import { networkStatus } from '@/constants/networkStatus';
import { VidikatService } from '@/services';

import {
  SET_CAR_SERVICE_IFRAME,
} from '../mutationTypes';

const state = {
  iframeData: {},
};

const mutations = {
  [SET_CAR_SERVICE_IFRAME](stateData, data) {
    stateData.iframeData = {...data};
  },
};

const actions = {
  async setCarServiceIframeData({ commit }) {
    const response = await VidikatService.getCarServiceIFrame();
    if (response.status === networkStatus['ok']) {
      commit(SET_CAR_SERVICE_IFRAME, response.data);
    }
    return response;
  },
};

// const getters = {
// };

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  // getters,
};
