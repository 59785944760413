<template>
  <div class="search__wrapper">
    <form @submit.prevent="onSearchTyre">
      <v-text-field
        v-model="search"
        :label="`${$t('langkey.search-all')}`"
        solo
        hide-details
        class="text-field__search"
        height="50"
      >
        <template #prepend-inner>
          <v-btn
            v-if="search"
            icon
            plain
            class="button__input-clear"
            @click="clearSearch"
          >
            <icon-close />
          </v-btn>
          <span
            class="icon__search"
            @click="onSearchTyre"
          >
            <icon-search size="22" />
          </span>
        </template>
      </v-text-field>
      <button
        style="display:none"
        type="submit"
      />
    </form>

    <info-dialog ref="searchInfoRef" />

    <select-search-tyre-dialog
      ref="selectSearchTyreDialog"
      :tabs="selectSearchTyreTabs"
      :search-text="search"
      @on-select-tab="onSelectTab"
    />
  </div>
</template>

<style lang="scss">
@import '@/styles/components/navigation-search-bar.scss';
</style>

<script>
import IconInfo from '@/components/icons/IconInfo';
import InfoDialog from '@/components/InfoDialog';
import SelectSearchTyreDialog from '@/components/SelectSearchTyreDialog';
import { mapState } from 'vuex';
import { tyreCarVehicleTypesGroup, tyreAllAvailableVehicleTypes } from '@/constants/tyreMenuGroup';

export default {
  components: {
    InfoDialog,
    SelectSearchTyreDialog,
  },
  data() {
    return {
      search: '',
      selectSearchTyreTabs: [],
    };
  },
  computed: {
    ...mapState('tyre', {
      tyreConcatenatedSearch: 'tyreConcatenatedSearch',
    }),
  },
  watch: {
    $route() {
      if (!this.$route.query.search) {
        this.search = '';
        return;
      }
      if (this.$route.query.search) {
        this.search = this.$route.query.search;
      }
    },
  },
  created() {
    if (this.$route.query.search) {
      this.search = this.$route.query.search;
    }
  },
  methods: {
    async onSearchTyre() {
      if (this.search.length === 0) {
        return;
      }
      this.$store.dispatch('dialog/setLoadingDialog', {
        status: true,
      });
      try {
        await this.$store.dispatch('tyre/searchTyreResult', {
          searchValue: this.search,
          filters: {
            sortField: 'bestPrice', // initial tyre table sort
            sortDirection: 'ASC',
            vehicleTypes: this.$route.meta.vehicleType
              ? this.$route.meta.vehicleType
              : this.$route.query.type
                ? this.$route.query.type
                : '',
            isSearchFromNavigation: true,
          },
        });
        await this.$store.dispatch('tyre/getTyresConcatenatedSearch', {
          query: this.search,
          vehicleTypes: this.$route.meta.vehicleType
            ? this.$route.meta.vehicleType
            : this.$route.query.type
              ? this.$route.query.type
              : '',
        });
        const searchVehicleTypes = this.tyreConcatenatedSearch.vehicleType;
        let tmpVehicleTypeCount = {
          car: 0,
          truck: 0,
          bike: 0,
          industrial: 0,
          agriculture: 0,
          earthMover: 0,
        };
        searchVehicleTypes.forEach((vehicleType) => {
          if (tyreCarVehicleTypesGroup.includes(vehicleType)) {
            tmpVehicleTypeCount.car += 1;
          } else if (vehicleType === 'truck') {
            tmpVehicleTypeCount.truck += 1;
          } else if (vehicleType === 'bike') {
            tmpVehicleTypeCount.bike += 1;
          } else if (vehicleType === 'industrial') {
            tmpVehicleTypeCount.industrial += 1;
          } else if (vehicleType === 'agriculture') {
            tmpVehicleTypeCount.agriculture += 1;
          } else if (vehicleType === 'earthMover') {
            tmpVehicleTypeCount.earthMover += 1;
          }
        });
        const hasValueVehicleTypes = Object.keys(tmpVehicleTypeCount).filter((key) => tmpVehicleTypeCount[key] > 0);
        // select vehicle if has more than one
        if (hasValueVehicleTypes.length > 1) {
          this.selectSearchTyreTabs = hasValueVehicleTypes;
          this.$refs.selectSearchTyreDialog.onShowDialog();
          return;
        }
        const selectedVehicleType = hasValueVehicleTypes[0];
        if (selectedVehicleType === 'car') {
          // if still at home page
          if (this.$route.name !== 'tyre-car') {
            this.$router.push(this.$i18nRoute({
              name: 'tyre-car',
              query: {
                search: this.search,
              },
            }));
          } else {
            this.$router.replace(this.$i18nRoute({
              name: 'tyre-car',
              query: {
                search: this.search,
              },
            }));
          }
        } else if (selectedVehicleType === 'truck') {
          // if still at home page
          if (this.$route.name !== 'tyre-truck') {
            this.$router.push(this.$i18nRoute({
              name: 'tyre-truck',
              query: {
                search: this.search,
              },
            }));
          } else {
            this.$router.replace(this.$i18nRoute({
              name: 'tyre-truck',
              query: {
                search: this.search,
              },
            }));
          }
        } else if (selectedVehicleType === 'bike') {
          // if still at home page
          if (this.$route.name !== 'tyre-motorbike') {
            this.$router.push(this.$i18nRoute({
              name: 'tyre-motorbike',
              query: {
                search: this.search,
              },
            }));
          } else {
            this.$router.replace(this.$i18nRoute({
              name: 'tyre-motorbike',
              query: {
                search: this.search,
              },
            }));
          }
        } else if (selectedVehicleType === 'industrial') {
          // if still at home page
          if (this.$route.name !== 'tyre-industrial') {
            this.$router.push(this.$i18nRoute({
              name: 'tyre-industrial',
              query: {
                search: this.search,
              },
            }));
          } else {
            this.$router.replace(this.$i18nRoute({
              name: 'tyre-industrial',
              query: {
                search: this.search,
              },
            }));
          }
        } else if (selectedVehicleType === 'agriculture') {
          // if still at home page
          if (this.$route.name !== 'tyre-agriculture') {
            this.$router.push(this.$i18nRoute({
              name: 'tyre-agriculture',
              query: {
                search: this.search,
              },
            }));
          } else {
            this.$router.replace(this.$i18nRoute({
              name: 'tyre-agriculture',
              query: {
                search: this.search,
              },
            }));
          }
        } else if (selectedVehicleType === 'earthMover') {
          // if still at home page
          if (this.$route.name !== 'tyre-earthMover') {
            this.$router.push(this.$i18nRoute({
              name: 'tyre-earthMover',
              query: {
                search: this.search,
              },
            }));
          } else {
            this.$router.replace(this.$i18nRoute({
              name: 'tyre-earthMover',
              query: {
                search: this.search,
              },
            }));
          }
        }
      } catch (err) {
        if (err === 'noResult') {
          const h = this.$createElement;
          const vm = this;
          setTimeout(() => {
            this.$store.dispatch('dialog/setShowErrorDialog', {
              status: true,
              title: this.$t('langkey.no-tyre-found-subject'),
              message: this.$t('langkey.no-tyre-found-message'),
              messageAction: h('button', {
                on: {
                  click: function() {
                    vm.$refs.searchInfoRef.show();
                    vm.$store.dispatch('dialog/setShowErrorDialog', {
                      status: false,
                    });
                  },
                },
              }, [
                h('div', {
                  class: 'd-flex align-center font-weight-bold',
                  style: {
                    color: 'red',
                  },
                }, [
                  h(IconInfo, {
                    class: 'mr-2',
                    props: {
                      color:'red',
                      size: '40',
                    },
                  }),
                  h('span', this.$t('langkey.explanation-info-button')),
                ]),
              ]),
            });
            this.search = '';
          }, 200);
        }
      } finally {
        this.$store.dispatch('dialog/setLoadingDialog', {
          status: false,
        });
      }
    },
    clearSearch () {
      this.search = '';
      if (this.$route.query.search) {
        this.$router.push(this.$i18nRoute({
          name: this.$route.name,
          query: null,
        }));
      }
    },
    async onSelectTab(tab) {
      let vehicleTypes;
      if (tab === 'car') {
        vehicleTypes = 'car, lightTruck, offRoad';
      } else {
        vehicleTypes = tab;
      }
      this.$store.dispatch('dialog/setLoadingDialog', {
        status: true,
      });
      try {
        await this.$store.dispatch('tyre/searchTyreResult', {
          searchValue: this.search,
          filters: {
            sortField: 'bestPrice', // initial tyre table sort
            sortDirection: 'ASC',
            vehicleTypes: vehicleTypes,
            isSearchFromNavigation: true,
          },
        });
        await this.$store.dispatch('tyre/getTyresConcatenatedSearch', {
          query: this.search,
          vehicleTypes: vehicleTypes,
        });
        if (tab === 'car') {
          if (this.$route.name !== 'tyre-car') {
            this.$router.push(this.$i18nRoute({
              name: 'tyre-car',
              query: {
                search: this.search,
              },
            }));
          } else {
            this.$router.replace(this.$i18nRoute({
              name: 'tyre-car',
              query: {
                search: this.search,
              },
            }));
          }
        } else if (tab === 'truck') {
          if (this.$route.name !== 'tyre-truck') {
            this.$router.push(this.$i18nRoute({
              name: 'tyre-truck',
              query: {
                search: this.search,
              },
            }));
          } else {
            this.$router.replace(this.$i18nRoute({
              name: 'tyre-truck',
              query: {
                search: this.search,
              },
            }));
          }
        } else if (tab === 'bike') {
          if (this.$route.name !== 'tyre-motorbike') {
            this.$router.push(this.$i18nRoute({
              name: 'tyre-motorbike',
              query: {
                search: this.search,
              },
            }));
          } else {
            this.$router.replace(this.$i18nRoute({
              name: 'tyre-motorbike',
              query: {
                search: this.search,
              },
            }));
          }
        } else if (tab === 'industrial') {
          if (this.$route.name !== 'tyre-industrial') {
            this.$router.push(this.$i18nRoute({
              name: 'tyre-industrial',
              query: {
                search: this.search,
              },
            }));
          } else {
            this.$router.replace(this.$i18nRoute({
              name: 'tyre-industrial',
              query: {
                search: this.search,
              },
            }));
          }
        } else if (tab === 'agriculture') {
          if (this.$route.name !== 'tyre-agriculture') {
            this.$router.push(this.$i18nRoute({
              name: 'tyre-agriculture',
              query: {
                search: this.search,
              },
            }));
          } else {
            this.$router.replace(this.$i18nRoute({
              name: 'tyre-agriculture',
              query: {
                search: this.search,
              },
            }));
          }
        } else if (tab === 'earthMover') {
          if (this.$route.name !== 'tyre-earthMover') {
            this.$router.push(this.$i18nRoute({
              name: 'tyre-earthMover',
              query: {
                search: this.search,
              },
            }));
          } else {
            this.$router.replace(this.$i18nRoute({
              name: 'tyre-earthMover',
              query: {
                search: this.search,
              },
            }));
          }
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.$store.dispatch('dialog/setLoadingDialog', {
          status: false,
        });
      }
    },
  },
};
</script>