<template>
  <v-btn
    v-if="!item.isGroup"
    depressed
    plain
    :to="toRoute"
    :disabled="isInactive"
    :class="[
      'navigation-tab',
      isInactive ? 'inactive' : null,
      $route.query
        && ($route.query.fromPage === item.to) ? 'link-active' : null
    ]"
    active-class="link-active"
    exact-active-class="exact-link-active"
    @click="onClick"
  >
    <component
      :is="`icon-${item.icon}`"
      v-if="item.icon"
      class="nav__icon"
    />
    <span :class="['text', item.icon]">{{ $t(item.title) }}</span>
    <span
      v-if="isInactive"
      class="coming-soon"
    >
      {{ $t('langkey.coming-soon') }}
    </span>
  </v-btn>
  <v-btn
    v-else
    depressed
    plain
    :to="toRouteGroupBtn"
    :disabled="isInactive"
    :class="[
      'navigation-tab is-group',
      isInactive && 'inactive',
      item.class
    ]"
    active-class="link-active"
    exact-active-class="exact-link-active"
    @click="$emit('click')"
  >
    <div
      v-for="(child, index) in item.children"
      :key="index"
      class="navigation-tab__group-item"
    >
      <component
        :is="`icon-${child.icon}`"
        v-if="child.icon"
        class="nav__icon"
      />
      <span :class="['text', child.icon]">{{ $t(child.title) }}</span>
      <span
        v-if="isInactive"
        class="coming-soon"
      >
        {{ $t('langkey.coming-soon') }}
      </span>
    </div>
  </v-btn>
</template>

<style lang="scss">
@import '@/styles/components/navigation-tab.scss';
</style>

<script>
import IconCar from '@/components/icons/IconCar';
import IconSuv from '@/components/icons/IconSuv';
import IconVan from '@/components/icons/IconVan';
import IconTruck from '@/components/icons/IconTruck';
import IconIndustrial from '@/components/icons/IconIndustrial';
import IconAgriculture from '@/components/icons/IconAgriculture';
import IconEarthMover from '@/components/icons/IconEarthMover';
import IconBike from '@/components/icons/IconBike';
import IconRim from '@/components/icons/IconRim';
import IconService from '@/components/icons/IconService';
import IconSteeringWheel from '@/components/icons/IconSteeringWheel';

export default {
  components: {
    IconCar,
    IconSuv,
    IconVan,
    IconTruck,
    IconIndustrial,
    IconAgriculture,
    IconEarthMover,
    IconBike,
    IconRim,
    IconService,
    IconSteeringWheel,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    isSelect: {
      type: Boolean,
      default: false,
    },
    isSearch: {
      type: Boolean,
      default: false,
    },
    searchText: {
      type: String,
      default: '',
    },
  },
  computed: {
    isActive() {
      return this.$route.matched.some((item) => item.name === this.$route.name);
    },
    isInactive() {
      return this.item.inactive;
    },
    toRoute() {
      if (this.isSelect) {
        return null;
      }
      if (!this.isSearch) {
        return { name: this.item.to };
      }
      return {
        name: this.item.to,
        query: {
          search: this.searchText,
        },
      };
    },
    toRouteGroupBtn() {
      if (this.isSelect) {
        return null;
      }
      if (!this.isSearch) {
        return { name: this.item.to };
      }
      return {
        name: this.item.to,
        query: {
          search: this.searchText,
        },
      };
    },
  },
  methods: {
    onClick() {
      if (this.isSelect) {
        this.$emit('on-select');
      }
    },
  },
};
</script>