<template>
  <div
    :class="[
      'base-rimconfig-filter-bar',
      isCarConfigurator ? 'car-configurator' : null
    ]"
  >
    <div
      v-if="hasCheckbox"
      class="checkbox-bar"
    >
      <v-container>
        <span
          v-if="hasCheckboxLabel"
          class="checkbox-label"
        >
          {{ $t(checkboxLabel) }}:
        </span>
        <slot name="checkbox-group" />
      </v-container>
    </div>
    <div
      v-if="hasSelect"
      class="select-bar"
    >
      <v-container>
        <div class="select-list__container">
          <slot name="select-group" />
        </div>
        <div class="functions-container">
          <v-btn
            v-if="hasReset"
            class="function-item"
            color="primary"
            text
            @click="$emit('reset')"
          >
            <span class="mr-1">
              {{ $t('langkey.filter-reset') }}
            </span>
            <icon-clear-filter
              size="14"
              color="var(--theme-black)"
            />
          </v-btn>
          <v-btn
            v-if="hasMore"
            :class="[
              'function-item show-all',
              isShowMore ? 'is-more' : null
            ]"
            color="primary"
            @click="$emit('show-all')"
          >
            <span class="mr-2">
              {{ $t(showAllText) }}
            </span>
            <icon-caret-double solid />
          </v-btn>
        </div>
      </v-container>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/styles/components/base-rimconfig-filter-bar.scss";
</style>

<script>
import IconClearFilter from '@/components/icons/IconClearFilter';
import IconCaretDouble from '@/components/icons/IconCaretDouble';

export default {
  components: {
    IconClearFilter,
    IconCaretDouble,
  },
  props: {
    isCarConfigurator: {
      type: Boolean,
      default: false,
    },
    hasReset: {
      type: Boolean,
      default: true,
    },
    hasMore: {
      type: Boolean,
      default: false,
    },
    isShowMore: {
      type: Boolean,
      default: false,
    },
    hasCheckbox: {
      type: Boolean,
      default: false,
    },
    hasCheckboxLabel: {
      type: Boolean,
      default: true,
    },
    hasSelect: {
      type: Boolean,
      default: true,
    },
    checkboxLabel: {
      type: String,
      default: 'langkey.filter-property',
    },
  },
  computed: {
    showAllText() {
      return this.isShowMore ? 'langkey.filter-see-less' : 'langkey.filter-see-all';
    },
  },
};
</script>