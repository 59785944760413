import { apiClient } from '@/services';

export const CartService = {
  addToCart({ id, quantity, context }) {
    if (context) {
      return apiClient.post(`/cart/add-to-cart/${id}/${quantity}`, context);
    }
    return apiClient.post(`/cart/add-to-cart/${id}/${quantity}`);
  },
  getCart() {
    return apiClient.get('/cart/get-cart');
  },
  editCartQuantiyById({ id, quantity }) {
    return apiClient.put(`/cart/update-quantity/${id}/${quantity}`);
  },
  getAddress() {
    return apiClient.get('/cart/get-addresses');
  },
  addAddress({ name, street, streetNumber, zip, city, country, countryCode }) {
    return apiClient.post('/cart/add-address', {
      name,
      street,
      streetNumber,
      zip,
      city,
      country,
      countryCode,
    });
  },
  checkAllowChangeAddressForSource({ sourceId }) {
    return apiClient.head(`/cart/address/${sourceId}`);
  },
  changeAddress({ sourceId, addressId }) {
    return apiClient.put(`/cart/change-address/${sourceId}/${addressId}`);
  },
  deleteCartPosition(id) {
    return apiClient.delete(`/cart/delete-cart-position/${id}`);
  },
  clearCart() {
    return apiClient.delete('/cart/clear-cart');
  },
  order() {
    return apiClient.post('/cart/order');
  },
  editCartComment({ id, comment }) {
    return apiClient.put(`/cart/update-comment/${id}`, {
      comment,
    });
  },
  addCouponToCart({ context }) {
    return apiClient.post('/cart/coupon', context);
  },
};