<template>
  <section class="rimconfig-menu">
    <v-btn
      v-for="(item, index) in rimconfigMenus"
      :key="`${item.title}${index}`"
      plain
      :to="getNavigationLink(item)"
      exact-active-class="route-exact-active"
      active-class="route-active"
      :class="{ 'in-active': item.isInactive }"
      @click="onClickMenu(item.isInactive)"
    >
      <span
        class="rimconfig-menu___btn-content"
      >
        <component
          :is="`Icon${item.icon}`"
          v-if="item.icon"
          :class="item.iconClass"
        />
        <span>{{ $t(item.title) }}</span>
      </span>
      <span
        v-if="item.isInactive"
        class="coming-soon"
      >
        {{ $t('langkey.coming-soon') }}
      </span>
    </v-btn>
  </section>
</template>

<style lang="scss">
@import '@/styles/components/rimconfig-menu.scss';
</style>

<script>
import IconCarFront from '@/components/icons/IconCarFront';
import IconWheelDesign from '@/components/icons/IconWheelDesign';
import IconTpms from '@/components/icons/IconTpms';
import IconTpmsKit from '@/components/icons/IconTpmsKit';
import IconRimTruck from '@/components/icons/IconRimTruck';

export default {
  components: {
    IconCarFront,
    IconWheelDesign,
    IconTpms,
    IconTpmsKit,
    IconRimTruck,
  },
  data() {
    return {
      rimconfigMenus: [
        {
          title: 'langkey.config-header-icon-wheel-by-car',
          icon: 'CarFront',
          iconClass: 'icon-car-front',
          to: 'rims-config-configurator',
        },
        {
          title: 'langkey.config-header-icon-wheel-by-design',
          icon: 'WheelDesign',
          iconClass: 'icon-wheel-design',
          to: 'rims-config-wheelDesign',
        },
        {
          title: 'langkey.config-header-icon-tpms',
          icon: 'Tpms',
          iconClass: 'icon-tpms',
          to: 'rims-config-tpms',
        },
        {
          title: 'langkey.config-header-icon-tpms-kit',
          icon: 'TpmsKit',
          iconClass: 'icon-tpms-kit',
          to: 'rims-config-tpmsKit',
        },
        {
          title: 'langkey.config-header-icon-rims-for-Trucks-OTR',
          icon: 'RimTruck',
          iconClass: 'icon-rim-truck',
          to: 'rims-config-industryAgriculture',
          isInactive: true,
        },
      ],
    };
  },
  methods: {
    onClickMenu(isInactive = false) {
      if (!isInactive) {
        this.$store.dispatch('rimconfigCarSelector/clearCarSelector');
        this.$store.dispatch('rimconfigCarSelectorTpms/clearCarSelector');
      }
    },
    getNavigationLink({ isInactive = false, to }) {
      if (!isInactive)
        return { name: to };
      return null;
    },
  },
};
</script>