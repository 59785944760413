<template>
  <svg
    width="24"
    height="23"
    viewBox="0 0 24 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.3124 9.12007C15.6754 8.03243 14.5453 6.42981 14.9262 4.36988C15.081 3.43557 15.5618 2.58548 16.2841 1.9691C17.0064 1.35273 17.924 1.00952 18.8754 0.999845C19.8274 0.976687 20.7564 1.29272 21.4949 1.89098C22.2334 2.48924 22.7328 3.33038 22.9033 4.26277C23.3559 6.36115 22.2699 7.97612 19.6454 9.10771C19.3059 11.5096 18.9748 11.8392 16.6897 12.1811C16.6193 13.689 16.7476 15.2051 16.4454 16.6306C15.9874 18.5758 14.8347 20.2892 13.202 21.4518C11.5693 22.6144 9.56782 23.147 7.56998 22.9504C5.51916 22.7438 3.61579 21.7954 2.22095 20.285C0.826113 18.7745 0.0367879 16.8072 0.00265311 14.756C-0.0486242 12.7399 0.644896 10.775 1.95181 9.23365C3.25873 7.6923 5.08828 6.6815 7.09391 6.39273C10.625 5.91071 13.4276 7.19198 15.3732 10.1377C16.0025 11.0894 16.7642 10.8875 17.5176 10.7351C18.3994 10.5538 18.3497 9.79572 18.3124 9.12007ZM8.32338 9.23131C6.88393 9.23675 5.50527 9.80937 4.48898 10.8239C3.47268 11.8384 2.90151 13.2122 2.90042 14.6448C2.91348 16.0924 3.49911 17.4767 4.53042 18.4976C5.56173 19.5186 6.95569 20.0941 8.41035 20.0995C9.84522 20.0757 11.2133 19.492 12.2199 18.474C13.2265 17.4561 13.791 16.0854 13.7919 14.6571C13.8014 13.9406 13.6663 13.2294 13.3947 12.5658C13.123 11.9021 12.7203 11.2995 12.2104 10.7935C11.7005 10.2876 11.0937 9.88863 10.426 9.62028C9.75834 9.35194 9.04334 9.21967 8.32338 9.23131ZM18.8837 2.22756C18.1399 2.24267 17.4317 2.54765 16.9115 3.07693C16.3913 3.60621 16.1006 4.31752 16.1018 5.0579C16.1287 5.79854 16.4439 6.49977 16.9808 7.01334C17.5177 7.52691 18.2342 7.81258 18.9789 7.80996C19.7222 7.79373 20.4288 7.48505 20.944 6.9515C21.4592 6.41795 21.7409 5.70299 21.7276 4.96314C21.7217 4.59833 21.6433 4.23829 21.4969 3.90378C21.3505 3.56927 21.1391 3.26692 20.8747 3.01414C20.6103 2.76137 20.2983 2.56319 19.9565 2.43101C19.6148 2.29884 19.2502 2.23529 18.8837 2.24404V2.22756Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'var(--theme-gray-4)',
    },
  },
};
</script>