import { apiClient } from '@/services';

export const TpmsService = {
  getSensorsByEan(payload) {
    return apiClient.get('/tpms/sensors', {
      params: {
        eans: payload,
      },
    });
  },
  getSensorsByMan(payload) {
    return apiClient.get('/tpms/sensors', {
      params: {
        mans: payload,
      },
    });
  },
  getSensors(filters) {
    return apiClient.get('/tpms/car-sensors', {
      params: {
        ...filters,
      },
    });
  },
  getConfiguratorManufacturers() {
    return apiClient.get('/configurator/manufacturers');
  },
  getConfiguratorClasses(payload) {
    return apiClient.get('/configurator/classes', {
      params: payload,
    });
  },
  getConfiguratorTypes(payload) {
    return apiClient.get('/configurator/type-groups', {
      params: payload,
    });
  },
  getConfiguratorVersions(payload) {
    return apiClient.get('/configurator/version-groups', {
      params: payload,
    });
  },
  getCarGroupByGermanCode(payload) {
    return apiClient.get('/cars/groups/german-code', {
      params: payload,
    });
  },
  getKits(payload) {
    return apiClient.get('/tpms/kits', {
      params: payload,
    });
  },
  getKitsOnly(payload) {
    return apiClient.get('/tpms/kits-only', {
      params: payload,
    });
  },
};