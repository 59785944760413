import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import { i18n } from '@/plugins/i18n';
import { translation } from '@/plugins/translation';
import vuetify from '@/plugins/vuetify';
import '@/plugins/vuebar';
import '@/utils/filters';

import VueAgile from 'vue-agile';
Vue.use(VueAgile);

import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/swiper-bundle.css';
Vue.use(VueAwesomeSwiper);

import VueLazyload from 'vue-lazyload';
Vue.use(VueLazyload, {
  error: 'https://cdn.jfnet.de/wheellogos/404/80x80-cc-xxxxxx.png',
});

import VueFullscreen from 'vue-fullscreen';
Vue.use(VueFullscreen);

import { themeSetup } from '@/configs/themeColor';

// Styles
import '@/styles/app.scss';

themeSetup();

import BaseDropdown from '@/components/BaseDropdown';
import BaseAddToCartDialog from '@/components/BaseAddToCartDialog';
import BaseCheckbox from '@/components/BaseCheckbox';
import BaseSelectFilterDropdown from '@/components/BaseSelectFilterDropdown';
import BaseRimconfigCarSelector from '@/components/BaseRimconfigCarSelector';
import BaseRimconfigCardCarSelector from '@/components/BaseRimconfigCardCarSelector';
import BaseRimconfigTopBrand from '@/components/BaseRimconfigTopBrand';
import BaseRimconfigFilterBar from '@/components/BaseRimconfigFilterBar';
import BaseVirtualTable from '@/components/BaseVirtualTable';
import BaseDialog from '@/components/BaseDialog';
// import BaseInputSelectDropdown from '@/components/BaseInputSelectDropdown';

import IconClose from '@/components/icons/IconClose';
import IconSearch from '@/components/icons/IconSearch';
import IconAddToCart from '@/components/icons/IconAddToCart';

Vue.component('base-add-to-cart-dialog', BaseAddToCartDialog);
Vue.component('base-dropdown', BaseDropdown);
Vue.component('base-checkbox', BaseCheckbox);
Vue.component('base-select-filter-dropdown', BaseSelectFilterDropdown);
Vue.component('base-rimconfig-filter-bar', BaseRimconfigFilterBar);
Vue.component('base-rimconfig-car-selector', BaseRimconfigCarSelector);
Vue.component('base-rimconfig-card-car-selector', BaseRimconfigCardCarSelector);
Vue.component('base-rimconfig-top-brand', BaseRimconfigTopBrand);
Vue.component('base-virtual-table', BaseVirtualTable);
Vue.component('base-dialog', BaseDialog);
// Vue.component('base-input-select-dropdown', BaseInputSelectDropdown);

Vue.component('icon-close', IconClose);
Vue.component('icon-search', IconSearch);
Vue.component('icon-add-to-cart', IconAddToCart);

Vue.component('VNodeBridge', {
  functional: true,
  // eslint-disable-next-line
  props: ['nodes'],
  render(h, { props }) {
    return props.nodes;
  },
});

Vue.config.productionTip = false;
Vue.prototype.$i18nRoute = translation.i18nRoute.bind(translation);

const VBtn = Vue.component('VBtn');
VBtn.options.props.ripple.default = false;

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
