<template>
  <v-dialog
    v-model="showDialog"
    :max-width="maxWidth"
    :content-class="dialogClass"
    @click:outside="hide"
  >
    <v-card class="base-dialog">
      <v-card-title
        v-if="title"
        class="headline"
      >
        <h2
          class="title-text"
          v-html="$t(title)"
        />
        <v-btn
          class="button__close"
          small
          icon
          @click="hide"
        >
          <icon-close />
        </v-btn>
      </v-card-title>
      <v-card-title
        v-else
        class="headline reverse"
      >
        <v-btn
          text
          class="button__close"
          @click="hide"
        >
          <icon-close />
        </v-btn>
      </v-card-title>
      <v-card-text>
        <slot />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style lang="scss">
@import '@/styles/components/base-dialog.scss';
</style>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    dialogClass: {
      type: String,
      default: '',
    },
    maxWidth: {
      type: Number,
      default: 1000,
    },
  },
  data() {
    return {
      showDialog: false,
    };
  },
  methods: {
    show() {
      this.showDialog = true;
    },
    hide() {
      this.$emit('close');
      this.showDialog = false;
    },
  },
};
</script>