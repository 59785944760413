export const LANGUAGES = [
  {
    iso: 'de',
    label: 'Deutsch',
    abbr: 'DE',
    locale: 'de_DE',
  },
  {
    iso: 'en',
    label: 'English',
    abbr: 'EN',
    locale: 'en_US',
  },
  // {
  //   iso: 'fr',
  //   label: 'Français',
  //   abbr: 'FR',
  // },
  // { iso: "hu", label: "Hungarian" },
  // { iso: "nl", label: "Nederlands" },
  // {
  //   iso: 'pl',
  //   label: 'Polski',
  //   abbr: 'PL',
  // },
  // { iso: "ro", label: "Romanian" },
  // {
  //   iso: 'ru',
  //   label: 'Русский',
  //   abbr: 'RU',
  // },
  // { iso: "sv", label: "Svenska" }
];

export const SUPPORTED_LANGUAGES = LANGUAGES.map((l) => l.iso);
export const DEFAULT_LANGUAGE = SUPPORTED_LANGUAGES[0];
export const DEFAULT_LANGUAGE_ABBR = LANGUAGES.find((l) => l.iso === DEFAULT_LANGUAGE).abbr;
export const FALLBACK_LANGUAGE = SUPPORTED_LANGUAGES[0];
