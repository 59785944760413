<template>
  <svg
    :height="size"
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.98267 6.54124C1.98145 5.44488 2.34236 4.37881 3.00934 3.50867C3.67631 2.63852 4.61199 2.01304 5.67102 1.72937C6.73005 1.4457 7.85311 1.51975 8.86573 1.94C9.87834 2.36026 10.7238 3.10319 11.2708 4.05337"
      :stroke="color"
      stroke-width="1.13086"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M11.9418 6.5412C11.9395 7.67602 11.5499 8.77606 10.8375 9.65941C10.1251 10.5428 9.13256 11.1565 8.02399 11.3992C6.91541 11.6418 5.75723 11.4988 4.74096 10.9938C3.72469 10.4888 2.91121 9.65208 2.43506 8.62198"
      :stroke="color"
      stroke-width="1.13086"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M3.76952 5.50836L1.89984 6.83523L0.56543 4.95801"
      :stroke="color"
      stroke-width="1.13086"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M10.3813 7.66453L12.0249 6.24719L13.4347 7.88316"
      :stroke="color"
      stroke-width="1.13086"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#1D1D1B',
    },
    size: {
      type: String,
      default: '13',
    },
  },
};
</script>