<template>
  <div>
    <navigation-admin />
    <v-main>
      <slot />
    </v-main>
    <footer-menu />
  </div>
</template>

<script>
import NavigationAdmin from '@/components/NavigationAdmin';
import FooterMenu from '@/components/FooterMenu';

export default {
  components: {
    NavigationAdmin,
    FooterMenu,
  },
};
</script>