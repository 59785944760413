import { apiClient } from '@/services';

export const AssignService = {
  getAssigns({ ids, expressDelivery = '', marketplace_address }) {
    if (expressDelivery === '') {
      return apiClient.get('/assigns/', {
        params: {
          ids,
          marketplace_address,
        },
      });
    }
    return apiClient.get('/assigns/', {
      params: {
        ids,
        expressDelivery,
        marketplace_address,
      },
    });
  },
};