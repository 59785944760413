var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.item.isGroup)?_c('v-btn',{class:[
    'navigation-tab',
    _vm.isInactive ? 'inactive' : null,
    _vm.$route.query
      && (_vm.$route.query.fromPage === _vm.item.to) ? 'link-active' : null
  ],attrs:{"depressed":"","plain":"","to":_vm.toRoute,"disabled":_vm.isInactive,"active-class":"link-active","exact-active-class":"exact-link-active"},on:{"click":_vm.onClick}},[(_vm.item.icon)?_c(`icon-${_vm.item.icon}`,{tag:"component",staticClass:"nav__icon"}):_vm._e(),_c('span',{class:['text', _vm.item.icon]},[_vm._v(_vm._s(_vm.$t(_vm.item.title)))]),(_vm.isInactive)?_c('span',{staticClass:"coming-soon"},[_vm._v(" "+_vm._s(_vm.$t('langkey.coming-soon'))+" ")]):_vm._e()],1):_c('v-btn',{class:[
    'navigation-tab is-group',
    _vm.isInactive && 'inactive',
    _vm.item.class
  ],attrs:{"depressed":"","plain":"","to":_vm.toRouteGroupBtn,"disabled":_vm.isInactive,"active-class":"link-active","exact-active-class":"exact-link-active"},on:{"click":function($event){return _vm.$emit('click')}}},_vm._l((_vm.item.children),function(child,index){return _c('div',{key:index,staticClass:"navigation-tab__group-item"},[(child.icon)?_c(`icon-${child.icon}`,{tag:"component",staticClass:"nav__icon"}):_vm._e(),_c('span',{class:['text', child.icon]},[_vm._v(_vm._s(_vm.$t(child.title)))]),(_vm.isInactive)?_c('span',{staticClass:"coming-soon"},[_vm._v(" "+_vm._s(_vm.$t('langkey.coming-soon'))+" ")]):_vm._e()],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }