export const priceTypeItems = [
  {
    title: 'langkey.purchase_price',
    value: 'purchasePrice',
  },
  {
    title: 'langkey.retail_price',
    value: 'retailPrice',
  },
  {
    title: 'langkey.retail_tax_price',
    value: 'retailTaxPrice',
  },
];