// rimconfig car selector
export const SET_MANUFACTURERS = 'SET_MANUFACTURERS';
export const SET_MODELS = 'SET_MODELS';
export const SET_TYPES = 'SET_TYPES';
export const SET_VERSIONS = 'SET_VERSIONS';
export const SET_SELECTED_MANUFACTURER = 'SET_SELECTED_MANUFACTURER';
export const SET_SELECTED_MODEL = 'SET_SELECTED_MODEL';
export const SET_SELECTED_TYPE = 'SET_SELECTED_TYPE';
export const LOAD_CAR_TPMS = 'LOAD_CAR_TPMS';
export const SET_STEP = 'SET_STEP';
export const SET_CAR_HISTORY = 'SET_CAR_HISTORY';
export const SET_SEARCH_BY_KBA_NUMBER = 'SET_SEARCH_BY_KBA_NUMBER';
export const SET_PREV_CAR_TAG = 'SET_PREV_CAR_TAG';
export const SET_CAR_GROUP_BY_GERMAN_CODE = 'SET_CAR_GROUP_BY_GERMAN_CODE';
export const SET_SEARCH_BY_EAN_MAN = 'SET_SEARCH_BY_EAN_MAN';

// rimconfig breadcrumb
export const UPDATE_RIMCONFIG_BREADCRUMB = 'UPDATE_RIMCONFIG_BREADCRUMB';

// securitiy
export const SET_LOGIN_TOKEN = 'SET_LOGIN_TOKEN';

// tyre
export const SET_TYRE_DATA_CAR = 'SET_TYRE_DATA_CAR';
export const UPDATE_TYRE_DATA_CAR = 'UPDATE_TYRE_DATA_CAR';
export const SET_TYRE_CONCATENATED_CAR = 'SET_TYRE_CONCATENATED_CAR';

export const SET_TYRE_DATA_SEARCH = 'SET_TYRE_DATA_SEARCH';
export const UPDATE_TYRE_DATA_SEARCH = 'UPDATE_TYRE_DATA_SEARCH';
export const SET_TYRE_CONCATENATED_SEARCH = 'SET_TYRE_CONCATENATED_SEARCH';

export const SET_TYRE_DATA_TRUCK = 'SET_TYRE_DATA_TRUCK';
export const UPDATE_TYRE_DATA_TRUCK = 'UPDATE_TYRE_DATA_TRUCK';
export const SET_TYRE_CONCATENATED_TRUCK = 'SET_TYRE_CONCATENATED_TRUCK';

export const SET_TYRE_DATA_MOTORBIKE = 'SET_TYRE_DATA_MOTORBIKE';
export const UPDATE_TYRE_DATA_MOTORBIKE = 'UPDATE_TYRE_DATA_MOTORBIKE';
export const SET_TYRE_CONCATENATED_MOTORBIKE = 'SET_TYRE_CONCATENATED_MOTORBIKE';

export const SET_TYRE_DATA_INDUSTRIAL = 'SET_TYRE_DATA_INDUSTRIAL';
export const UPDATE_TYRE_DATA_INDUSTRIAL = 'UPDATE_TYRE_DATA_INDUSTRIAL';
export const SET_TYRE_CONCATENATED_INDUSTRIAL = 'SET_TYRE_CONCATENATED_INDUSTRIAL';

export const SET_TYRE_DATA_AGRICULTURE = 'SET_TYRE_DATA_AGRICULTURE';
export const UPDATE_TYRE_DATA_AGRICULTURE = 'UPDATE_TYRE_DATA_AGRICULTURE';
export const SET_TYRE_CONCATENATED_AGRICULTURE = 'SET_TYRE_CONCATENATED_AGRICULTURE';

export const SET_TYRE_DATA_EARTHMOVER = 'SET_TYRE_DATA_EARTHMOVER';
export const UPDATE_TYRE_DATA_EARTHMOVER = 'UPDATE_TYRE_DATA_EARTHMOVER';
export const SET_TYRE_CONCATENATED_EARTHMOVER = 'SET_TYRE_CONCATENATED_EARTHMOVER';

export const SET_TYRE_ASSIGNS = 'SET_TYRE_ASSIGNS';
export const SET_TYRE_ASSIGNS_TPMS = 'SET_TYRE_ASSIGNS_TPMS';

// tpms
export const SET_TPMS_DATA = 'SET_TPMS_DATA';
export const SET_TPMS_ASSIGNS = 'SET_TPMS_ASSIGNS';
export const SET_TPMS_KIT_ASSIGNS = 'SET_TPMS_KIT_ASSIGNS';
export const SET_TPMS_KITS_DATA = 'SET_TPMS_KITS_DATA';
export const SET_TPMS_SEARCH_DATA = 'SET_TPMS_SEARCH_DATA';
export const SET_TPMS_KITS_ONLY_DATA = 'SET_TPMS_KITS_ONLY_DATA';

// dialog
export const OPEN_ERROR_DIALOG = 'OPEN_ERROR_DIALOG';
export const CLOSE_ERROR_DIALOG = 'CLOSE_ERROR_DIALOG';
export const OPEN_LOADING_DIALOG = 'OPEN_LOADING_DIALOG';
export const CLOSE_LOADING_DIALOG = 'CLOSE_LOADING_DIALOG';
export const SET_SHOW_INFO_DIALOG = 'SET_SHOW_INFO_DIALOG';

// Rimconfig Base
export const SET_WHEEL_MANUFACTURERS = 'SET_WHEEL_MANUFACTURERS';
export const SET_WHEEL_DESIGNS = 'SET_WHEEL_DESIGNS';
export const SET_WHEELS_CONCATENATED = 'SET_WHEELS_CONCATENATED';
export const SET_WHEELS_BY_QUERY = 'SET_WHEELS_BY_QUERY';

//add to cart dialog
export const SET_CART_DATA = 'SET_CART_DATA';
export const ADD_QUANTITY_CART_ITEM = 'ADD_QUANTITY_CART_ITEM';
export const SUBTRACT_QUANTITY_CART_ITEM = 'SUBTRACT_QUANTITY_CART_ITEM';
export const UPDATE_QUANTITY_CART_ITEM = 'UPDATE_QUANTITY_CART_ITEM';
export const CLEAR_CART_ITEM = 'CLEAR_CART_ITEM';

// Rimconfig car configurator
export const SET_RIMCONFIG_CAR_DATA = 'SET_RIMCONFIG_CAR_DATA';
export const SET_CAR_WHEELS = 'SET_CAR_WHEELS';
export const SET_CAR_WHEELS_NEXT_PAGE = 'SET_CAR_WHEELS_NEXT_PAGE';
export const SET_CAR_WHEELS_CONCATENATED = 'SET_CAR_WHEELS_CONCATENATED';
export const SET_CAR_WHEELS_SELECTED_FILTERS = 'SET_CAR_WHEELS_SELECTED_FILTERS';
export const SET_CAR_WHEELS_ASSIGNS = 'SET_CAR_WHEELS_ASSIGNS';
export const SET_CAR_IMAGE = 'SET_CAR_IMAGE';
export const SET_SELECTED_WHEEL = 'SET_SELECTED_WHEEL';
export const SET_CAR_COC_DATA = 'SET_CAR_COC_DATA';
export const SET_CERTIFICATE_TEXTS = 'SET_CERTIFICATE_TEXTS';
export const SET_SELECTED_CONFIGURATOR_WHEEL = 'SET_SELECTED_CONFIGURATOR_WHEEL';
export const SET_SELECTED_CONFIGURATOR_TPMS = 'SET_SELECTED_CONFIGURATOR_TPMS';
export const SET_SELECTED_CONFIGURATOR_TYRE = 'SET_SELECTED_CONFIGURATOR_TYRE';
export const RESET_SELECTED_CONFIGURATOR_WHEEL = 'RESET_SELECTED_CONFIGURATOR_WHEEL';
export const RESET_SELECTED_CONFIGURATOR_TPMS = 'RESET_SELECTED_CONFIGURATOR_TPMS';
export const RESET_SELECTED_CONFIGURATOR_TYRE = 'RESET_SELECTED_CONFIGURATOR_TYRE';

// Rimconfig wheel design
export const SET_WHEEL_CERTIFICATES = 'SET_WHEEL_CERTIFICATES';
export const SET_WHEEL_ASSIGNS = 'SET_WHEEL_ASSIGNS';
export const SET_KIT_ITEMS = 'SET_KIT_ITEMS';

// cart
export const SET_CART_SOURCES = 'SET_CART_SOURCES';
export const SET_SOURCE_ID = 'SET_SOURCE_ID';
export const SET_CART_ADDRESS_ITEMS = 'SET_CART_ADDRESS_ITEMS';
export const ADD_ARTICLE_VERSION_A = 'ADD_ARTICLE_VERSION_A';
export const UPDATE_ARTICLE_VERSION_A = 'UPDATE_ARTICLE_VERSION_A';
export const SET_COUPON = 'SET_COUPON';
export const SET_ALLOW_CHANGE_ADDRESS_FOR_SOURCE = 'SET_ALLOW_CHANGE_ADDRESS_FOR_SOURCE';

//user
export const SHOW_EMAIL_CONFIRM_DIALOG = 'SHOW_EMAIL_CONFIRM_DIALOG';
export const SHOW_RESET_INITIAL_PASSWORD_DIALOG = 'SHOW_RESET_INITIAL_PASSWORD_DIALOG';
export const SET_USER_INFORMATION = 'SET_USER_INFORMATION';
export const SET_USER_ADDRESSES = 'SET_USER_ADDRESSES';
export const UPDATE_USER_ADDRESS = 'UPDATE_USER_ADDRESS';
export const DELETE_USER_ADDRESS = 'DELETE_USER_ADDRESS';
export const SET_USER_TYPE = 'SET_USER_TYPE';
export const SET_USER_ALLOW_ADDRESS = 'SET_USER_ALLOW_ADDRESS';

//order
export const SET_ORDER_ITEMS = 'SET_ORDER_ITEMS';
export const UPDATE_ORDER_ITEMS = 'UPDATE_ORDER_ITEMS';
export const SET_ORDER_STATUS = 'SET_ORDER_STATUS';

//registration
export const SET_CUSTOMER_INFORMATION = 'SET_CUSTOMER_INFORMATION';

//direct debit
export const SET_DIRECT_DEBIT = 'SET_DIRECT_DEBIT';

// car service
export const SET_CAR_SERVICE_IFRAME = 'SET_CAR_SERVICE_IFRAME';

//dealer calculation
export const SET_DEALER_CALCULATION = 'SET_DEALER_CALCULATION';
export const SET_DEALER_CALCULATION_BY_ID = 'SET_DEALER_CALCULATION_BY_ID';

//pricing
export const SET_PRICE_TYPE = 'SET_PRICE_TYPE';
export const SET_OPT_IN = 'SET_OPT_IN';

// offer tool settings
export const SET_OFFER_TOOL_SETTINGS = 'SET_OFFER_TOOL_SETTINGS';