import {RegisterService} from '@/services';

import {SET_CUSTOMER_INFORMATION} from '../mutationTypes';

const state = {
  customerInformation: {},
};

const mutations = {
  [SET_CUSTOMER_INFORMATION](stateData, data) {
    stateData.customerInformation = {
      ...data,
    };
  },
};

const actions = {
  async register({ _ }, payload = {}) {
    return await RegisterService.customerRegisterOrUpgrade('register', payload);
  },
  async upgrade({ _ }, payload = {}) {
    return await RegisterService.customerRegisterOrUpgrade('upgrade', payload);
  },
  async getCustomerInformation({ commit }) {
    const response = await RegisterService.getCustomerInformation();

    commit(SET_CUSTOMER_INFORMATION, response.data);
    sessionStorage.setItem('customerInformation', JSON.stringify(response.data));

    return response;
  },
};

const getters = {
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
