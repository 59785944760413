<template>
  <div class="base-rimconfig-card-car-selector rounded-sm">
    <div
      v-if="title || customTitle"
      class="base-rimconfig-card-car-selector__header"
    >
      <slot
        v-if="!!$slots.headerIcon"
        name="headerIcon"
      />
      <h5
        v-if="title"
        class="base-rimconfig-card-car-selector__title"
      >
        {{ title }}
      </h5>
      <slot name="custom-title" />
      <slot name="custom-header-content" />
    </div>
    <div class="base-rimconfig-card-car-selector__body">
      <slot name="body" />
    </div>
  </div>
</template>

<style lang="scss">
@import '@/styles/components/base-rimconfig-card-car-selector.scss';
</style>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    customTitle: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
