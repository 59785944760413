import { WheelService } from '@/services';
import { networkStatus } from '@/constants/networkStatus';
import { isObjectEmpty } from '@/utils';

import {
  SET_WHEEL_MANUFACTURERS,
  SET_WHEEL_DESIGNS,
  SET_WHEELS_CONCATENATED,
  SET_WHEELS_BY_QUERY,
} from '../mutationTypes';

const state = {
  wheelManufacturers: [],
  wheelDesigns: [],
  wheelsConcatenated: {},
  wheelsByQuery: [],
};

const mutations = {
  [SET_WHEEL_MANUFACTURERS](stateData, data) {
    stateData.wheelManufacturers = data;
  },
  [SET_WHEEL_DESIGNS](stateData, data) {
    stateData.wheelDesigns = data;
  },
  [SET_WHEELS_CONCATENATED](stateData, data) {
    stateData.wheelsConcatenated = data;
  },
  [SET_WHEELS_BY_QUERY](stateData, data) {
    stateData.wheelsByQuery = data;
  },
};

const actions = {
  async getWheelManufacturers({ commit }) {
    const response = await WheelService.getWheelManufacturers();

    if (response.status === networkStatus['ok']) {
      commit(SET_WHEEL_MANUFACTURERS, response.data);
    }

    return response;
  },
  async getWheelDesigns({ commit }, payload) {
    const response = await WheelService.getWheels(payload);

    if (response.status === networkStatus['ok']) {
      commit(SET_WHEEL_DESIGNS, response.data);
    }

    return response;
  },
  async getWheelsConcatenated({ commit }, payload) {
    const response = await WheelService.getWheelsConcatenated(payload);

    if (response.status === networkStatus['ok']) {
      commit(SET_WHEELS_CONCATENATED, response.data);
    }

    return response;
  },
  async getWheelsByQuery({ commit }, payload) {
    const response = await WheelService.getWheelsByQuery(payload);

    if (response.status === networkStatus['ok']) {
      commit(SET_WHEELS_BY_QUERY, response.data);
    }

    return response;
  },
};

const getters = {
  getGroupedWheelsByDesignAndColor: (stateData) => {
    let result;
    result = [
      ...stateData.wheelDesigns.reduce((acc, cur) => {
        const key = `${cur.design.toLowerCase()}${cur.color.toLowerCase()}`;
        const item = acc.get(key) ?
          {
            ...acc.get(key),
            children: [
              ...acc.get(key).children,
              cur,
            ],
          } :
          {
            ...cur,
            children: [cur],
          };
        return acc.set(key, item);
      }, new Map()).values(),
    ].map((group) => {
      return {
        ...group,
        children: group.children,
      };
    });
    return result;
  },
  getWheelsSelectFilterOptions: (stateData) => (fields) => {
    if (isObjectEmpty(stateData.wheelsConcatenated)) {
      return {};
    }

    let result = {};

    fields.forEach((field) => {
      if (stateData.wheelsConcatenated[field.key]) {
        result[field.key] = Object.values(stateData.wheelsConcatenated[field.key])
          .map((value) => {
            return {
              label: value,
              value: value,
            };
          });
      }
    });

    return result;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};