import { CarService, AssignService, TpmsService } from '@/services';
import { networkStatus } from '@/constants/networkStatus';
import { getImageBySize } from '@/utils';

import {
  SET_RIMCONFIG_CAR_DATA,
  SET_CAR_WHEELS,
  SET_CAR_WHEELS_NEXT_PAGE,
  SET_CAR_WHEELS_CONCATENATED,
  SET_CAR_WHEELS_SELECTED_FILTERS,
  SET_CAR_WHEELS_ASSIGNS,
  SET_CAR_IMAGE,
  SET_SELECTED_WHEEL,
  SET_PREV_CAR_TAG,
  SET_CAR_COC_DATA,
  SET_CERTIFICATE_TEXTS,
  SET_SELECTED_CONFIGURATOR_WHEEL,
  SET_SELECTED_CONFIGURATOR_TPMS,
  SET_SELECTED_CONFIGURATOR_TYRE,
  RESET_SELECTED_CONFIGURATOR_WHEEL,
  RESET_SELECTED_CONFIGURATOR_TPMS,
  RESET_SELECTED_CONFIGURATOR_TYRE,
  SET_TPMS_DATA,
} from '../mutationTypes';
import { isObjectEmpty } from '@/utils';

const state = {
  carData: {},
  carWheels: [],
  carImage: {},
  selectedWheel: {},
  prevCarTag: '',
  carCocData: [],
  carWheelAssignByWheelTag: {},
  carWheelsConcatenated: {},
  carWheelsSelectedFilters: {},
  certificateTexts: {},

  tpmsTable: [],

  selectedConfiguratorItems: {
    wheel: { },
    tpms: { },
    tyre: { },
  },
  tpmsData: [],
};

const mutations = {
  [SET_RIMCONFIG_CAR_DATA](stateData, data) {
    stateData.carData = {
      ...data,
    };
  },
  [SET_CAR_WHEELS](stateData, data) {
    stateData.carWheels = [...data];
  },
  [SET_CAR_WHEELS_NEXT_PAGE](stateData, data) {
    stateData.carWheels = [
      ...stateData.carWheels,
      ...data,
    ];
  },
  [SET_CAR_IMAGE](stateData, data) {
    stateData.carImage = {
      ...data[0],
    };
  },
  [SET_SELECTED_WHEEL](stateData, data) {
    stateData.selectedWheel = data;
  },
  [SET_PREV_CAR_TAG](stateData, data) {
    stateData.prevCarTag = data;
  },
  [SET_CAR_COC_DATA](stateData, data) {
    stateData.carCocData = [...data];
  },
  [SET_CAR_WHEELS_ASSIGNS](stateData, { wheelTag, data}) {
    stateData.carWheelAssignByWheelTag = {
      ...stateData.carWheelAssignByWheelTag,
      [wheelTag]: data,
    };
  },
  [SET_CAR_WHEELS_CONCATENATED](stateData, data) {
    stateData.carWheelsConcatenated = {
      ...data,
    };
  },
  [SET_CAR_WHEELS_SELECTED_FILTERS](stateData, data) {
    stateData.carWheelsSelectedFilters = {
      ...data,
    };
  },
  [SET_CERTIFICATE_TEXTS](stateData, data) {
    stateData.certificateTexts = {
      ...data,
    };
  },
  [SET_SELECTED_CONFIGURATOR_WHEEL](stateData, data) {
    const { assignIds, quantity, cartItems, isCombination } = data;
    stateData.selectedConfiguratorItems.wheel = {
      isCombination: isCombination,
      assignIds: [...assignIds],
      quantity: Number(quantity),
      cartItems: [...cartItems],
    };
  },
  [SET_SELECTED_CONFIGURATOR_TPMS](stateData, data) {
    const { assignId, quantity, cartItems } = data;
    stateData.selectedConfiguratorItems.tpms = {
      assignId: assignId,
      quantity: Number(quantity),
      cartItems: cartItems,
    };
  },
  [SET_SELECTED_CONFIGURATOR_TYRE](stateData, data) {
    const { assignId, quantity, cartItems } = data;
    stateData.selectedConfiguratorItems.tyre = {
      assignId: assignId,
      quantity: Number(quantity),
      cartItems: [...cartItems],
    };
  },
  [RESET_SELECTED_CONFIGURATOR_WHEEL](stateData) {
    stateData.selectedConfiguratorItems.wheel = {};
  },
  [RESET_SELECTED_CONFIGURATOR_TPMS](stateData) {
    stateData.selectedConfiguratorItems.tpms = {};
  },
  [RESET_SELECTED_CONFIGURATOR_TYRE](stateData) {
    stateData.selectedConfiguratorItems.tyre = {};
  },
  [SET_TPMS_DATA](stateData, data) {
    stateData.tpmsData = data;
  },
};

const actions = {
  async loadCarByCarTag({ commit }, { carTag }) {
    const response = await CarService.getCarByCarTag({
      carTag,
    });

    if (response.status === networkStatus['ok']) {
      commit(SET_RIMCONFIG_CAR_DATA, response.data);
    }

    return response;
  },
  async getCarGroupByGermanCode({ commit }, payload) {
    const response = await CarService.getCarGroupByGermanCode(payload);
    return response;
  },
  async getCarWheels({ commit }, payload) {
    let adjustedPayload = {
      minStock: 1,
      ...payload,
    };
    const response = await CarService.getCarWheels(adjustedPayload);
    if (response.status === networkStatus.ok) {
      commit(SET_CAR_WHEELS, response.data);
    }
    return response;
  },
  async getCarWheelsNextPage({ commit }, payload) {
    let adjustedPayload = {
      minStock: 1,
      ...payload,
    };
    const response = await CarService.getCarWheels(adjustedPayload);
    if (response.status === networkStatus.ok) {
      commit(SET_CAR_WHEELS_NEXT_PAGE, response.data);
    }
    return response;
  },
  async getCarImage({ commit }, payload) {
    const response = await CarService.getCarImage(payload);

    commit(SET_CAR_IMAGE, response.data);

    return response;
  },
  async getCarWheelsConcatenated({ commit }, payload) {
    let adjustedPayload = {
      minStock: 1,
      ...payload,
    };
    const response = await CarService.getCarWheelsConcatenated(adjustedPayload);
    if (response.status === networkStatus['ok']) {
      commit(SET_CAR_WHEELS_CONCATENATED, response.data);
    }
    return response;
  },
  async getCertificateTexts({ commit }, payload) {
    const response = await CarService.getCertificateTexts(payload);
    if (response.status === networkStatus['ok']) {
      commit(SET_CERTIFICATE_TEXTS, response.data);
    }
    return response;
  },
  async getCarWheelAssigns({ commit }, { wheelTag, assigns }) {
    const response = await AssignService.getAssigns({ ids: assigns.join() });

    if (response.status === networkStatus['ok']) {
      commit(SET_CAR_WHEELS_ASSIGNS, {
        wheelTag: wheelTag,
        data: response.data,
      });
    }

    return response;
  },
  clearWheelOverview({ commit }) {
    commit(SET_CAR_WHEELS, []);
  },
  setSelectedWheel({ commit }, payload) {
    commit(SET_SELECTED_WHEEL, payload);
  },
  setPrevCarTag({ commit }, carTag) {
    commit(SET_PREV_CAR_TAG, carTag);
  },
  async getCarCoc({ commit }, catTags = []) {
    const response = await CarService.getCarCoc(catTags.join());

    if (response.status === networkStatus['ok']) {
      commit(SET_CAR_COC_DATA, response.data);
    }

    return response;
  },
  setSeletedFilters({ commit }, data) {
    commit(SET_CAR_WHEELS_SELECTED_FILTERS, data);
  },
  selectConfiguratorWheel({ commit }, data) {
    commit(SET_SELECTED_CONFIGURATOR_WHEEL, data);
    return data;
  },
  selectConfiguratorTpms({ commit }, data) {
    commit(SET_SELECTED_CONFIGURATOR_TPMS, data);
    return data;
  },
  selectConfiguratorTyre({ commit }, data) {
    commit(SET_SELECTED_CONFIGURATOR_TYRE, data);
    return data;
  },
  setSelectConfiguratorStep({ commit }, step = 1) {
    /**
     * step
     * 1 -> select wheel
     * 2 -> select tpms
     * 3 -> select tyre
     */
    commit(RESET_SELECTED_CONFIGURATOR_TYRE);
    if (step === 1) {
      commit(RESET_SELECTED_CONFIGURATOR_TPMS);
      commit(RESET_SELECTED_CONFIGURATOR_WHEEL);
    } else if (step === 2) {
      commit(RESET_SELECTED_CONFIGURATOR_TPMS);
    }
    return step;
  },
  async loadTpmsCar({ commit }, payload = {}) {
    const response = await TpmsService.getSensors(payload);

    if (response.status === networkStatus['ok']) {
      commit(SET_TPMS_DATA, response.data);
    }

    return response;
  },
  clearTpmsCar({ commit }) {
    commit(SET_TPMS_DATA, []);
  },
};

const getters = {
  getGroupedCarWheelsByDiameter(stateData) {
    if (stateData.carWheels.length === 0) {
      return [];
    }
    const carWheelsWithAxisB = stateData.carWheels
      .filter((item) => {
        return item.wheels.some((wheel) => wheel.axisOnly === 'B');
      });

    // Get result with only axisOnly = 'B'
    const result = carWheelsWithAxisB.map((item) => {
      return {
        ...item,
        possibleDiameters: Object.fromEntries(
          item.wheels
            .filter((wheel) => wheel.axisOnly === 'B')
            .reduce((acc, cur) => {
              const key = cur.diameter;
              const wheelItem = acc.get(key) ?
                [
                  ...acc.get(key),
                  cur,
                ] :
                [cur];
              return acc.set(key, wheelItem);
            }, new Map()),
        ),
        wheels: item.wheels.filter((wheel) => wheel.axisOnly === 'B'),
      };
    }).map((group) => {
      let diameterPriceObject = {};
      Object.keys(group.possibleDiameters).forEach((key) => {
        diameterPriceObject = {
          ...diameterPriceObject,
          [key]: group.possibleDiameters[key].map((item) => item.prices.bestPrice)
            .reduce((acc, cur) => {
              return cur < acc ? cur : acc;
            }),
        };
      });

      const getMinimumDiameterPrice = Object.keys(group.possibleDiameters).map((key) => {
        return group.possibleDiameters[key].map((item) => item.prices.bestPrice)
          .reduce((acc, cur) => {
            return cur < acc ? cur : acc;
          });
      })[0];
      return {
        ...group,
        minimumDiameterPrice: getMinimumDiameterPrice,
        diameterPriceObject: diameterPriceObject,
      };
    });
    return result;
  },
  getCarWheelsSelectFilterOptions: (stateData) => (fields) => {
    if (isObjectEmpty(stateData.carWheelsConcatenated)) {
      return {};
    }

    let result = {};

    fields.forEach((field) => {
      if (stateData.carWheelsConcatenated[field.key]) {
        result[field.key] = Object.values(stateData.carWheelsConcatenated[field.key])
          .map((value) => {
            return {
              label: value,
              value: value,
            };
          });
      }
    });

    return result;
  },
  getTpmsData: (stateData) => {
    return stateData.tpmsData.forEach((element) => {
      return {
        articleId: element.articleId,
        brand: element.manufacturer,
        type: element.brand,
        image: getImageBySize(element.imageTemplate, 100, 30),
        article: `${element.description1} ${element.description2}`,
        bjVon: element.minCarBuildDate,
        bjBis: element.maxCarBuildDate,
        stock: 0,
        price: 0,
        totalPrice: 0,
      };
    });
  },
  getTyreSizeSelectFilterOptions: (stateData) => (params, query) => {
    if (stateData.carWheels.length === 0) {
      return [];
    }

    // Car wheels with only axisOnly = 'B'
    const carWheelsWithAxisBOnly = stateData.carWheels.slice()
      .filter((item) => {
        return item.wheels && item.wheels.some((wheel) => wheel.axisOnly === 'B');
      })
      .map((filteredItem) => {
        return {
          ...filteredItem,
          wheels: filteredItem.wheels && filteredItem.wheels.filter((wheel) => wheel.axisOnly === 'B'),
        };
      });

    const selectedWheels = carWheelsWithAxisBOnly.filter((wheel) => {
      return wheel.manufacturer.toLowerCase() === query.manufacturer.toLowerCase() &&
        wheel.design.toLowerCase() === query.design.toLowerCase() &&
        wheel.color.toLowerCase() === query.color.toLowerCase();
    });

    const isCombination = params.selectedWheel.includes('+') ? true : false;
    let wheelArticleId;
    let result = [];

    if (!isCombination) {
      wheelArticleId = params.selectedWheel;
      const selectedWheel = selectedWheels[0].wheels.find((wheel) => {
        return wheel.man === wheelArticleId;
      });

      const selectedWheelFrontTyres = selectedWheel.tyres && selectedWheel.tyres.front;
      if (selectedWheelFrontTyres) {
        result = selectedWheelFrontTyres.map((tyre) => {
          return {
            label: `${tyre.width}/${tyre.aspectratio} ${tyre.size}`,
            value: `${tyre.width}/${tyre.aspectratio} ${tyre.size}`,
          };
        });
      }
    } else {
      wheelArticleId = {
        front: params.selectedWheel.split('+')[0],
        rear: params.selectedWheel.split('+')[1],
      };
      const selectedWheelCombinationFront = selectedWheels[0].wheels.find((wheel) => {
        return wheel.man === wheelArticleId.front;
      });
      const selectedWheelCombinationRear = selectedWheels[0].wheels.find((wheel) => {
        return wheel.man === wheelArticleId.rear;
      });

      const selectedWheelCombinationFrontTyres = selectedWheelCombinationFront.tyres && selectedWheelCombinationFront.tyres.front;
      if (selectedWheelCombinationFrontTyres) {
        selectedWheelCombinationFrontTyres.forEach((frontTyre) => {
          frontTyre.rear.forEach((rearTyre) => {
            result.push({
              ...frontTyre,
              rearTyre: rearTyre,
            });
          });
        });
        result = result.map((combination) => {
          let dimension = `${combination.width}/${combination.aspectratio} ${combination.size} - ${combination.rearTyre.width}/${combination.rearTyre.aspectratio} ${combination.rearTyre.size}`;
          return {
            label: dimension,
            value: dimension,
          };
        });
      }
    }
    return result;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
