import { apiClient, apiSimple } from '@/services';

export const UserService = {
  checkChangePasswordStatus() {
    return apiClient.get('/user/password');
  },
  confirmEmail({ email }) {
    const params = new URLSearchParams();
    params.append('email', email);
    return apiClient.patch('/user/email', params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  },
  confirmPasswordChange({ password_current, password_new }) {
    const params = new URLSearchParams();
    params.append('password_current', password_current);
    params.append('password_new', password_new);
    return apiClient.patch('/user/password', params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  },
  confirmPasswordChangeWithToken({ password_current, password_new, token }) {
    const params = new URLSearchParams();
    params.append('password_current', password_current);
    params.append('password_new', password_new);
    return apiSimple.patch('/user/password', params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `Bearer ${token}`,
      },
    });
  },
  passwordRecovery({ mail }) {
    return apiClient.head(`/user/password/recovery/${mail}`);
  },
  checkPasswordRecoveryToken({ token, secret }) {
    return apiClient.head(`/user/password/recovery/${token}/${secret}`);
  },
  confirmPasswordRecovery({ password, token, secret }) {
    const params = new URLSearchParams();
    params.append('password', password);
    return apiClient.patch(`/user/password/recovery/${token}/${secret}`, params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  },
  getUserInformation() {
    return apiClient.get('/user/');
  },
  getUserAddresses() {
    return apiClient.get('/user/address');
  },
  addUserAddresses({ type = 'delivery_address', name, street, streetNumber, zip, city, defaultValue, countryCode }) {
    return apiClient.post('/user/address', {
      type,
      name,
      street,
      streetNumber,
      zip: `${zip}`,
      city,
      default: defaultValue,
      countryCode,
    });
  },
  updateUserAddresses({ id, type = 'delivery_address', name, street, streetNumber, zip, city, defaultValue, countryCode }) {
    return apiClient.patch(`/user/address/${id}`, {
      id,
      type,
      name,
      street,
      streetNumber,
      zip: `${zip}`,
      city,
      default: defaultValue,
      countryCode,
    });
  },
  deleteUserAddress(id) {
    return apiClient.delete(`/user/address/${id}`);
  },
  deleteAllAddress() {
    return apiClient.delete('/user/address');
  },
  checkUserType() {
    return apiClient.get('/user/type/demo');
  },
  getUserLoginInformation() {
    return apiClient.get('/user/loginInformation');
  },
  setUserLoginInformation(data) {
    const params = new URLSearchParams();
    params.append('display', data);
    return apiClient.patch(
      '/user/loginInformation',
      params,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
  },
  checkAllowAddress() {
    return apiClient.head('/user/address');
  },
};