export const usageTranslationItems = {
  'air tyre': 'langkey.industrial-air-tyre',
  'solid tyre': 'langkey.industrial-solid-tyre',
  'bandage': 'langkey.industrial-bandage',
  'loader/dozer': 'langkey.em-loader-dozer',
  'industry device': 'langkey.em-Industry-Device',
  'mobilcrane': 'langkey.em-mobilecrane',
  'small tyre': 'langkey.as-small-tyre',
  'mpt/industry': 'langkey.as-mpt-industry',
  'traction': 'langkey.as-traction',
  'front': 'langkey.as-front',
  'implement': 'langkey.as-implement',
};