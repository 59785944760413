import {
  SET_CART_DATA,
  ADD_QUANTITY_CART_ITEM,
  SUBTRACT_QUANTITY_CART_ITEM,
  UPDATE_QUANTITY_CART_ITEM,
  CLEAR_CART_ITEM,
} from '../mutationTypes';

const state = {
  cartItem: {},
};

const mutations = {
  [SET_CART_DATA](stateData, data) {
    stateData.cartItem = {
      imageTemplate: data.imageTemplate,
      description: data.description1,
      supplier: data.group,
      articleId: data.articleId,
      ean: data.ean || '',
      quantity: data.quantity || 1,
      price: data.price || 0,
    };
  },
  [ADD_QUANTITY_CART_ITEM](stateData) {
    stateData.cartItem.quantity++;
  },
  [SUBTRACT_QUANTITY_CART_ITEM](stateData) {
    stateData.cartItem.quantity > 0 ? stateData.cartItem.quantity-- : 0;
  },
  [UPDATE_QUANTITY_CART_ITEM](stateData, quantity) {
    stateData.cartItem.quantity = quantity;
  },
  [CLEAR_CART_ITEM](stateData, data) {
    stateData.cartItem = {};
  },
};

const actions = {
  addToCart({ commit }, item) {
    commit(SET_CART_DATA, item);
  },
  addQuantity({ commit }) {
    commit(ADD_QUANTITY_CART_ITEM);
  },
  subtractQuantity({ commit }) {
    commit(SUBTRACT_QUANTITY_CART_ITEM);
  },
  updateQuantity({ commit }, quantity) {
    commit(UPDATE_QUANTITY_CART_ITEM, quantity);
  },
  clearCartDialog({ commit }, item){
    commit(CLEAR_CART_ITEM, item);
  },
};

const getters = {
  getCartItem: (state) => {
    return state.cartItem;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};