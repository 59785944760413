<template>
  <base-dialog
    ref="infoRef"
    :title="$t('langkey.modal-info-header')"
    dialog-class="info-dialog"
  >
    <div class="nav__items">
      <div class="nav__scroll">
        <navigation-tab
          :item="navbar.carTire"
          :class="[
            navbar.carTire.to === selectedTab ? 'link-active' : null
          ]"
          is-select
          @click="selectedTab = navbar.carTire.to"
        />
        <navigation-tab
          :item="navbar.truckTire"
          :class="[
            navbar.truckTire.to === selectedTab ? 'link-active' : null
          ]"
          is-select
          @on-select="selectedTab = navbar.truckTire.to"
        />
        <navigation-tab
          :item="navbar.motorbikeTire"
          :class="[
            navbar.motorbikeTire.to === selectedTab ? 'link-active' : null
          ]"
          is-select
          @on-select="selectedTab = navbar.motorbikeTire.to"
        />
        <navigation-tab
          :item="navbar.rimsConfig"
          :class="[
            selectedTab.includes(navbar.rimsConfig.to) ? 'link-active' : null
          ]"
          is-select
          @on-select="selectedTab = navbar.rimsConfig.to"
        />
        <navigation-tab
          :item="navbar.service"
          :class="[
            navbar.service.to === selectedTab ? 'link-active' : null
          ]"
          is-select
          @on-select="selectedTab = navbar.service.to"
        />
        <navigation-tab
          :item="navbar.industrial"
          :class="[
            navbar.industrial.to === selectedTab ? 'link-active' : null
          ]"
          is-select
          @on-select="selectedTab = navbar.industrial.to"
        />
        <navigation-tab
          :item="navbar.agriculture"
          :class="[
            navbar.agriculture.to === selectedTab ? 'link-active' : null
          ]"
          is-select
          @on-select="selectedTab = navbar.agriculture.to"
        />
        <navigation-tab
          :item="navbar.earthMover"
          :class="[
            navbar.earthMover.to === selectedTab ? 'link-active' : null
          ]"
          is-select
          @on-select="selectedTab = navbar.earthMover.to"
        />
      </div>
    </div>
    <img
      v-if="isShowMatchcodeInfo"
      :src="require('@/assets/images/MatchcodeInfo.png')"
      class="info-img"
    >
    <div v-if="selectedTab.includes(navbar.rimsConfig.to)">
      <p v-html="$t('langkey.modal-info-message_wheels')" />
      <img
        :src="require('@/assets/images/RimsConfigModule.webp')"
        class="wheels-module-img"
      >
    </div>
    <div v-else-if="selectedTab === navbar.service.to">
      <p v-html="$t('langkey.modal-info-message_spare-parts')" />
      <img
        :src="require('@/assets/images/SparePartsModule.webp')"
        class="spare-parts-module-img"
      >
    </div>
    <v-row v-else>
      <v-col
        cols="
        4"
      >
        <p v-html="getInfoMessageOne" />
      </v-col>
      <v-col cols="8">
        <p v-html="getInfoMessageTwo" />
      </v-col>
    </v-row>
    <v-row v-if="selectedTab === navbar.agriculture.to">
      <v-col>
        <p v-html="$t('langkey.angkey.modal-info-message_as3')" />
      </v-col>
    </v-row>
    <div
      style="display: inline-block;"
      class="mt-7"
    >
      <base-checkbox
        v-model="isNotDisplay"
        :label-text="$t('langkey.unset-modal-matchcode-info')"
        reverse
        @input="onSelectItem"
      />
    </div>
  </base-dialog>
</template>

<style lang="scss">
@import '@/styles/components/info-dialog.scss';
</style>

<script>
import NavigationTab from '@/components/NavigationTab';

export default {
  components: {
    NavigationTab,
  },
  data() {
    return {
      isNotDisplay: false,
      selectedTab: '',
      navbar: {
        carTire: {
          to: 'tyre-car',
          class: 'tyre-car',
          isGroup: true,
          children: [
            {
              title: 'langkey.car',
              icon: 'car',
            },
            {
              title: 'langkey.suv',
              icon: 'suv',
            },
            {
              title: 'langkey.van',
              icon: 'van',
            },
          ],
        },
        suvTire: {
          title: 'langkey.suv',
          icon: 'suv',
          to: 'tyre-suv',
        },
        vanTire: {
          title: 'langkey.van',
          icon: 'van',
          to: 'tyre-van',
        },
        truckTire: {
          title: 'langkey.truck',
          icon: 'truck',
          to: 'tyre-truck',
        },
        motorbikeTire: {
          title: 'langkey.motorbike',
          icon: 'bike',
          to: 'tyre-motorbike',
        },
        rimsConfig: {
          title: 'langkey.rims-config',
          icon: 'rim',
          to: 'rims-config',
        },
        service: {
          title: 'langkey.car-service',
          icon: 'service',
          to: 'car-service',
        },
        industrial: {
          title: 'langkey.industrial',
          icon: 'industrial',
          to: 'tyre-industrial',
        },
        agriculture: {
          title: 'langkey.agriculture',
          icon: 'agriculture',
          to: 'tyre-agriculture',
        },
        earthMover: {
          title: 'langkey.earthMover',
          icon: 'earth-mover',
          to: 'tyre-earthMover',
        },
      },
    };
  },
  computed: {
    getInfoMessageOne() {
      if (this.selectedTab === 'tyre-suv') {
        return this.$t('langkey.modal-info-message_suv1');
      }
      if (this.selectedTab === 'tyre-van') {
        return this.$t('langkey.modal-info-message_van1');
      }
      if (this.selectedTab === 'tyre-truck') {
        return this.$t('langkey.modal-info-message_truck1');
      }
      if (this.selectedTab === 'tyre-motorbike') {
        return this.$t('langkey.modal-info-message_bike1');
      }
      if (this.selectedTab === 'tyre-agriculture') {
        return this.$t('langkey.modal-info-message_as1');
      }
      if (this.selectedTab === 'tyre-industrial') {
        return this.$t('langkey.modal-info-message_ind1');
      }
      if (this.selectedTab === 'tyre-earthMover') {
        return this.$t('langkey.modal-info-message_em1');
      }
      return this.$t('langkey.modal-info-message1');
    },
    getInfoMessageTwo() {
      if (this.selectedTab === 'tyre-suv') {
        return this.$t('langkey.modal-info-message_suv2');
      }
      if (this.selectedTab === 'tyre-van') {
        return this.$t('langkey.modal-info-message_van2');
      }
      if (this.selectedTab === 'tyre-truck') {
        return this.$t('langkey.modal-info-message_truck2');
      }
      if (this.selectedTab === 'tyre-motorbike') {
        return this.$t('langkey.modal-info-message_bike2');
      }
      if (this.selectedTab === 'tyre-agriculture') {
        return this.$t('langkey.modal-info-message_as2');
      }
      if (this.selectedTab === 'tyre-industrial') {
        return this.$t('langkey.modal-info-message_ind2');
      }
      if (this.selectedTab === 'tyre-earthMover') {
        return this.$t('langkey.modal-info-message_em2');
      }
      return this.$t('langkey.modal-info-message2');
    },
    isShowMatchcodeInfo() {
      return !this.selectedTab.includes(this.navbar.rimsConfig.to) &&
        this.selectedTab !== this.navbar.service.to;
    },
  },
  methods: {
    show() {
      this.$refs.infoRef.show();
      if (this.$route.query && this.$route.query.fromPage) {
        this.selectedTab = this.$route.query.fromPage;
      } else {
        this.selectedTab = this.$route.name;
      }
    },
    hide() {
      this.$refs.infoRef.hide();
    },
    onSelectItem() {
      this.$store.dispatch('dialog/setUserLoginInformation', {
        status: !this.isNotDisplay,
      });
    },
  },
};
</script>