<template>
  <v-dialog
    v-model="showError"
    max-width="500"
    @click:outside="onClose"
  >
    <v-card>
      <v-card-title class="headline">
        <div v-html="errorTitle" />
      </v-card-title>
      <v-card-text>
        <VNodeBridge
          v-if="errorMessageElement"
          :nodes="errorMessageElement"
        />
        <div
          v-else
          v-html="errorMessage"
        />
      </v-card-text>
      <v-card-actions>
        <VNodeBridge
          v-if="errorMessageElementAction"
          :nodes="errorMessageElementAction"
        />
        <v-spacer />
        <v-btn
          text
          @click="onClose"
        >
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('dialog', [
      'showError',
      'errorTitle',
      'errorMessage',
      'errorMessageElement',
      'errorMessageElementAction',
    ]),
  },
  methods: {
    onClose() {
      this.$store.dispatch('dialog/setShowErrorDialog', {
        status: false,
      });
    },
  },
};
</script>