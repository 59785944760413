import { apiClient } from '@/services';

export const WheelService = {
  getWheelManufacturers() {
    return apiClient.get('/wheels/overview/manufacturers');
  },
  getWheels(payload) {
    return apiClient.get('/wheels', {
      params: payload,
    });
  },
  getWheelsConcatenated(payload) {
    return apiClient.get('/wheels/concatenated', {
      params: {
        ...payload,
      },
    });
  },
  getWheelsByQuery({ query }) {
    return apiClient.get(`/wheels/search/${query}`);
  },
};