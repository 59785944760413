<template>
  <v-dialog
    v-model="showDialog"
    max-width="1000"
    @click:outside="hide"
  >
    <v-card class="base-dialog rimconfig-car-search-history-dialog">
      <v-card-title
        class="headline"
      >
        <h2
          class="title-text"
        >
          <icon-history
            color="var(--theme-black)"
            size="30"
            style="margin-bottom: -6px;"
          />
          {{ $t(title) }}
        </h2>
        <v-spacer />
        <v-btn
          class="button__close"
          small
          icon
          @click="hide"
        >
          <icon-close />
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-data-table
          class="primary-style"
          :headers="tableFieldCarSearchHistory"
          :items="carHistories"
          hide-default-footer
          disable-pagination
          :no-data-text="$t('langkey.no-data')"
          @click:row="onRowSelect"
        >
          <template
            v-for="head in tableFieldCarSearchHistory"
            v-slot:[`header.${head.value}`]="{ header }"
          >
            <div
              :key="head.value"
              class="d-flex align-center justify-center"
            >
              <span>{{ $t(header.text) }}</span>
              <div class="icon__sort" />
            </div>
          </template>
          <template v-slot:[`item.image`]="{ item }">
            <img
              v-show="item.image"
              v-lazy="item.image"
              class="car-search-history-img"
            >
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style lang="scss">
@import '@/styles/components/rimconfig-car-search-history-dialog.scss';
</style>

<script>
import IconHistory from '@/components/icons/IconHistory';
import { tableFieldCarSearchHistory } from '@/configs/components/rimconfigCarSearchHistoryDialog';
import { mapState, mapGetters } from 'vuex';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import de from 'dayjs/locale/de';
import updateLocale from 'dayjs/plugin/updateLocale';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(updateLocale);

export default {
  components: {
    IconHistory,
  },
  data() {
    return {
      showDialog: false,
      title: 'langkey.car-history-header',
    };
  },
  computed: {
    ...mapState({
      carHistory: (state) => state.rimconfigCarSelector.carHistory,
    }),
    ...mapGetters({
      getLocale: 'getLocale',
    }),
    tableFieldCarSearchHistory() {
      return tableFieldCarSearchHistory;
    },
    historyItemLength() {
      return this.carHistories.length;
    },
    carHistories() {
      return this.carHistory.slice().reverse().slice(0, 10).map((item) => {
        return {
          image: this.getImagePath(item.car.group, '250x200'),
          description: `${item.car.manufacturer} ${item.car.modelShortcut}`,
          version: `${this.getProduction(item.car)} ${item.car.type}`,
          searchTime: this.getSearchDay(item.date),
          carTag: item.car.tag,
        };
      });
    },
  },
  methods: {
    getImagePath(group, size) {
      const imagePrefix = 'https://cdn.jfnet.de/carPerspective/';
      const imageSuffix = `/${size}-cc-xxxxxx.png`;
      return `${imagePrefix}${group.toLowerCase()}${imageSuffix}`;
    },
    getSearchDay(date) {
      return dayjs(date).tz('Europe/Berlin').locale(this.getLocale).format('HH:mm, DD MMMM YYYY');
    },
    getProduction({ productionFrom, productionTo }) {
      const from = productionFrom.split('-');
      const to = productionTo.split('-');
      return `${from[0] !== '0000' ? from[0] : ''} - ${to[0] !== '0000' ? to[0] : ''}`;
    },
    async onRowSelect(item) {
      this.$store.dispatch('dialog/setLoadingDialog', {
        status: true,
      });
      await this.$store.dispatch('rimconfigCarSelector/setCarHistory', item.carTag);
      await this.$store.dispatch('rimconfigCarSelector/getCarHistory');
      this.$store.dispatch('dialog/setLoadingDialog', {
        status: false,
      });
      window.location.href = `/${this.$route.params.lang}/rims-config/configurator/${item.carTag}`;
      // this.$router.push(
      //   this.$i18nRoute({
      //     name: 'rims-config-configurator_carTag',
      //     params: {
      //       carTag: item.carTag,
      //     },
      //   }),
      // );
      // this.showDialog = false;
    },
    show() {
      this.showDialog = true;
    },
    hide() {
      this.showDialog = false;
    },
  },
};
</script>