import { apiClient } from '@/services';
import axios from 'axios';

let getTyresRequest;
let getTyresConcatenatedRequest;

export const TyreService = {
  getTyres(filters) {
    if (getTyresRequest) {
      getTyresRequest.cancel('Cancel previous request');
    }
    const CancelToken = axios.CancelToken;
    getTyresRequest = CancelToken.source();

    return apiClient.get('/tyres/', {
      params: {
        ...filters,
        page: filters.page || 1,
        maxResult: 100,
      },
      cancelToken: getTyresRequest.token,
    });
  },
  getTyresByTyreTag({ eans }) {
    // return apiClient.get(`/tyres/${tag}`);
    return apiClient.get('/tyres/', {
      params: {
        eans: eans,
      },
    });
  },
  getTyresConcatenated(filters) {
    if (getTyresConcatenatedRequest) {
      getTyresConcatenatedRequest.cancel('Cancel previous request');
    }
    const CancelToken = axios.CancelToken;
    getTyresConcatenatedRequest = CancelToken.source();

    return apiClient.get('/tyres/concatenated', {
      params: {
        ...filters,
      },
      cancelToken: getTyresConcatenatedRequest.token,
    });
  },
  getTyreTagFromSearch({ searchValue, adjustedFilters = {} }) {
    const { page, maxResult, vehicleTypes } = adjustedFilters;
    return apiClient.get(`/tyres/search/${encodeURI(searchValue)}`, {
      params: {
        page: page || 1,
        maxResult,
        vehicleTypes,
      },
    });
  },

  getTyreImage({ eans }) {
    return apiClient.get('/tyres/image', {
      params: {
        eans,
      },
    });
  },
  getTyreLabel({ eans }) {
    return apiClient.get('/tyres/label', {
      params: {
        eans,
      },
    });
  },
  getTyreDescriptionLocale({ tyreTag, locales }) {
    return apiClient.get('/tyres/description/locales', {
      params: {
        tyreTag,
        locales,
        descriptionType: 'html',
      },
    });
  },
};