import Vue from 'vue';
import Vuex from 'vuex';
import { i18n } from '@/plugins/i18n';
import rimconfigBreadcrumb from './modules/rimconfigBreadcrumb';
import rimconfigCarConfigurator from './modules/rimconfigCarConfigurator';
import rimconfigTpms from './modules/rimconfigTpms';
import rimconfigBase from './modules/rimconfigBase';
import rimconfigCarSelector from './modules/rimconfigCarSelector';
import rimconfigCarSelectorTpms from './modules/rimconfigCarSelectorTpms';
import rimconfigWheelDesign from './modules/rimconfigWheelDesign';

import dialog from './modules/dialog';
import tyre from './modules/tyre';
import baseAddToCartDialog from './modules/baseAddToCartDialog';
import security from './modules/security';
import cart from './modules/cart';
import user from './modules/user';
import registration from './modules/registration';
import order from './modules/order';
import directDebit from './modules/directDebit';
import carService from './modules/carService';
import dealerCalculation from './modules/dealerCalculation';
import pricing from './modules/pricing';

Vue.use(Vuex);

export default new Vuex.Store({
  getters: {
    getLocale() {
      return i18n.locale;
    },
  },
  modules: {
    rimconfigBase,
    rimconfigBreadcrumb,
    rimconfigCarConfigurator,
    rimconfigTpms,
    rimconfigCarSelector,
    rimconfigCarSelectorTpms,
    rimconfigWheelDesign,
    dialog,
    tyre,
    cart,
    baseAddToCartDialog,
    security,
    user,
    registration,
    order,
    directDebit,
    carService,
    dealerCalculation,
    pricing,
  },
});
