<template>
  <base-rimconfig-card-car-selector
    class="rimconfig-car-search-kba-number"
    :title="$t('langkey.rim-config-search-by')"
  >
    <template #custom-header-content>
      <div class="button-wrapper">
        <button
          v-if="!isKbaVersions"
          :class="['button-menu first', !searchByKbaNumber && !searchByEanMan && 'selected']"
          @click="setSearchByKbaNumber(false)"
        >
          {{ $t('langkey.rim-config-configurator') }}
        </button>
        <button
          :class="['button-menu', searchByKbaNumber && 'selected']"
          @click="setSearchByKbaNumber(true)"
        >
          {{ $t('langkey.rim-config-kba-number') }}
        </button>
        <v-btn
          icon
          plain
          style="position: relative;top: -1px;"
          @click="onClickKbaInfo"
        >
          <icon-info color="red" />
        </v-btn>
        <button
          v-if="isTpms"
          :class="['button-menu', searchByEanMan && 'selected']"
          class="last"
          @click="setSearchByEanMan(true)"
        >
          {{ $t('langkey.tpms-search-ean-man') }}
        </button>
      </div>
    </template>

    <template #body>
      <form
        class="search-by-code"
        @submit.prevent="submit"
      >
        <div class="input-wrapper">
          <input
            id="hsn-input"
            v-model="search1"
            type="search"
            class="input__search"
            placeholder="HSN"
          >
          <input
            id="tsn-vsn-input"
            v-model="search2"
            type="search"
            class="input__search tsn-vsn"
            placeholder="TSN / VSN"
          >
          <v-btn
            v-if="search1 || search2"
            icon
            plain
            class="button__input-clear"
            @click="clearSearch"
          >
            <icon-close />
          </v-btn>
          <span
            class="icon__search"
            @click="submit"
          >
            <icon-search color="var(--theme-black)" />
          </span>
        </div>

        <div class="description__wrapper">
          <div class="description">
            <div class="content">
              <div
                class="kba-number-info"
                @click="onZoomKbaImage"
              >
                <img :src="require('@/assets/images/kba.png')">
                <icon-zoom />
              </div>
            </div>
          </div>
        </div>

        <div class="car-history">
          <v-btn
            depressed
            plain
            block
            class="car-history__button"
          >
            <a
              @click="openCarSearchHistoryDialog"
            >
              {{ $t('langkey.rim-config-car-history') }}
            </a>
          </v-btn>
        </div>
      </form>
    </template>
  </base-rimconfig-card-car-selector>
</template>

<style lang="scss">
@import '@/styles/components/rimconfig-car-search-kba-number.scss';
</style>

<script>
import IconZoom from '@/components/icons/IconZoom';
import IconInfo from '@/components/icons/IconInfo';
import { CarService } from '@/services';
import { mapState } from 'vuex';

const addIndex = (data) => {
  return data.map((d, i) => {
    return {
      ...d,
      __index: i,
    };
  });
};

export default {
  components: {
    IconZoom,
    IconInfo,
  },
  props: {
    searchByKbaNumber: {
      type: Boolean,
      default: false,
    },
    isTpms: {
      type: Boolean,
      default: false,
    },
    isKbaVersions: {
      type: Boolean,
      default: false,
    },
    searchByEanMan: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search1: '',
      search2: '',
    };
  },
  computed: {
    ...mapState({
      carGroupByGermanCodeConfigurator: (state) => state.rimconfigCarSelector.carGroupByGermanCode,
      stepConfigurator: (state) => state.rimconfigCarSelector.step,
    }),
    ...mapState({
      stepTpms: (state) => state.rimconfigCarSelectorTpms.step,
      carGroupByGermanCodeTpms: (state) => state.rimconfigCarSelectorTpms.carGroupByGermanCode,
    }),
    step() {
      return this.isTpms ? this.stepTpms : this.stepConfigurator;
    },
    carSelectorModule() {
      return this.isTpms ? 'rimconfigCarSelectorTpms' : 'rimconfigCarSelector';
    },
    carGroupByGermanCode() {
      return this.isTpms ? this.carGroupByGermanCodeTpms : this.carGroupByGermanCodeConfigurator;
    },
  },
  watch: {
    search1(val) {
      if (val.length >= 4) {
        document.getElementById('tsn-vsn-input').focus();
      }
    },
    search2(val) {
      if (val.length === 0) {
        document.getElementById('hsn-input').focus();
      }
    },
  },
  mounted() {
    document.addEventListener('keyup', (e) => {
      if (e.key === 'Enter') {
        this.submit();
      }
    });
  },
  methods: {
    async submit(e) {
      if (e) {
        e.preventDefault();
      }
      if (this.step === 'kba-versions') {
        this.$emit('on-search-kba-versions', {
          hsn: this.search1,
          tsn: this.search2.substring(0,3),
          vsn: this.search2.substring(3),
        });
        return;
      }

      if (!this.search1 && !this.search2) return;

      // if (this.step !== 'kba-versions') {
      this.$store.dispatch('dialog/setLoadingDialog', {
        status: true,
      });
      // }

      this.$store.dispatch(`${this.carSelectorModule}/getCarGroupByGermanCode`, {
        hsn: this.search1,
        tsn: this.search2.substring(0,3),
        vsn: this.search2.substring(3),
      })
        .then(() => {
          const len = this.carGroupByGermanCode.length;

          if (!this.isTpms) {
            if (len === 0) {
              this.$store.dispatch('dialog/setLoadingDialog', {
                status: false,
              });
              this.$store.dispatch('dialog/setShowErrorDialog', {
                status: true,
                title: this.$t('langkey.kba-no-car-found-header'),
                message: this.$t('langkey.kba-no-car-found-message'),
              });
              return;
            } else if (len === 1) {
              this.$store.dispatch('rimconfigCarSelector/setCarHistory', this.carGroupByGermanCode[0].tag);
              this.$store.dispatch('rimconfigCarSelector/getCarHistory');
              this.$store.dispatch('dialog/setLoadingDialog', {
                status: false,
              });
              this.redirect(this.carGroupByGermanCode);
            } else {
              this.$store.dispatch('dialog/setLoadingDialog', {
                status: false,
              });
              this.showKbaVersions();
            }
          } else {
            if (len === 0) {
              this.$store.dispatch('dialog/setLoadingDialog', {
                status: false,
              });
              this.$store.dispatch('dialog/setShowErrorDialog', {
                status: true,
                title: this.$t('langkey.kba-no-car-found-header'),
                message: this.$t('langkey.kba-no-car-found-message'),
              });
              return;
            } else if (len === 1) {
              this.$store.dispatch('rimconfigCarSelector/setCarHistory', this.carGroupByGermanCode[0].tag);
              this.$store.dispatch('dialog/setLoadingDialog', {
                status: true,
              });
              this.$store.dispatch('rimconfigCarSelector/getCarHistory');
              this.$store.dispatch('dialog/setLoadingDialog', {
                status: false,
              });
              this.redirect(this.carGroupByGermanCode);
            } else {
              this.$store.dispatch('dialog/setLoadingDialog', {
                status: false,
              });
              this.showKbaVersions();
            }
          }
        })
        .catch((err) => {
          this.$store.dispatch('dialog/setLoadingDialog', {
            status: false,
          });
          this.$store.dispatch('dialog/setShowErrorDialog', {
            status: true,
            title: this.$t('langkey.kba-search-error-header'),
            message: err,
          });
        });
    },
    redirect(data) {
      if (this.isTpms) {
        window.location.href = `/${this.$route.params.lang}/rims-config/tpms/${data[0].tag}`;
      } else {
        window.location.href = `/${this.$route.params.lang}/rims-config/configurator/${data[0].tag}`;
      }
    },
    clearSearch1() {
      this.search1 = '';
    },
    clearSearch2() {
      this.search2 = '';
    },
    showKbaVersions() {
      this.$emit('on-show-kba-versions');
    },
    setSearchByKbaNumber(status) {
      this.$emit('set-search-by-kba-number', status);
    },
    clearSearch() {
      this.search1 = '';
      this.search2 = '';
    },
    back() {
      this.setSearchByKbaNumber(false);
    },
    onZoomKbaImage() {
      this.$emit('on-zoom-kba-image');
    },
    onClickKbaInfo() {
      this.$emit('on-click-kba-info');
    },
    openCarSearchHistoryDialog() {
      this.$emit('open-car-search-history-dialog');
    },
    setSearchByEanMan(status) {
      this.$emit('set-search-by-ean-man', status);
    },
  },
};
</script>
