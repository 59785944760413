import axios from 'axios';
import { SecurityService } from './SecurityService';
import { TyreService } from './TyreService';
import { TpmsService } from './TpmsService';
import { WheelService } from './WheelService';
import { AssignService } from './AssignService';
import { CarService } from './CarService';
import { ConfiguratorService } from './ConfiguratorService';
import { CartService } from './CartService';
import { getCookie, removeCookie } from '@/utils/js-cookie';
import { UserService } from './UserService';
import { RegisterService } from './RegisterService';
import { OrderService } from './OrderService';
import { DirectDebitService } from './DirectDebitService';
import { VidikatService } from './VidikatService';
import { DealerCalculationService } from './DealerCalculationService';
import router from '@/router';
import store from '@/store';
import { i18n } from '@/plugins/i18n';

export const BASE_API_URL = process.env.NODE_ENV === 'development' || window.location.host.includes('dev')
  ? 'https://test.api.tyresnparts.com/frontend/v1'
  : 'https://test.api.tyresnparts.com/frontend/v1';
export const apiClient = axios.create({
  baseURL: BASE_API_URL,
});
export const apiSimple = axios.create({
  baseURL: BASE_API_URL,
});

apiClient.interceptors.request.use((config) => {
  const userAccessToken = getCookie('user_access_token');
  if (userAccessToken) {
    config.headers.Authorization = `Bearer ${userAccessToken}`;
  }
  return config;
});

apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (!error.response) {
      return Promise.reject(error);
    }
    if (error.response.status === 422 && error.response.config.url === '/user/password') {
      return error.response;
    }
    if (error.response.status === 422 && error.response.config.url === '/user/loginInformation') {
      return error.response;
    }
    if (error.response.status === 401 && error.response.data.message.includes('JWT')) {
      removeCookie('user_access_token');
      store.dispatch('dialog/setShowErrorDialog', {
        status: true,
        title:i18n.t('langkey.token-expired'),
        message: i18n.t('langkey.token-expired-message'),
      }).then(() => {
        router.replace({ name: 'login' });
      });
    }
    return Promise.reject(error);
  },
);

apiSimple.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (!error.response) {
      return Promise.reject(error);
    }
    if (error.response.status === 422 && error.response.config.url === '/user/password') {
      return error.response;
    }
    if (error.response.status === 401 && error.response.data.message.includes('JWT')) {
      removeCookie('user_access_token');
      store.dispatch('dialog/setShowErrorDialog', {
        status: true,
        title: i18n.t('langkey.token-expired'),
        message: i18n.t('langkey.token-expired-message'),
      }).then(() => {
        router.replace({ name: 'login' });
      });
    }
    return Promise.reject(error);
  },
);

export const apiPdf = axios.create({
  baseURL: BASE_API_URL,
  headers: {
    'Content-Type': 'application/pdf',
  },
  transformRequest: [
    (data, headers) => {
      // Do whatever you want to transform the data

      const formData = new FormData();

      for (const key in data) {
        if (data[key] || data[key] === 0) {
          const value = data[key];
          formData.append(key, value);
        }
      }

      return formData;
    },
  ],
  responseType: 'arraybuffer',
});

apiPdf.interceptors.request.use((config) => {
  const userAccessToken = getCookie('user_access_token');
  if (userAccessToken) {
    config.headers.Authorization = `Bearer ${userAccessToken}`;
  }
  return config;
});

export const apiFormData = axios.create({
  baseURL: BASE_API_URL,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
  transformRequest: [
    (data, headers) => {
      // Do whatever you want to transform the data

      const formData = new FormData();

      for (const key in data) {
        if (data[key] || data[key] === 0) {
          const value = data[key];
          formData.append(key, value);
        }
      }

      return formData;
    },
  ],
});

apiFormData.interceptors.request.use((config) => {
  const userAccessToken = getCookie('user_access_token');
  if (userAccessToken) {
    config.headers.Authorization = `Bearer ${userAccessToken}`;
  }
  return config;
});

apiFormData.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (!error.response) {
      return Promise.reject(error);
    }
    if (error.response.status === 422 && error.response.config.url === '/user/password') {
      return error.response;
    }
    if (
      (error.response.status === 401 && error.response.data.message && error.response.data.message.includes('JWT')) ||
      (error.response.status === 401 && error.response.data.error_title && error.response.data.error_title.includes('Unauthorized'))
    ) {
      removeCookie('user_access_token');
      store.dispatch('dialog/setShowErrorDialog', {
        status: true,
        title:i18n.t('langkey.token-expired'),
        message: i18n.t('langkey.token-expired-message'),
      }).then(() => {
        router.replace({ name: 'login' });
      });
    }
    return Promise.reject(error);
  },
);

export {
  SecurityService,
  TyreService,
  TpmsService,
  WheelService,
  AssignService,
  CarService,
  ConfiguratorService,
  CartService,
  UserService,
  RegisterService,
  OrderService,
  DirectDebitService,
  VidikatService,
  DealerCalculationService,
};
