<template>
  <svg
    id="iconInfo"
    data-name="iconInfo"
    xmlns="http://www.w3.org/2000/svg"
    :height="size"
    viewBox="0 0 16 16"
  >
    <path
      id="Path_5341"
      data-name="Path 5341"
      d="M8,0A8,8,0,1,1,0,8,8,8,0,0,1,8,0Z"
      :fill="color"
    />
    <line
      id="Line_80"
      data-name="Line 80"
      x2="0.015"
      transform="translate(7.985 3.699)"
      fill="none"
      stroke="#fff"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
    />
    <path
      id="Path_5254"
      data-name="Path 5254"
      d="M11,12h1.475v5.9H13.95"
      transform="translate(-4.487 -5.033)"
      fill="none"
      stroke="#fff"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
    />
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: '16',
    },
    color: {
      type: String,
      default: '#131523',
    },
  },
};
</script>