<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24.963"
    viewBox="0 0 24 24.963"
  >
    <g
      id="Component_421_1"
      data-name="Component 421 – 1"
      transform="translate(0 0.425)"
    >
      <path
        id="Path_5782"
        data-name="Path 5782"
        d="M0,0H24V24H0Z"
        fill="none"
      />
      <g
        id="Group_5259"
        data-name="Group 5259"
        transform="translate(2.048 0.575)"
      >
        <path
          id="Path_5777"
          data-name="Path 5777"
          d="M0,0H18.672V18.672H0Z"
          transform="translate(0.616 2.894)"
          fill="none"
        />
        <path
          id="Path_5779"
          data-name="Path 5779"
          d="M29.987,64.286l-5.174,3.921a1.654,1.654,0,0,1-1.067.347l-3.761-.133a.835.835,0,0,1-.64-.32.707.707,0,0,1,0-.667.957.957,0,0,1,.747-.427l3.44.107a.962.962,0,0,0,.533-.16l.773-.587a.867.867,0,0,0,.32-.773c-.053-.347-.32-.427-.667-.48l-3.867-.72a4.834,4.834,0,0,0-3.894.933l-4.4,3.334a.878.878,0,0,0-.133,1.28l2.294,2.72a.882.882,0,0,0,1.227.133l.827-.64a.655.655,0,0,1,.4-.133l6.881.133a2.635,2.635,0,0,0,1.894-.72l5.734-5.174a1.293,1.293,0,0,0,.133-1.787A1.191,1.191,0,0,0,29.987,64.286Z"
          transform="translate(-11.979 -50.132)"
          fill="none"
          :stroke="color"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <path
          id="Icon_material-perm-data-setting"
          data-name="Icon material-perm-data-setting"
          d="M31.25,27.444a4.563,4.563,0,0,0,.047-.575,3.085,3.085,0,0,0-.047-.575l1.244-.974a.3.3,0,0,0,.07-.376l-1.174-2.031a.3.3,0,0,0-.364-.129l-1.456.587a4.016,4.016,0,0,0-1-.575l-.223-1.549A.28.28,0,0,0,28.069,21H25.721a.3.3,0,0,0-.293.247L25.205,22.8a4.689,4.689,0,0,0-1,.575l-1.456-.587a.307.307,0,0,0-.364.129l-1.174,2.031a.291.291,0,0,0,.07.376l1.244.974a4.715,4.715,0,0,0,0,1.15l-1.244.974a.3.3,0,0,0-.07.376l1.174,2.031a.3.3,0,0,0,.364.129l1.456-.587a4.016,4.016,0,0,0,1,.575l.223,1.549a.292.292,0,0,0,.293.246h2.348a.3.3,0,0,0,.293-.246l.223-1.549a4.339,4.339,0,0,0,.986-.575l1.467.587a.307.307,0,0,0,.364-.129l1.174-2.031a.3.3,0,0,0-.07-.376ZM26.9,28.63a1.761,1.761,0,1,1,1.761-1.761A1.758,1.758,0,0,1,26.9,28.63Z"
          transform="translate(-16.941 -21)"
          fill="none"
          :stroke="color"
          stroke-linejoin="round"
          stroke-width="2"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    baseColor: {
      type: String,
      default: '#8B8B8B',
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    color() {
      return this.isActive ? '#00CCCC' : this.baseColor;
    },
  },
};
</script>