export const adminMenu = [
  {
    label: 'langkey.overview',
    to: 'admin-overview',
  },
  {
    label: 'langkey.orders',
    to: 'admin-orders',
  },
  // {
  //   label: 'langkey.price-tool',
  //   to: 'admin-price-tool',
  // },
  {
    label: 'langkey.dealer-calculation',
    to: 'admin-dealer-calculation',
  },
  {
    label: 'langkey.offer-tool-settings',
    to: 'admin-offer-tool-settings',
  },
  {
    label: 'langkey.claims',
    to: 'admin-claims',
  },
  {
    label: 'langkey.account-info',
    to: 'admin-account-info',
  },
  {
    label: 'langkey.bank',
    to: 'admin-bank',
  },
  {
    label: 'langkey.general-terms-of-payment-and-delivery',
    to: 'admin-general-terms-of-payment-and-delivery',
  },
];
