import { OrderService } from '@/services';
import { networkStatus } from '@/constants/networkStatus';
import {
  SET_ORDER_ITEMS,
  UPDATE_ORDER_ITEMS,
  SET_ORDER_STATUS,
} from '../mutationTypes';

const state = {
  orderItems: [],
  orderStatus: {},
};

const mutations = {
  [SET_ORDER_ITEMS](stateData, data) {
    stateData.orderItems = [...data];
  },
  [UPDATE_ORDER_ITEMS](stateData, data) {
    stateData.orderItems = [
      ...stateData.orderItems,
      ...data,
    ];
  },
  [SET_ORDER_STATUS](stateData, data) {
    stateData.orderStatus = {...data};
  },
};

const actions = {
  async getOrder({ commit }, payload = {}) {
    const response = await OrderService.getOrder(payload);

    if (response.status === networkStatus['ok']) {
      commit(SET_ORDER_ITEMS, response.data);
    }

    return response;
  },
  async getOrderNextPage({ commit, state: stateData }, payload = {}) {
    const response = await OrderService.getOrder(payload);

    if (response.status === networkStatus['ok']) {
      if (response.data.length === 0) {
        const removeLoadingItems = stateData.orderItems.slice();
        commit(SET_ORDER_ITEMS, removeLoadingItems);
        return Promise.reject('noResult');
      }

      commit(UPDATE_ORDER_ITEMS, response.data);
    }

    return response;
  },
  async getOrderStatus({ commit }) {
    const response = await OrderService.getOrderStatus();

    if (response.status === networkStatus['ok']) {
      commit(SET_ORDER_STATUS, response.data);
    }

    return response;
  },
  async orderMail({ commit }, payload = {}) {
    const response = await OrderService.orderMail(payload);
    return response;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};