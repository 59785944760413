import { AssignService, TpmsService } from '@/services';
import { networkStatus } from '@/constants/networkStatus';
import { getImageBySize } from '@/utils';
import _ from 'lodash';

import {
  SET_TPMS_ASSIGNS,
  SET_TPMS_KITS_DATA,
  SET_TPMS_SEARCH_DATA,
  SET_TPMS_KITS_ONLY_DATA,
  SET_TPMS_KIT_ASSIGNS,
  SET_TPMS_DATA,
} from '../mutationTypes';

const state = {
  tpmsData: [],
  tpmsAssignByTpmsTag: {},
  tpmsKitAssignByMan: {},
  tpmsKitsData: [],
  tpmsSearchData: [],
  tpmsKitsOnlyData: [],
};

const mutations = {
  [SET_TPMS_DATA](stateData, data) {
    stateData.tpmsData = data;
  },
  [SET_TPMS_ASSIGNS](stateData, { tpmsTag, data}) {
    stateData.tpmsAssignByTpmsTag = {
      ...stateData.tpmsAssignByTpmsTag,
      [tpmsTag]: data,
    };
  },
  [SET_TPMS_KIT_ASSIGNS](stateData, { tpmsKitMan, data}) {
    stateData.tpmsKitAssignByMan = {
      ...stateData.tpmsKitAssignByMan,
      [tpmsKitMan]: data,
    };
  },
  [SET_TPMS_KITS_DATA](stateData, data) {
    stateData.tpmsKitsData = data;
  },
  [SET_TPMS_SEARCH_DATA](stateData, data) {
    stateData.tpmsSearchData = data;
  },
  [SET_TPMS_KITS_ONLY_DATA](stateData, data) {
    stateData.tpmsKitsOnlyData = data;
  },
};

const actions = {
  async getTpmsAssigns({ commit }, { tpmsTag, assigns }) {
    const response = await AssignService.getAssigns({ ids: assigns.join() });
    if (response.status === networkStatus['ok']) {
      commit(SET_TPMS_ASSIGNS, {
        tpmsTag: tpmsTag,
        data: response.data,
      });
    }

    return response;
  },
  async getTpmsKitAssigns({ commit }, { tpmsKitMan, assigns }) {
    const response = await AssignService.getAssigns({ ids: assigns.join() });

    if (response.status === networkStatus['ok']) {
      commit(SET_TPMS_KIT_ASSIGNS, {
        tpmsKitMan: tpmsKitMan,
        data: response.data,
      });
    }

    return response;
  },
  async loadTpmsKits({ commit }, payload = {}) {
    const response = await TpmsService.getKits(payload);

    if (response.status === networkStatus['ok']) {
      commit(SET_TPMS_KITS_DATA, response.data);
    }

    return response;
  },
  async loadTpmsKitsOnly({ commit }, payload = {}) {
    const response = await TpmsService.getKitsOnly(payload);

    if (response.status === networkStatus['ok']) {
      commit(SET_TPMS_KITS_ONLY_DATA, response.data);
    }

    return response;
  },
  async loadTpmsData({ commit }, payload = {}) {
    const response = await TpmsService.getSensors(payload);

    if (response.status === networkStatus['ok']) {
      commit(SET_TPMS_DATA, response.data);
    }

    return response;
  },
  clearTpmsCar({ commit }) {
    commit(SET_TPMS_DATA, []);
  },
  async getSensorsByEan({ commit }, payload) {
    const response = await TpmsService.getSensorsByEan(payload);

    if (response.status === networkStatus['ok']) {
      commit(SET_TPMS_SEARCH_DATA, response.data);
    }

    return response;
  },
  async getSensorsByMan({ commit }, payload) {
    const response = await TpmsService.getSensorsByMan(payload);

    if (response.status === networkStatus['ok']) {
      commit(SET_TPMS_SEARCH_DATA, response.data);
    }

    return response;
  },
};

const getters = {
  getTpmsData: (stateData) => {
    return stateData.tpmsData.forEach((element) => {
      return {
        articleId: element.articleId,
        brand: element.manufacturer,
        type: element.brand,
        image: getImageBySize(element.imageTemplate, 100, 30),
        article: `${element.description1} ${element.description2}`,
        bjVon: element.minCarBuildDate,
        bjBis: element.maxCarBuildDate,
        stock: 0,
        price: 0,
        totalPrice: 0,
      };
    });
  },
  getTpmsKitsData: (stateData) => {
    let kitItems = [];
    let result = [];
    stateData.tpmsKitsData.forEach((element) => {
      kitItems = [
        ...kitItems,
        ...element.kits,
      ];
    });
    result = kitItems.reduce((acc,cur) => {
      return acc.find((item) => item.man === cur.man) ?
        acc :
        acc.concat(cur);
    }, []);
    result.sort((a,b) => a.man.localeCompare(b.man));
    return result;
  },
  getTpmsKitsOnlyDataBySupplier: (stateData) => (man) => {
    let result = [];
    const tpmsKitsOnlyDataObj = stateData.tpmsKitsOnlyData[0];
    if (_.isEmpty(stateData.tpmsKitAssignByMan) || !stateData.tpmsKitAssignByMan[man]) {
      return [];
    }
    stateData.tpmsKitAssignByMan[man].forEach((tpmsKitAssign) => {
      result.push({
        ...tpmsKitsOnlyDataObj,
        purchasePrice: tpmsKitAssign.purchasePrice,
        stock: tpmsKitAssign.stock,
        supplier: tpmsKitAssign.supplier,
        assign: tpmsKitAssign.id,
      });
    });
    return result;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};