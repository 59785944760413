<template>
  <svg
    :height="size"
    viewBox="0 0 7 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      x1="0.353553"
      y1="0.646447"
      x2="6.57578"
      y2="6.86867"
      :stroke="color"
    />
    <line
      x1="0.424401"
      y1="6.86868"
      x2="6.64662"
      y2="0.646454"
      :stroke="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: '8',
    },
    color: {
      type: String,
      default: 'var(--theme-black)',
    },
  },
};
</script>