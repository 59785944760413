import VueI18n from 'vue-i18n';
import Vue from 'vue';
import { DEFAULT_LANGUAGE, FALLBACK_LANGUAGE } from '@/constants/trans';

Vue.use(VueI18n);

document.documentElement.lang = DEFAULT_LANGUAGE;

export const i18n = new VueI18n({
  // locale: DEFAULT_LANGUAGE, // disable default locale to force load message by API
  fallbackLocale: FALLBACK_LANGUAGE,
  // Disable missing langkey warning log
  silentTranslationWarn: true,
  // messages: {}
});
