<template>
  <base-rimconfig-card-car-selector
    class="rimconfig-car-search-ean-man"
    :title="$t('langkey.rim-config-search-by')"
  >
    <template #custom-header-content>
      <div class="button-wrapper">
        <button
          :class="['button-menu first', !searchByKbaNumber && !searchByEanMan && 'selected']"
          @click="setSearchByKbaNumber(false)"
        >
          {{ $t('langkey.rim-config-configurator') }}
        </button>
        <button
          :class="['button-menu', searchByKbaNumber && 'selected']"
          @click="setSearchByKbaNumber(true)"
        >
          {{ $t('langkey.rim-config-kba-number') }}
        </button>
        <v-btn
          icon
          plain
          style="position: relative;top: -1px;"
          @click="onClickKbaInfo"
        >
          <icon-info color="red" />
        </v-btn>
        <button
          v-if="isTpms"
          :class="['button-menu', searchByEanMan && 'selected']"
          @click="setSearchByEanMan(true)"
        >
          {{ $t('langkey.tpms-search-ean-man') }}
        </button>
      </div>
    </template>
    <template #body>
      <div class="tpms-selector__container">
        <div class="select-car__section">
          <div class="input-wrapper">
            <input
              v-model="searchTpms"
              type="search"
              class="input__search"
              :placeholder="$t('langkey.tpms-search-man')"
              @keyup.enter="searchTpmsSensors"
            >
            <v-btn
              v-if="searchTpms"
              icon
              plain
              class="button__input-clear"
              @click="clearSearch"
            >
              <icon-close />
            </v-btn>
            <span
              class="icon__search"
              @click="searchTpmsSensors"
            >
              <icon-search color="var(--theme-black)" />
            </span>
          </div>
        </div>
      </div>

      <div class="car-history">
        <v-btn
          depressed
          plain
          block
          class="car-history__button"
        >
          <a
            @click="openCarSearchHistoryDialog"
          >
            {{ $t('langkey.rim-config-car-history') }}
          </a>
        </v-btn>
      </div>
    </template>
  </base-rimconfig-card-car-selector>
</template>

<style lang="scss">
@import '@/styles/components/rimconfig-car-search-ean-man.scss';
</style>

<script>
import IconInfo from '@/components/icons/IconInfo';
import { mapState } from 'vuex';

export default {
  components: {
    IconInfo,
  },
  props: {
    searchByKbaNumber: {
      type: Boolean,
      default: false,
    },
    searchByEanMan: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isTpms: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchTpms: '',
    };
  },
  computed: {
  },
  watch: {
  },
  mounted() {
    
  },
  methods: {
    clearSearch() {
      this.searchTpms = '';
    },
    setSearchByKbaNumber(status) {
      this.$emit('set-search-by-kba-number', status);
    },
    setSearchByEanMan(status) {
      this.$emit('set-search-by-ean-man', status);
    },
    onClickKbaInfo() {
      this.$emit('on-click-kba-info');
    },
    openCarSearchHistoryDialog() {
      this.$emit('open-car-search-history-dialog');
    },
    async searchTpmsSensors() {
      let type = 'man';
      if (this.searchTpms.length === 13) {
        type = 'ean';
      }
      this.$router.push(this.$i18nRoute({
        name: 'rims-config-tpms-search',
        query: {
          type: type,
          value: this.searchTpms,
        },
      }));
    },
  },
};
</script>
