import {
  SET_PRICE_TYPE,
  SET_OPT_IN,
} from '../mutationTypes';

const state = {
  priceType: '',
  isOptIn: false,
};

const mutations = {
  [SET_PRICE_TYPE](stateData, data){
    stateData.priceType = data;
  },
  [SET_OPT_IN](stateData, data) {
    stateData.isOptIn = data;
  },
};

const actions = {
  async setPriceType({ commit }, priceType) {
    if (priceType) {
      commit(SET_PRICE_TYPE, priceType);
    } else {
      commit(SET_PRICE_TYPE, 'purchasePrice');
    }
  },
  async setOptIn({ commit }, isOptIn) {
    commit(SET_OPT_IN, isOptIn);
  },
};

const getters = {
  async getpriceType(stateData) {
    return stateData.priceType;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
