<template>
  <div class="base-rimconfig-top-brand">
    <div class="custom-container">
      <div class="title__wrapper">
        <h5>{{ $t('langkey.riim-config-our-brand') }}</h5>

        <!-- <div
          v-click-outside="hideSearchBar"
          class="search-wrapper"
        >
          <v-btn
            v-if="!showSearchBar"
            icon
            plain
            class="search__icon"
            @click="onShowSearch"
          >
            <icon-search size="18" />
          </v-btn>
          <v-form
            v-else
            @submit.prevent="onSearch"
          >
            <v-text-field
              ref="topbrandSearchRef"
              v-model="inputSearch"
              :hide-details="true"
              placeholder="Search"
              class="search-input"
              solo
            >
              <template #append>
                <button
                  style="height: 18px;"
                  @submit.prevent="onSearch"
                >
                  <icon-search size="18" />
                </button>
              </template>
            </v-text-field>
          </v-form>
        </div> -->
      </div>

      <div class="top-brands__container">
        <transition name="page">
          <v-progress-circular
            v-if="isLoading"
            indeterminate
            color="accent"
          />
          <div
            v-else-if="showFull"
            class="top-brands__full"
          >
            <v-card
              rounded="lg"
              class="top-brands__card"
            >
              <div
                v-for="(row, index) in chunkManufacturers"
                :key="index"
                class="manufacturer-row"
              >
                <div
                  v-for="(item, itemIndex) in row"
                  :key="itemIndex"
                  class="top-brand__item"
                >
                  <v-btn
                    plain
                    @click="onSelectBrand(item)"
                  >
                    <img
                      v-lazy="getBrandImage(item.urlTemplate)"
                      class="brand-image"
                    >
                  </v-btn>
                </div>
              </div>
            </v-card>
            <div class="function">
              <v-btn
                outlined
                plain
                :class="[
                  'see-all-btn',
                  isShowAllBrands ? 'show-all' : null
                ]"
                @click="onClickShowAllBrands"
              >
                {{ $t('langkey.rim-config-see-all-brand') }} <icon-caret-double />
              </v-btn>
            </div>
          </div>
          <div
            v-else
            class="top-brands__wrapper"
          >
            <div
              v-for="i in 9"
              :key="`${Object.values(wheelManufacturers)[i - 1].name}${i}`"
              class="top-brand__item"
            >
              <v-btn
                plain
                @click="onSelectBrand(Object.values(wheelManufacturers)[i - 1])"
              >
                <img
                  v-lazy="getBrandImage(Object.values(wheelManufacturers)[i - 1].urlTemplate)"
                  class="brand-image"
                >
              </v-btn>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/styles/components/base-rimconfig-top-brand.scss";
</style>

<script>
import IconCaretDouble from '@/components/icons/IconCaretDouble';
import { getImageBySize } from '@/utils';
import { mapState } from 'vuex';
import _ from 'lodash';

export default {
  components: {
    IconCaretDouble,
  },
  props: {
    showFull: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // inputSearch: '',
      // showSearchBar: false,
      partnerImages: [],
      isLoading: false,
      isShowAllBrands: false,
    };
  },
  computed: {
    ...mapState('rimconfigBase', ['wheelManufacturers']),
    chunkManufacturers() {
      if (_.isEmpty(this.wheelManufacturers)) {
        return [];
      }

      if (this.isShowAllBrands) {
        return _.chunk(Object.values(this.wheelManufacturers), 5);
      }
      return _.chunk(Object.values(this.wheelManufacturers).slice(0, 10), 5);
    },
  },
  created() {
    if (this.wheelManufacturers.length > 0) {
      return;
    }
    this.isLoading = true;
    this.$store.dispatch('rimconfigBase/getWheelManufacturers')
      .finally(() => {
        this.isLoading = false;
      });
  },
  methods: {
    onSelectBrand(item) {
      this.$emit('select-brand', item);
    },
    // onSearch() {
    //   this.hideSearchBar();
    // },
    // onShowSearch() {
    //   this.showSearchBar = true;
    //   this.$nextTick(() => {
    //     this.$refs.topbrandSearchRef.focus();
    //   });
    // },
    // hideSearchBar() {
    //   this.showSearchBar = false;
    // },
    getBrandImage(image) {
      return getImageBySize(image, 200, 80);
    },
    onClickShowAllBrands() {
      this.isShowAllBrands = !this.isShowAllBrands;
    },
  },
};
</script>