export const tableFieldAddToCart = [
  { value: 'image', text: 'langkey.image', width: '200px', align: 'center' },
  { value: 'description', text: 'langkey.article', width: '300px', align: 'center' },
  { value: 'articleId', text: 'langkey.article-id', width: '135px', align: 'center' },
  // { value: 'quantity', text: 'langkey.quantity', width: '180px', align: 'center' },
  // { value: 'total', text: 'langkey.total-price', width: '110px', align: 'center' },
  { value: 'data-table-expand' },
];

export const tableFieldAddToCartSuppliers = [
  { text: 'langkey.supplier', value: 'supplier', width: `${(4/18) * 100}%`, sortable: false },
  { text: 'langkey.stock', value: 'stock', width: `${(2/18) * 100}%`, sortable: false },
  // { text: 'langkey.list-price', value: 'listPrice', width: `${(2/18) * 100}%` },
  // { text: 'langkey.discount', value: 'discount', width: `${(2/18) * 100}%` },
  { text: 'langkey.price', value: 'purchasePrice', width: `${(2/18) * 100}%`, sortable: false },
  { text: 'langkey.quantity', align: 'center', value: 'qty', width: `${(4/18) * 100}%`, sortable: false },
  { text: 'langkey.total-price', align: 'center', value: 'total', width: `${(1/18) * 100}%`, sortable: false },
  { text: '', align: 'right', value: 'order', width: `${(1/18) * 100}%`, sortable: false },
];


export const tableFieldAddToCartSuppliersRetailPrice = [
  { text: 'langkey.supplier', value: 'supplier', width: `${(4/18) * 100}%`, sortable: false },
  { text: 'langkey.stock', value: 'stock', width: `${(2/18) * 100}%`, sortable: false },
  // { text: 'langkey.list-price', value: 'listPrice', width: `${(2/18) * 100}%` },
  // { text: 'langkey.discount', value: 'discount', width: `${(2/18) * 100}%` },
  { text: 'langkey.netto', value: 'retailPrice', width: `${(2/18) * 100}%`, sortable: false },
  { text: 'langkey.brutto', value: 'retailTaxPrice', width: `${(2/18) * 100}%`, sortable: false },
  { text: 'langkey.quantity', align: 'center', value: 'qty', width: `${(4/18) * 100}%`, sortable: false },
  { text: 'langkey.total_price_including_vat', align: 'center', value: 'total', width: `${(1/18) * 100}%`, sortable: false },
  { text: '', align: 'right', value: 'order', width: `${(1/18) * 100}%`, sortable: false },
];