import { UserService } from '@/services';
import {
  OPEN_ERROR_DIALOG,
  CLOSE_ERROR_DIALOG,
  OPEN_LOADING_DIALOG,
  CLOSE_LOADING_DIALOG,
  SET_SHOW_INFO_DIALOG,
} from '../mutationTypes';

const state = {
  showError: false,
  errorTitle: '',
  errorMessage: '',
  errorMessageElement: null,
  errorMessageElementAction: null,
  showLoading: false,
  showInfoDialog: false,
};

const mutations = {
  [OPEN_ERROR_DIALOG](stateData, { title, message, messageElement, messageAction }) {
    stateData.showError = true;
    stateData.errorTitle = title;
    stateData.errorMessage = message;
    stateData.errorMessageElement = messageElement;
    stateData.errorMessageElementAction = messageAction;
  },
  [CLOSE_ERROR_DIALOG](stateData) {
    stateData.showError = false;
    stateData.errorTitle = '';
    stateData.errorMessage = '';
    stateData.errorMessageElement = null;
    stateData.errorMessageElementAction = null;
  },
  [OPEN_LOADING_DIALOG](stateData) {
    stateData.showLoading = true;
  },
  [CLOSE_LOADING_DIALOG](stateData) {
    stateData.showLoading = false;
  },
  [SET_SHOW_INFO_DIALOG](stateData, data) {
    stateData.showInfoDialog = data;
  },
};

const actions = {
  async setShowErrorDialog({ commit }, { status, title, message, messageElement, messageAction }) {
    if (status) {
      commit(OPEN_ERROR_DIALOG, { title, message, messageElement, messageAction });
    } else {
      commit(CLOSE_ERROR_DIALOG);
    }
  },
  async setLoadingDialog({ commit }, { status }) {
    if (status) {
      commit(OPEN_LOADING_DIALOG);
    } else {
      commit(CLOSE_LOADING_DIALOG);
    }
  },
  async getUserLoginInformation({ commit }) {
    const response  = await UserService.getUserLoginInformation();
    if (response.status === 200 || response.status === 204) {
      // display
      sessionStorage.setItem('showInfoDialog', true);
      commit(SET_SHOW_INFO_DIALOG, true);
    } else {
      // not display
      sessionStorage.setItem('showInfoDialog', false);
      commit(SET_SHOW_INFO_DIALOG, false);
    }
    return response;
  },
  setUserLoginInformation({ commit }, { status, noRequest = false }) {
    if (!noRequest) {
      UserService.setUserLoginInformation(status);
    }
    sessionStorage.setItem('showInfoDialog', status);
    commit(SET_SHOW_INFO_DIALOG, status);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};