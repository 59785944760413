<template>
  <v-menu
    v-model="isShowDropdown"
    :nudge-top="37"
    :close-on-content-click="false"
    offset-y
    class="base-select-filter-dropdown"
    :content-class="customContentClass
      ? `base-select-filter-dropdown-content ${customContentClass}`
      : 'base-select-filter-dropdown-content'"
    :max-width="isMaxWidth ? 190 : 'auto'"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        :class="[
          'base-select-filter-dropdown-trigger',
          isShowDropdown && 'is-show',
          dropdownClass,
        ]"
        :disabled="disabled"
        depressed
        plain
        v-bind="attrs"
        v-on="on"
      >
        <span
          v-if="value.length === 0"
          class="label-text"
        >
          {{ $t(placeholder) }}
        </span>
        <span
          v-else-if="isUseLabelInSelectedValue"
          class="label-text"
        >
          <template v-if="filterKey === 'minStock'">
            <template v-if="getLabelFromSelectValue">{{ getLabelFromSelectValue }}</template>
            <template v-else>{{ value[0] }}</template>
          </template>
          <template v-else>
            {{ getLabelFromSelectValue }}
          </template>
        </span>
        <span
          v-else-if="value.length === 1"
          class="label-text"
        >
          {{ value[0] !== ' ' ? value[0] : '-' }}
        </span>
        <span
          v-else
          class="label-text"
        >
          {{ value[0] }} + {{ value.length - 1 }}
        </span>
        <icon-caret
          color="var(--theme-black)"
          class="icon-caret"
        />
      </v-btn>
    </template>
    <v-card>
      <div style="position:absolute;width:100%;height:100%;top:0;left:-5px;">
        <v-overlay
          v-if="isLoading"
          :z-index="20"
          :opacity="0.7"
          absolute
          color="#fff"
        >
          <v-progress-circular
            indeterminate
            color="accent"
          />
        </v-overlay>
        <!-- <v-overlay
          v-if="isLoading"
          :opacity="0.7"
          absolute
          color="#fff"
        /> -->
      </div>
      <div class="selected-section">
        <span
          v-if="value.length === 0"
          class="selected-text"
        >
          {{ placeholder }}
        </span>
        <span
          v-else-if="isUseLabelInSelectedValue"
          class="selected-text"
        >
          <template v-if="filterKey === 'minStock'">
            <template v-if="getLabelFromSelectValue">{{ getLabelFromSelectValue }}</template>
            <template v-else>{{ value[0] }}</template>
          </template>
          <template v-else>
            {{ getLabelFromSelectValue }}
          </template>
        </span>
        <span
          v-else-if="value.length === 1"
          class="label-text"
        >
          {{ value[0] }}
        </span>
        <span
          v-else
          class="selected-text"
        >
          {{ value[0] }} + {{ value.length - 1 }}
        </span>
        <button
          class="close-button"
          @click="hideDropdown"
        >
          <icon-close size="10" />
        </button>
      </div>
      <div class="content-section">
        <form @submit.prevent="onSearchSubmit">
          <v-text-field
            v-model="searchFilter"
            :hide-details="true"
            class="search-input"
            :placeholder="`${$t('langkey.search')}`"
            solo
          >
            <template #append>
              <button @click.prevent="onSearchSubmit">
                <icon-search />
              </button>
            </template>
          </v-text-field>
        </form>
        <div class="clear-section">
          <v-btn
            v-if="!isRadio"
            class="clear-button"
            outlined
            small
            @click="onClearSelectFilter"
          >
            <span class="mr-1">
              {{ $t('langkey.filter-clear') }}
            </span>
            <icon-clear-filter />
          </v-btn>
        </div>
        <div class="option-section">
          <v-list
            flat
          >
            <template v-for="(dropdownItem, index) in sortedSelectFilterDropdownItems">
              <v-list-item
                v-show="isItemVisible($t(dropdownItem.label)) || isSearchSubmit"
                :key="`${dropdownItem.label}${index}`"
              >
                <template #default>
                  <base-checkbox
                    :value="dropdownItem.isSelected"
                    :label-text="$t(dropdownItem.label)"
                    :disabled="isRadio && dropdownItem.isSelected"
                    @input="onSelectItem(dropdownItem)"
                  />
                </template>
              </v-list-item>
            </template>
          </v-list>
        </div>
      </div>
    </v-card>
  </v-menu>
</template>

<style lang="scss">
@import "@/styles/components/base-select-filter-dropdown.scss";
</style>

<script>
import IconCaret from '@/components/icons/IconCaret';
import IconClearFilter from '@/components/icons/IconClearFilter';

export default {
  components: {
    IconCaret,
    IconClearFilter,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    selectLabel: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    optionItems: {
      type: Array,
      default: () => [],
    },
    isObjValue: {
      type: Boolean,
      default: false,
    },
    objValueKey: {
      type: String,
      default: '',
    },
    single: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dropdownClass: {
      type: String,
      default: '',
    },
    customContentClass: {
      type: String,
      default: '',
    },
    sortable: {
      type: Boolean,
      default: false,
    },
    sortFunction: {
      type: Function,
      default: null,
    },
    isUseLabelInSelectedValue: {
      type: Boolean,
      default: false,
    },
    isSearchSubmit: {
      type: Boolean,
      default: false,
    },
    filterKey: {
      type: String,
      default: '',
    },
    isMaxWidth: {
      type: Boolean,
      default: true,
    },
    isSearchDeliveryAddress: {
      type: Boolean,
      default: false,
    },
    closeOnSelect: {
      type: Boolean,
      default: false,
    },
    isRadio: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isShowDropdown: false,
      searchFilter: '',
      selectFilterDropdownItems: [],
    };
  },
  computed: {
    hasSelectedItem() {
      return this.value.length > 0;
    },
    getSelectedItems() {
      return this.selectFilterDropdownItems.slice()
        .filter((item) => item.isSelected);
    },
    getSelectedItemValues() {
      return this.getSelectedItems
        .map((item) => item.value);
    },
    sortedSelectFilterDropdownItems() {
      if (this.selectFilterDropdownItems.length === 0) {
        return [];
      }

      if (this.sortable && this.sortFunction !== null) {
        return this.selectFilterDropdownItems.slice()
          .sort(this.sortFunction);
      }

      if (this.sortable) {
        return this.selectFilterDropdownItems.slice()
          .sort((a, b) => a.label.toString().localeCompare(b.label.toString()));
      }

      return this.selectFilterDropdownItems;
    },
    getLabelFromSelectValue() {
      if (this.getSelectedItems.length === 0) {
        return '';
      }
      const getSelectedValuesLabel = this.getSelectedItems.map((item) => this.$t(item.label));
      return getSelectedValuesLabel.join();
    },
  },
  watch: {
    isShowDropdown(val) {
      if (val) {
        this.initSelectFilterDropdownItems();
      } else {
        this.searchFilter = '';
      }
    },
    optionItems() {
      if (this.isShowDropdown && this.value.length !== 0) {
        return;
      }
      this.initSelectFilterDropdownItems();
    },
  },
  created() {
    this.initSelectFilterDropdownItems();
  },
  methods: {
    handleSelectOnSearch() {
      if (this.searchFilter === '') {
        return;
      }
      this.searchFilter = '';
    },
    isItemVisible(label) {
      const labelString = `${label}`;
      const currentLabel = labelString.toLowerCase();
      const currentInput = this.searchFilter.toLowerCase();

      if (this.isSearchDeliveryAddress) {
        const words = currentInput.split(' ');
        const result = words.every((word) => currentLabel.includes(word));
        return result;
      }
      return currentLabel.includes(currentInput);
    },
    onSelectItem(item) {
      if (this.closeOnSelect) {
        this.hideDropdown();
      }

      if (this.isRadio) {
        if (!item.isSelected) {
          const selectState = !item.isSelected;
          item.isSelected = selectState;
          this.handleSelectOnSearch();

          this.selectFilterDropdownItems = this.selectFilterDropdownItems.slice()
            .map((optItem) => {
              if (item.id === optItem.id) {
                return {
                  ...optItem,
                  isSelected: selectState,
                };
              } else {
                return {
                  ...optItem,
                  isSelected: false,
                };
              }
            });
          this.$emit('input', this.getSelectedItemValues);
          return;
        }
      }

      const selectState = !item.isSelected;
      item.isSelected = selectState;
      this.handleSelectOnSearch();

      if (this.single) {
        this.selectFilterDropdownItems = this.selectFilterDropdownItems.slice()
          .map((optItem) => {
            if (item.id === optItem.id) {
              return {
                ...optItem,
                isSelected: selectState,
              };
            } else {
              return {
                ...optItem,
                isSelected: false,
              };
            }
          });
        this.$emit('input', this.getSelectedItemValues);
        return;
      }

      this.selectFilterDropdownItems = this.selectFilterDropdownItems.slice()
        .map((optItem) => {
          if (item.id === optItem.id) {
            return {
              ...optItem,
              isSelected: selectState,
            };
          } else {
            return {
              ...optItem,
            };
          }
        });

      this.$emit('input', this.getSelectedItemValues);

      // if (this.isObjValue) {
      //   this.handleOnSelectObjValue(item, selectState);
      //   return;
      // }

      // this.handleOnSelect(item, selectState);
    },
    onClearSelectFilter() {
      this.searchFilter = '';
      this.$emit('input', []);
      if (this.isObjValue) {
        this.selectFilterDropdownItems = this.optionItems.slice()
          .map((item, index) => {
            return {
              id: `${this.selectLabel}${item[this.objValueKey]}${index}`,
              label: item.label,
              value: item.value,
              isSelected: false,
            };
          });
        return;
      }
      this.selectFilterDropdownItems = this.optionItems.slice()
        .map((item, index) => {
          return {
            id: `${this.selectLabel}${item.label}${index}`,
            label: item.label,
            value: item.value,
            isSelected: false,
          };
        });
    },
    initSelectFilterDropdownItems() {
      if (this.optionItems.length === 0) {
        return;
      }

      if (this.isObjValue) {
        this.setInitSelectFilterDropdownItemsObjValue();
        return;
      }

      this.setInitSelectFilterDropdownItems();
    },
    setInitSelectFilterDropdownItems() {
      this.selectFilterDropdownItems = this.optionItems.slice()
        .map((item, index) => {
          if (this.value.length === 0) {
            return {
              id: `${this.selectLabel}${item.label}${index}`,
              label: item.label,
              value: item.value,
              isSelected: false,
            };
          } else {
            return {
              id: `${this.selectLabel}${item.label}${index}`,
              label: item.label,
              value: item.value,
              isSelected: this.value.includes(item.value),
            };
          }
        });
    },
    setInitSelectFilterDropdownItemsObjValue() {
      this.selectFilterDropdownItems = this.optionItems.slice()
        .map((item, index) => {
          if (this.value.length === 0) {
            return {
              id: `${this.selectLabel}${item[this.objValueKey]}${index}`,
              label: item.label,
              value: item.value,
              isSelected: false,
            };
          } else {
            const foundedItem = this.value.find((valueItem) => valueItem[this.objValueKey] === item[this.objValueKey]);
            return {
              id: `${this.selectLabel}${item[this.objValueKey]}${index}`,
              label: item.label,
              value: item.value,
              isSelected: foundedItem !== undefined,
            };
          }
        });
    },
    hideDropdown() {
      this.isShowDropdown = false;
    },
    onSearchSubmit() {
      const getSelectItem = this.selectFilterDropdownItems.find((item) => this.searchFilter.toString() === item.value.toString());
      // if search value doesn't in the dropdown item
      if (!getSelectItem) {
        if (this.single) {
          this.selectFilterDropdownItems = this.selectFilterDropdownItems.slice()
            .map((optItem) => {
              return {
                ...optItem,
                isSelected: false,
              };
            });
          this.$emit('input', [this.searchFilter]);
        }
        return;
      }

      const selectState = !getSelectItem.isSelected;
      getSelectItem.isSelected = selectState;
      this.handleSelectOnSearch();

      if (this.single || this.isRadio) {
        this.selectFilterDropdownItems = this.selectFilterDropdownItems.slice()
          .map((optItem) => {
            if (getSelectItem.id === optItem.id) {
              return {
                ...optItem,
                isSelected: selectState,
              };
            } else {
              return {
                ...optItem,
                isSelected: false,
              };
            }
          });
        this.$emit('input', this.getSelectedItemValues);
        return;
      }

      this.selectFilterDropdownItems = this.selectFilterDropdownItems.slice()
        .map((optItem) => {
          if (getSelectItem.id === optItem.id) {
            return {
              ...optItem,
              isSelected: selectState,
            };
          } else {
            return {
              ...optItem,
            };
          }
        });

      this.$emit('input', this.getSelectedItemValues);
    },
    // handleOnSelect(item, selectState) {
    //   if (selectState) {
    //     // this.selectedItems.push(item);
    //     this.$emit('input', this.getSelectedItemValues);
    //     return;
    //   }

    //   // const index = this.selectedItems.findIndex((selectItem) => selectItem.value === item.value);
    //   // this.selectedItems.splice(index, 1);
    //   this.$emit('input', this.getSelectedItemValues);
    // },
    // handleOnSelectObjValue(item, selectState) {
    //   if (selectState) {
    //     // this.selectedItems.push(item);
    //     this.$emit('input', this.getSelectedItemValues);
    //     return;
    //   }

    //   // const index = this.selectedItems.findIndex((selectItem) => selectItem.value === item.value);
    //   // this.selectedItems.splice(index, 1);
    //   this.$emit('input', this.getSelectedItemValues);
    // },
  },
};
</script>