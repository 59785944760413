<template>
  <div class="o-wrapper">
    <div class="error-container">
      <div>
        <div
          v-if="msg !== 'wheels-not-found'"
          class="error-number"
        >
          404
        </div>
        <h1 :style="style">
          {{ $t(`langkey.${msg}`) }}
        </h1>
        <p
          v-if="msg !== 'wheels-not-found'"
          v-html="$t('langkey.404-description')"
        />
        <p v-if="msg === 'wheels-not-found'">
          {{ $t('langkey.please-try-other-parameters') }}
        </p>
        <router-link
          :to="$i18nRoute({ name: 'tyre' })"
          class="o-btn o-btn--primary"
          :style="{ marginRight: `10px` }"
          rel="nofollow"
        >
          Home
        </router-link>
        <a
          class="o-btn o-btn--basic"
          rel="nofollow"
          @click.prevent="goBack"
        >
          {{ $t('langkey.back') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  computed: {
    ...mapState({
      isProlineShop: (state) => state.isProlineShop,
    }),
    msg() {
      if (this.$route.query.msg) {
        return this.$route.query.msg;
      }
      return 'Page not found';
    },
    style() {
      if (this.$route.query.msg) {
        return {
          textTransform: 'uppercase',
        };
      }
      return {};
    },
  },
  methods: {
    goBack() {
      if (this.$route.query.redirect) {
        return this.$router.push(
          this.$i18nRoute({
            name: this.$route.query.redirect,
          }),
        );
      }
      return this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss">
.error-number {
  font-size: 100px;
  font-weight: 900;
  color: rgba($color: #000000, $alpha: 0.1);
}

.error-container {
  padding: 40px 0;
  height: 50vh;
  display: flex;
  align-items: center;
}
</style>