import { apiClient } from '@/services';

export const ConfiguratorService = {
  getWheelCertificates(payload) {
    return apiClient.get('/configurator/wheels-certificates', {
      params: payload,
    });
  },
  getKitMatches(payload) {
    return apiClient.get('/configurator/kit-matches', {
      params: payload,
    });
  },
};