import Vue from 'vue';
import Router from 'vue-router';
import {SUPPORTED_LANGUAGES} from '@/constants/trans';
import {translation} from '@/plugins/translation';
import {getCookie, setCookie} from '@/utils/js-cookie';

import NotFound from '@/pages/404.vue';
import store from '@/store';

Vue.use(Router);

const routes = [
  {
    path: '/:lang',
    component: {
      template: '<router-view></router-view>',
    },
    beforeEnter: translation.routeMiddleware,
    children: [
      {
        path: '',
        redirect: {
          name: 'tyre',
        },
      },
      {
        path: 'login',
        name: 'login',
        component: () => import(/* webpackChunkName: "landing" */ '@/pages/landing'),
        meta: {
          layout: 'landing',
        },
        beforeEnter(to, from, next) {
          const token = getCookie('user_access_token');
          if (token) {
            next('tyre');
          } else {
            next();
          }
        },
      },
      {
        path: 'register',
        name: 'register',
        component: () => import(/* webpackChunkName: "register" */ '@/pages/register'),
        meta: {
          layout: 'landing',
          title: 'Registrieren',
        },
        beforeEnter(to, from, next) {
          const token = getCookie('user_access_token');
          if (token) {
            next('tyre');
          } else {
            next();
          }
        },
      },
      {
        path: 'register-full',
        name: 'register-full',
        component: () => import(/* webpackChunkName: "register" */ '@/pages/register'),
        meta: {
          layout: 'main',
          requiresAuth: true,
        },
      },
      {
        path: 'reset-password/:token/:secret',
        name: 'reset-password',
        component: () => import(/* webpackChunkName: "reset-password" */ '@/pages/reset-password'),
        meta: {
          layout: 'landing',
        },
      },
      {
        path: 'tyre',
        name: 'tyre',
        component: () => import(/* webpackChunkName: "tyre" */ '@/pages/tyre'),
        meta: {
          layout: 'main',
          requiresAuth: true,
        },
      },
      {
        path: 'tyre/search',
        name: 'tyre-search',
        component: () => import(/* webpackChunkName: "tyre-search" */ '@/pages/tyre/search'),
        meta: {
          layout: 'main',
          requiresAuth: true,
        },
      },
      {
        path: 'tyre/car',
        name: 'tyre-car',
        component: () => import(/* webpackChunkName: "tyre-car" */ '@/pages/tyre/car'),
        meta: {
          layout: 'main',
          requiresAuth: true,
          vehicleType: 'car',
        },
      },
      {
        path: 'tyre/truck',
        name: 'tyre-truck',
        component: () => import(/* webpackChunkName: "tyre-truck" */ '@/pages/tyre/truck'),
        meta: {
          layout: 'main',
          requiresAuth: true,
          vehicleType: 'truck',
        },
      },
      {
        path: 'tyre/industrial',
        name: 'tyre-industrial',
        component: () => import(/* webpackChunkName: "tyre-industrial" */ '@/pages/tyre/industrial'),
        meta: {
          layout: 'main',
          requiresAuth: true,
          vehicleType: 'industrial',
        },
      },
      {
        path: 'tyre/agriculture',
        name: 'tyre-agriculture',
        component: () => import(/* webpackChunkName: "tyre-agriculture" */ '@/pages/tyre/agriculture'),
        meta: {
          layout: 'main',
          requiresAuth: true,
          vehicleType: 'agriculture',
        },
      },
      {
        path: 'tyre/earthMover',
        name: 'tyre-earthMover',
        component: () => import(/* webpackChunkName: "tyre-earthMover" */ '@/pages/tyre/earthMover'),
        meta: {
          layout: 'main',
          requiresAuth: true,
          vehicleType: 'earthMover',
        },
      },
      {
        path: 'tyre/motorbike',
        name: 'tyre-motorbike',
        component: () => import(/* webpackChunkName: "tyre-motorbike" */ '@/pages/tyre/motorbike'),
        meta: {
          layout: 'main',
          requiresAuth: true,
          vehicleType: 'bike',
        },
      },
      {
        path: 'tyre/offer/:eans',
        name: 'tyre-offer',
        component: () => import(/* webpackChunkName: "tyre-offer" */ '@/pages/tyre/offer'),
        meta: {
          layout: 'main',
          requiresAuth: true,
        },
      },
      {
        path: 'rims-config',
        name: 'rims-config',
        redirect: {
          name: 'rims-config-configurator',
        },
      },
      {
        path: 'rims-config/configurator',
        name: 'rims-config-configurator',
        component: () => import(/* webpackChunkName: "rims-config-configurator" */ '@/pages/rims-config/configurator'),
        meta: {
          layout: 'rims-config',
          requiresAuth: true,
        },
      },
      {
        path: 'rims-config/configurator/:carTag',
        name: 'rims-config-configurator_carTag',
        component: () => import(/* webpackChunkName: "rims-config-configurator_carTag" */ '@/pages/rims-config/configurator/_carTag'),
        meta: {
          layout: 'rims-config',
          requiresAuth: true,
        },
      },
      {
        path: 'rims-config/configurator/:hsnTsnVsn',
        name: 'rims-config-configurator_hsnTsnVsn',
        component: () => import(/* webpackChunkName: "rims-config-configurator_carTag" */ '@/pages/rims-config/configurator/_carTag'),
        meta: {
          layout: 'rims-config',
          requiresAuth: true,
        },
      },
      {
        path: 'rims-config/configurator/:carTag/tpms/:selectedWheel',
        name: 'rims-config-configurator_carTag-tpms',
        component: () => import(/* webpackChunkName: "rims-config-configurator_carTag-tpms" */ '@/pages/rims-config/configurator/_carTag/tpms'),
        meta: {
          layout: 'rims-config',
          requiresAuth: true,
        },
        async beforeEnter (to, from, next) {
          store.dispatch('dialog/setLoadingDialog', {
            status: true,
          });

          try {
            const carTag = to.params.carTag;
            const { quantity, isCombination, manufacturer, design, color } = to.query;
            const wheelIds = to.query.wheel.split('+');

            //get car info
            await store.dispatch('rimconfigCarConfigurator/loadCarByCarTag', {
              carTag: carTag,
            });

            //get wheel list
            await store.dispatch('rimconfigCarConfigurator/getCarWheels', {
              carTag: carTag,
              wheelManufacturer: manufacturer,
              wheelDesigns: design,
              colors: color,
            });
            let selectedWheels = {
              assignIds: [...wheelIds],
              isCombination: isCombination === 'true' ? true : false,
              quantity: quantity,
              cartItems: [],
            };
            // Car wheels with only axisOnly = 'B'
            const carWheelsWithAxisBOnly = store.state.rimconfigCarConfigurator.carWheels
              .filter((item) => {
                return item.wheels.some((wheel) => wheel.axisOnly === 'B');
              })
              .map((item) => {
                return {
                  ...item,
                  wheels: item.wheels.filter((wheel) => wheel.axisOnly === 'B'),
                };
              });

            for (let wheel of carWheelsWithAxisBOnly[0].wheels) {
              const foundedWheelId = wheel.assigns.find((assignId) => assignId.toString().includes(wheelIds[0]));
              if (foundedWheelId) {
                await store.dispatch('rimconfigCarConfigurator/getCarWheelAssigns', {
                  wheelTag: wheel.wheelTag,
                  assigns: wheel.assigns,
                });
                const supplier = store.state.rimconfigCarConfigurator.carWheelAssignByWheelTag[wheel.wheelTag].slice()
                  .map((assignItem) => {
                    return {
                      ...assignItem,
                    };
                  })
                  .find((element) => {
                    return element.id === foundedWheelId;
                  });
                const selectedWheel = {
                  ...wheel,
                  stock: supplier.stock,
                };
                selectedWheels.cartItems = [...selectedWheels.cartItems, selectedWheel];
              }
            }
            await store.dispatch('rimconfigCarConfigurator/selectConfiguratorWheel', selectedWheels);

            //get tpms data
            // const selectedWheel = to.params.selectedWheel; //comment out for now as it is error below
            // let selectedWheelArticleNumber;
            // if (selectedWheel.includes('+')) {
            //   const wheelCombinations = selectedWheel.split('+');
            // }
            if (store.state.rimconfigCarConfigurator.tpmsData.length === 0) {
              await store.dispatch('rimconfigCarConfigurator/loadTpmsCar', {
                carTag: carTag,
                // wheelArticleNumber: selectedWheelArticleNumber, //error with wheel article number
              });
            }
          } catch (err) {
            console.log(err);
          }

          store.dispatch('dialog/setLoadingDialog', {
            status: false,
          });
          next();
        },
      },
      {
        path: 'rims-config/configurator/:carTag/tyre/:selectedWheel',
        name: 'rims-config-configurator_carTag-tyre',
        component: () => import(/* webpackChunkName: "rims-config-configurator_carTag-tyre" */ '@/pages/rims-config/configurator/_carTag/tyre'),
        meta: {
          layout: 'rims-config',
          requiresAuth: true,
        },
        async beforeEnter (to, from, next) {
          store.dispatch('dialog/setLoadingDialog', {
            status: true,
          });

          try {
            const carTag = to.params.carTag;
            const { quantity, isCombination, manufacturer, design, color, tpms, tpmsQuantity } = to.query;
            let wheelIds = to.query.wheel.split('+');

            // Get car info
            await store.dispatch('rimconfigCarConfigurator/loadCarByCarTag', {
              carTag: carTag,
            });

            // Get wheel list with tyres
            await store.dispatch('rimconfigCarConfigurator/getCarWheels', {
              carTag: carTag,
              wheelManufacturer: manufacturer,
              wheelDesigns: design,
              colors: color,
              tyres: true,
            });
            let selectedWheels = {
              assignIds: [...wheelIds],
              isCombination: isCombination === 'true' ? true : false,
              quantity: quantity,
              cartItems: [],
            };
            // Car wheels with only axisOnly = 'B'
            const carWheelsWithAxisBOnly = store.state.rimconfigCarConfigurator.carWheels
              .filter((item) => {
                return item.wheels.some((wheel) => wheel.axisOnly === 'B');
              })
              .map((filteredItem) => {
                return {
                  ...filteredItem,
                  wheels: filteredItem.wheels.filter((wheel) => wheel.axisOnly === 'B'),
                };
              });

            for (let wheel of carWheelsWithAxisBOnly[0].wheels) {
              const foundedWheelId = wheel.assigns.find((assignId) => assignId.toString().includes(wheelIds[0]));
              if (foundedWheelId) {
                await store.dispatch('rimconfigCarConfigurator/getCarWheelAssigns', {
                  wheelTag: wheel.wheelTag,
                  assigns: wheel.assigns,
                });
                const supplier = store.state.rimconfigCarConfigurator.carWheelAssignByWheelTag[wheel.wheelTag].slice()
                  .map((assignItem) => {
                    return {
                      ...assignItem,
                    };
                  })
                  .find((element) => {
                    return element.id === foundedWheelId;
                  });
                const selectedWheel = {
                  ...wheel,
                  stock: supplier.stock,
                };
                selectedWheels.cartItems = [...selectedWheels.cartItems, selectedWheel];
              }
            }
            await store.dispatch('rimconfigCarConfigurator/selectConfiguratorWheel', selectedWheels);

            //get tpms data
            if (tpms) {
              if (store.state.rimconfigCarConfigurator.tpmsData.length === 0) {
                await store.dispatch('rimconfigCarConfigurator/loadTpmsCar', {
                  carTag: carTag,
                });
              }
              const selectedTpmsData = store.state.rimconfigCarConfigurator.tpmsData.find((item) => {
                return item.assigns.includes(Number(tpms));
              });
              await store.dispatch('rimconfigTpms/getTpmsAssigns', {
                tpmsTag: selectedTpmsData.tpmsTag,
                assigns: selectedTpmsData.assigns,
              });
              const tpmsAssignByTpmsTag = store.state.rimconfigTpms.tpmsAssignByTpmsTag;
              if (selectedTpmsData) {
                const selectedTpmsAssignData = tpmsAssignByTpmsTag[selectedTpmsData.tpmsTag]
                  .find((assignItem) => assignItem.id === Number(tpms));
                await store.dispatch('rimconfigCarConfigurator/selectConfiguratorTpms', {
                  assignId: Number(tpms),
                  quantity: tpmsQuantity,
                  cartItems: [{
                    ...selectedTpmsData,
                    description: selectedTpmsData.description1,
                    articleNumber: selectedTpmsData.man,
                    purchasePrice: selectedTpmsAssignData.purchasePrice,
                  }],
                });
              }
            }
          } catch (err) {
            console.log(err);
          }

          store.dispatch('dialog/setLoadingDialog', {
            status: false,
          });
          next();
        },
      },
      {
        path: 'rims-config/wheel-design',
        name: 'rims-config-wheelDesign',
        component: () => import(/* webpackChunkName: "rims-config-wheelDesign" */ '@/pages/rims-config/wheel-design'),
        meta: {
          layout: 'rims-config',
          requiresAuth: true,
        },
      },
      {
        path: 'rims-config/wheel-design/overview',
        name: 'rims-config-wheelDesign-overview',
        component: () => import(/* webpackChunkName: "rims-config-wheelDesign-overview" */ '@/pages/rims-config/wheel-design/overview'),
        meta: {
          layout: 'rims-config',
          requiresAuth: true,
        },
      },
      {
        path: 'rims-config/wheel-design/details',
        name: 'rims-config-wheelDesign_design-details',
        component: () => import(/* webpackChunkName: "rims-config-wheelDesign_design-details" */ '@/pages/rims-config/wheel-design/_design/details'),
        meta: {
          layout: 'rims-config',
          requiresAuth: true,
        },
      },
      {
        path: 'rims-config/tpms',
        name: 'rims-config-tpms',
        component: () => import(/* webpackChunkName: "rims-config-tpms" */ '@/pages/rims-config/tpms'),
        meta: {
          layout: 'rims-config',
          requiresAuth: true,
        },
      },
      {
        path: 'rims-config/tpms/search',
        name: 'rims-config-tpms-search',
        component: () => import(/* webpackChunkName: "rims-config-tpms-search" */ '@/pages/rims-config/tpms/search'),
        meta: {
          layout: 'rims-config',
          requiresAuth: true,
        },
      },
      {
        path: 'rims-config/tpms/:carTag',
        name: 'rims-config-tpms_carTag',
        component: () => import(/* webpackChunkName: "rims-config-tpms_carTag" */ '@/pages/rims-config/tpms/_carTag'),
        meta: {
          layout: 'rims-config',
          requiresAuth: true,
        },
      },
      {
        path: 'rims-config/tpms/:hsnTsnVsn',
        name: 'rims-config-tpms_hsnTsnVsn',
        component: () => import(/* webpackChunkName: "rims-config-tpms_carTag" */ '@/pages/rims-config/tpms/_carTag'),
        meta: {
          layout: 'rims-config',
          requiresAuth: true,
        },
      },
      {
        path: 'rims-config/tpms-kit',
        name: 'rims-config-tpmsKit',
        component: () => import(/* webpackChunkName: "rims-config-tpmsKit" */ '@/pages/rims-config/tpms-kit'),
        meta: {
          layout: 'rims-config',
          requiresAuth: true,
        },
      },
      {
        path: 'rims-config/tpms-kit/overview',
        name: 'rims-config-tpmsKit-overview',
        component: () => import(/* webpackChunkName: "rims-config-tpmsKit-overview" */ '@/pages/rims-config/tpms-kit/overview'),
        meta: {
          layout: 'rims-config',
          requiresAuth: true,
        },
      },
      {
        path: 'rims-config/tpms-kit/details',
        name: 'rims-config-tpmsKit_design-details',
        component: () => import(/* webpackChunkName: "rims-config-tpmsKit_design-details" */ '@/pages/rims-config/tpms-kit/_design/details'),
        meta: {
          layout: 'rims-config',
          requiresAuth: true,
        },
      },
      {
        path: 'rims-config/industry-agriculture',
        name: 'rims-config-industryAgriculture',
        component: () => import(/* webpackChunkName: "rims-config-industryAgriculture" */ '@/pages/rims-config/industry-agriculture'),
        meta: {
          layout: 'rims-config',
          requiresAuth: true,
        },
      },
      {
        path: 'marketplace/offering',
        name: 'marketplace-offering',
        component: () => import(/* webpackChunkName: "marketplace-offering" */ '@/pages/marketplace/offering'),
        meta: {
          layout: 'main',
          requiresAuth: true,
        },
      },
      {
        path: 'marketplace/offer',
        name: 'marketplace-offer',
        component: () => import(/* webpackChunkName: "marketplace-offer" */ '@/pages/marketplace/offer'),
        meta: {
          layout: 'main',
          requiresAuth: true,
        },
      },
      {
        path: 'marketplace/sale',
        name: 'marketplace-sale',
        component: () => import(/* webpackChunkName: "marketplace-sale" */ '@/pages/marketplace/sale'),
        meta: {
          layout: 'main',
          requiresAuth: true,
        },
      },
      {
        path: 'car-service',
        name: 'car-service',
        component: () => import(/* webpackChunkName: "car-service" */ '@/pages/car-service'),
        meta: {
          layout: 'main',
          requiresAuth: true,
        },
      },
      {
        path: 'steering',
        name: 'steering',
        component: () => import(/* webpackChunkName: "steering" */ '@/pages/steering'),
        meta: {
          layout: 'main',
          requiresAuth: true,
          disable: true,
        },
      },
      {
        path: 'cart',
        name: 'cart',
        component: () => import(/* webpackChunkName: "cart" */ '@/pages/cart'),
        meta: {
          layout: 'main',
          requiresAuth: true,
        },
      },
      {
        path: 'cart/edit-shipping-address',
        name: 'cart-edit-shipping-address',
        component: () => import(/* webpackChunkName: "cart" */ '@/pages/cart/edit-shipping-address'),
        meta: {
          layout: 'main',
          requiresAuth: true,
        },
      },
      {
        path: 'privacy',
        name: 'privacy',
        component: () => import(/* webpackChunkName: "impressum" */ '@/pages/privacy'),
        meta: {
          layout: 'plain',
          title: 'Datenschutzerklärung',
        },
      },
      {
        path: 'impressum',
        name: 'impressum',
        component: () => import(/* webpackChunkName: "impressum" */ '@/pages/impressum'),
        meta: {
          layout: 'plain',
          title: 'Impressum Initiator und Betreiber',
        },
      },
      {
        path: 'downloads',
        name: 'downloads',
        component: () => import(/* webpackChunkName: "downloads" */ '@/pages/downloads'),
        meta: {
          layout: 'plain',
          title: 'Downloads',
        },
      },
      {
        path: 'cookie-policy',
        name: 'cookie-policy',
        component: () => import(/* webpackChunkName: "cookie-policy" */ '@/pages/cookie-policy'),
        meta: {
          layout: 'plain',
          title: 'Verwendung von Cookies',
        },
      },
      {
        path: 'nutzungsbedingungen',
        name: 'nutzungsbedingungen',
        component: () => import(/* webpackChunkName: "nutzungsbedingungen" */ '@/pages/nutzungsbedingungen'),
        meta: {
          layout: 'plain',
          title: 'Nutzungsbedingungen',
        },
      },
      {
        path: 'admin',
        redirect: {
          name: 'admin-overview',
        },
      },
      {
        path: 'admin/overview',
        name: 'admin-overview',
        component: () => import(/* webpackChunkName: "admin-overview" */ '@/pages/admin/overview'),
        meta: {
          layout: 'admin',
          requiresAuth: true,
        },
      },
      {
        path: 'admin/orders',
        name: 'admin-orders',
        component: () => import(/* webpackChunkName: "admin-orders" */ '@/pages/admin/orders'),
        meta: {
          layout: 'admin',
          requiresAuth: true,
        },
      },
      {
        path: 'admin/price-tool',
        name: 'admin-price-tool',
        component: () => import(/* webpackChunkName: "admin-price-tool" */ '@/pages/admin/price-tool'),
        meta: {
          layout: 'admin',
          requiresAuth: true,
        },
      },
      {
        path: 'admin/dealer-calculation',
        name: 'admin-dealer-calculation',
        component: () => import(/* webpackChunkName: "admin-dealer-calculation" */ '@/pages/admin/dealer-calculation'),
        meta: {
          layout: 'admin',
          requiresAuth: true,
        },
      },
      {
        path: 'admin/offer-tool-settings',
        name: 'admin-offer-tool-settings',
        component: () => import(/* webpackChunkName: "admin-offer-tool-settings" */ '@/pages/admin/offer-tool-settings'),
        meta: {
          layout: 'admin',
          requiresAuth: true,
        },
      },
      {
        path: 'admin/claims',
        name: 'admin-claims',
        component: () => import(/* webpackChunkName: "admin-claims" */ '@/pages/admin/claims'),
        meta: {
          layout: 'admin',
          requiresAuth: true,
        },
      },
      {
        path: 'admin/account-info',
        name: 'admin-account-info',
        component: () => import(/* webpackChunkName: "admin-account-info" */ '@/pages/admin/account-info'),
        meta: {
          layout: 'admin',
          requiresAuth: true,
        },
      },
      {
        path: 'admin/bank',
        name: 'admin-bank',
        component: () => import(/* webpackChunkName: "admin-bank" */ '@/pages/admin/bank'),
        meta: {
          layout: 'admin',
          requiresAuth: true,
        },
      },
      {
        path: 'admin/general-terms-of-payment-and-delivery',
        name: 'admin-general-terms-of-payment-and-delivery',
        component: () => import(/* webpackChunkName: "admin-bank" */ '@/pages/admin/general-terms-of-payment-and-delivery'),
        meta: {
          layout: 'admin',
          requiresAuth: true,
        },
      },
      {
        path: 'admin/delivery-address',
        name: 'admin-delivery-address',
        component: () => import(/* webpackChunkName: "admin-delivery-address" */ '@/pages/admin/delivery-address'),
        meta: {
          layout: 'admin',
          requiresAuth: true,
        },
      },
      {
        path: '*',
        name: 'notfound',
        component: NotFound,
      },
    ],
  },
  {
    // Redirect user to supported lang version.
    path: '*',
    redirect: {
      name: 'login',
      params: {
        lang: 'de',
      },
    },
  },
];

const router = new Router({
  routes,
  mode: 'history',
  base: __dirname,
  scrollBehavior(to, from) {
    if (to.name === from.name) {
      return;
    }
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  document.title = to.meta.title ? `${to.meta.title} - TyresNParts` : 'TyresNParts | Tyres-N-Parts';
  if (to.query.token) {
    setCookie('user_access_token', to.query.token);
  }
  let lang = to.params.lang;
  if (SUPPORTED_LANGUAGES.includes(lang)) {
    await translation.changeLanguage(lang);
  } else {
    window.location.href = '/de/';
  }

  if (to.meta.disable) {
    next({
      name: 'notfound',
      params: { lang: lang },
    });
  } else {
    if (to.meta.requiresAuth) {
      const userSaveSession = sessionStorage.getItem('userInformation');
      const getUserEmail = userSaveSession ? JSON.parse(userSaveSession) : null;
      const isEmailPopupAlreadyOpened = sessionStorage.getItem('mailpwpopupopen');
      const token = to.query.token || getCookie('user_access_token');
      const user = to.query.user;
      const osKey = to.query.os_key;
      if (!isEmailPopupAlreadyOpened &&
        token &&
        getUserEmail !== null &&
        !getUserEmail.email
      ) {
        store.dispatch('user/checkConfirmEmail');
      } else if (!isEmailPopupAlreadyOpened && token) {
        store.dispatch('user/checkChangePassword');
      }
      // this route requires auth, check if logged in
      // if not, redirect to login page.
      if (to.meta.layout === 'admin' && store.state.user.isAllowAddressUser === null) {
        await store.dispatch('user/checkAllowAddress');
        next();
      } else if (to.meta.layout === 'admin' || to.meta.layout === 'main' || to.meta.layout === 'rims-config') {
        if (!token) {
          if (user && osKey) {
            store.dispatch('security/loginOsKeyCheck', {
              user: user,
              os_key: osKey,
            })
              .then(async () => {
                await store.dispatch('user/checkUserType');
                await store.dispatch('user/getUserInformation');
                await store.dispatch('dialog/getUserLoginInformation');
                let adjustedQuery = to.query;
                delete adjustedQuery.user;
                delete adjustedQuery.os_key;
                next({
                  name: to.name,
                  params: to.params,
                  query: adjustedQuery,
                });
              })
              .catch((err) => {
                if (err && err.response && err.response.config.url === '/user/loginInformation') {
                  return;
                }
              });
          } else {
            store.dispatch('dialog/setShowErrorDialog', {
              status: true,
              title: router.app.$t('langkey.token-expired'),
              message: router.app.$t('langkey.token-expired-message'),
            });
            next({
              name: 'login',
              params: { lang: lang },
            });
          }
        } else {
          const userType = store.state.user.userType;
          const userInformation = store.state.user.userInformation;
          if (!userType) {
            store.dispatch('user/checkUserType');
          }
          if (!userInformation) {
            await store.dispatch('user/getUserInformation');
          }
          next();
        }
      }
    } else {
      const user = to.query.user;
      const osKey = to.query.os_key;
      if (user && osKey) {
        store.dispatch('security/loginOsKeyCheck', {
          user: user,
          os_key: osKey,
        })
          .then(async () => {
            await store.dispatch('user/checkUserType');
            await store.dispatch('user/getUserInformation');
            await store.dispatch('dialog/getUserLoginInformation');
            let adjustedQuery = to.query;
            delete adjustedQuery.user;
            delete adjustedQuery.os_key;
            next({
              name: to.name,
              params: to.params,
              query: adjustedQuery,
            });
          })
          .catch((err) => {
            if (err && err.response && err.response.config.url === '/user/loginInformation') {
              return;
            }
          });
      } else {
        next(); // make sure to always call next()!
      }
    }
  }
});

export default router;
