<template>
  <base-checkbox
    v-model="isOptInValue"
    :label-text="$t('langkey.sale_mode.function')"
    reverse
    @input="onInputSelectFilter"
  />
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      isOptInValue: false,
    };
  },
  computed: {
    ...mapState('pricing', {
      priceType: 'priceType',
      isOptInState: 'isOptIn',
    }),
  },
  watch: {
    isOptInState(val) {
      this.isOptInValue = val;
    },
  },
  mounted() {
    this.isOptInValue = this.isOptInState;
    // if (!this.isOptInValue) {
    //   this.$store.dispatch('pricing/setPriceType', 'purchasePrice');
    // } else {
    //   this.$store.dispatch('pricing/setPriceType', 'retailPrice');
    // }
  },
  methods: {
    onInputSelectFilter() {
      this.$store.dispatch('pricing/setOptIn', this.isOptInValue);
      if (!this.isOptInValue) {
        this.$store.dispatch('pricing/setPriceType', 'purchasePrice');
      } else {
        this.$store.dispatch('pricing/setPriceType', 'retailPrice');
      }
    },
  },
};
</script>