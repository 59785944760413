<template>
  <div>
    <navigation v-if="isLogin" />
    <navigation-landing v-else />
    <v-main>
      <slot />
    </v-main>
    <footer-menu />
  </div>
</template>

<script>
import NavigationLanding from '@/components/NavigationLanding';
import Navigation from '@/components/Navigation';
import FooterMenu from '@/components/FooterMenu';
import { getCookie } from '@/utils/js-cookie';

export default {
  components: {
    NavigationLanding,
    Navigation,
    FooterMenu,
  },
  computed: {
    isLogin() {
      return getCookie('user_access_token');
    },
  },
};
</script>