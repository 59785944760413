<template>
  <base-rimconfig-card-car-selector
    class="rimconfig-card-car-selector-model"
    :title="$t('langkey.rim-config-search-by')"
  >
    <template #custom-header-content>
      <ul
        class="card-breadcrumb"
      >
        <li
          v-for="(item, index) in modelBreadcrumbItems"
          :key="`${item.key}${index}`"
          class="card-breadcrumb__item"
          @click="onClickBreadcrumbItem(item)"
        >
          {{ $t(item.label) }}
        </li>
      </ul>

      <div class="button-wrapper">
        <button
          :class="['button-menu first', !searchByKbaNumber && !searchByEanMan && 'selected']"
          @click="setSearchByKbaNumber(false)"
        >
          {{ $t('langkey.rim-config-configurator') }}
        </button>
        <button
          :class="['button-menu', searchByKbaNumber && 'selected']"
          @click="setSearchByKbaNumber(true)"
        >
          {{ $t('langkey.rim-config-kba-number') }}
        </button>
        <v-btn
          icon
          plain
          style="position: relative;top: -1px;"
          @click="onClickKbaInfo"
        >
          <icon-info color="red" />
        </v-btn>
        <button
          v-if="isTpms"
          :class="['button-menu', searchByEanMan && 'selected']"
          class="last"
          @click="setSearchByEanMan(true)"
        >
          {{ $t('langkey.tpms-search-ean-man') }}
        </button>
      </div>
    </template>

    <template #body>
      <div class="car-selector-model__container">
        <div class="select-model__section">
          <div class="input-wrapper">
            <input
              v-model="searchModels"
              type="search"
              class="input__search"
              placeholder="Search"
              @input="debounceSearch"
              @keydown.esc="clearSearch"
            >
            <v-btn
              v-if="searchModels"
              icon
              plain
              class="button__input-clear"
              @click="clearSearch"
            >
              <icon-close />
            </v-btn>
            <span class="icon__search">
              <icon-search color="var(--theme-black)" />
            </span>
          </div>

          <div v-bar>
            <transition name="page">
              <div
                v-if="isLoading"
                class="loading__wrapper"
              >
                <div class="loader">
                  <v-progress-circular
                    indeterminate
                    color="accent"
                  />
                </div>
              </div>
              <div
                v-else
                class="select-model__wrapper"
              >
                <v-list flat>
                  <v-list-item
                    v-for="(option, index) in filteredModels"
                    :key="`${option}${index}`"
                    :class="[
                      selectedModel === option && 'selected'
                    ]"
                    @click="onClickModelItem(option)"
                  >
                    <v-list-item-avatar
                      size="30"
                      tile
                    >
                      <icon-car />
                    </v-list-item-avatar>
                    <v-list-item-title>{{ option }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </div>
            </transition>
          </div>
        </div>

        <div class="car-history">
          <v-btn
            depressed
            plain
            block
            class="car-history__button"
          >
            <a
              @click="openCarSearchHistoryDialog"
            >
              {{ $t('langkey.rim-config-car-history') }}
            </a>
          </v-btn>
        </div>
      </div>
    </template>
  </base-rimconfig-card-car-selector>
</template>

<style lang="scss">
@import '@/styles/components/rimconfig-card-car-selector-model.scss';
</style>

<script>
import IconCar from '@/components/icons/IconCar';
import IconInfo from '@/components/icons/IconInfo';
import { mapState } from 'vuex';
import debounce from 'lodash/debounce';
import { breadcrumbItems } from '@/pages/rims-config/configurator/configs.js';

export default {
  components: {
    IconCar,
    IconInfo,
  },
  props: {
    searchByKbaNumber: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isTpms: {
      type: Boolean,
      default: false,
    },
    searchByEanMan: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchModels: '',
      filteredModels: [],
    };
  },
  computed: {
    ...mapState({
      modelsConfigurator: (state) => state.rimconfigCarSelector.models,
      selectedModelConfigurator: (state) => state.rimconfigCarSelector.selectedModel,
    }),
    ...mapState({
      modelsTpms: (state) => state.rimconfigCarSelectorTpms.models,
      selectedModelTpms: (state) => state.rimconfigCarSelectorTpms.selectedModel,
    }),
    models() {
      return this.isTpms ? this.modelsTpms : this.modelsConfigurator;
    },
    selectedModel() {
      return this.isTpms ? this.selectedModelTpms : this.selectedModelConfigurator;
    },
    breadcrumbItems() {
      return breadcrumbItems;
    },
    modelBreadcrumbItems() {
      return this.breadcrumbItems.slice(0,2);
    },
  },
  watch: {
    models(val) {
      this.filteredModels = val;
    },
  },
  mounted() {
    this.filteredModels = this.models;
  },
  methods: {
    onClickModelItem(model) {
      this.$emit('on-click-model-item', model);
    },
    debounceSearch: debounce(function(e) {
      this.searchModels = e.target.value;
      if (this.searchModels.length) {
        this.filteredModels = this.models.filter((item) => {
          return item.toLowerCase().includes(this.searchModels.toLowerCase().trim());
        });
      } else {
        this.filteredModels = this.models;
      }
    }, 400),
    clearSearch() {
      this.searchModels = '';
      this.filteredModels = this.models;
    },
    setSearchByKbaNumber(status) {
      this.$emit('set-search-by-kba-number', status);
    },
    onClickKbaInfo() {
      this.$emit('on-click-kba-info');
    },
    openCarSearchHistoryDialog() {
      this.$emit('open-car-search-history-dialog');
    },
    onClickBreadcrumbItem(item) {
      this.$emit('on-click-breadcrumb-item', item);
    },
    setSearchByEanMan(status) {
      this.$emit('set-search-by-ean-man', status);
    },
  },
};
</script>