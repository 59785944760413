<template>
  <base-rimconfig-card-car-selector
    class="rimconfig-card-car-selector-type"
    :title="$t('langkey.rim-config-search-by')"
  >
    <template #custom-header-content>
      <ul
        v-if="step === 3"
        class="card-breadcrumb"
      >
        <li
          v-for="(item, index) in typeBreadcrumbItems"
          :key="`${item.key}${index}`"
          class="card-breadcrumb__item"
          @click="onClickBreadcrumbItem(item)"
        >
          {{ $t(item.label) }}
        </li>
      </ul>

      <div class="button-wrapper">
        <button
          :class="['button-menu first', !searchByKbaNumber && !searchByEanMan && 'selected']"
          @click="setSearchByKbaNumber(false)"
        >
          {{ $t('langkey.rim-config-configurator') }}
        </button>
        <button
          :class="['button-menu', searchByKbaNumber && 'selected']"
          @click="setSearchByKbaNumber(true)"
        >
          {{ $t('langkey.rim-config-kba-number') }}
        </button>
        <v-btn
          icon
          plain
          style="position: relative;top: -1px;"
          @click="onClickKbaInfo"
        >
          <icon-info color="red" />
        </v-btn>
        <button
          v-if="isTpms"
          :class="['button-menu', searchByEanMan && 'selected']"
          class="last"
          @click="setSearchByEanMan(true)"
        >
          {{ $t('langkey.tpms-search-ean-man') }}
        </button>
      </div>
    </template>

    <template #body>
      <div class="car-selector-type__container">
        <div class="select-type__section">
          <div class="input-wrapper">
            <input
              v-model="searchTypes"
              type="search"
              class="input__search"
              placeholder="Search"
              @input="debounceSearch"
              @keydown.esc="clearSearch"
            >
            <v-btn
              v-if="searchTypes"
              icon
              plain
              class="button__input-clear"
              @click="clearSearch"
            >
              <icon-close />
            </v-btn>
            <span class="icon__search">
              <icon-search color="var(--theme-black)" />
            </span>
          </div>

          <div v-bar>
            <transition name="page">
              <div
                v-if="isLoading"
                class="loading__wrapper"
              >
                <div class="loader">
                  <v-progress-circular
                    indeterminate
                    color="accent"
                  />
                </div>
              </div>
              <div
                v-else
                class="select-type__wrapper"
              >
                <v-list flat>
                  <v-list-item
                    v-for="(option, index) in filteredTypes"
                    :key="`${option}${index}`"
                    :class="[
                      selectedType.group === option.group &&
                        selectedType.type === option.type
                        ? 'selected'
                        : null
                    ]"
                    @click="onClickTypeItem(option)"
                  >
                    <div class="left">
                      <img
                        :src="getImagePath(option, '250x200')"
                        class="car-image"
                      >
                    </div>
                    <div class="right">
                      <div class="upper">
                        <span class="label">{{ $t('langkey.year') }}:</span>
                        <span class="value">{{ getProductionText(option) }}</span>
                      </div>
                      <div class="lower">
                        <span class="label">{{ $t('langkey.type') }}:</span>
                        <span class="value">{{ option.type }}</span>
                      </div>
                    </div>
                  </v-list-item>
                </v-list>
              </div>
            </transition>
          </div>
        </div>

        <div class="car-history">
          <v-btn
            depressed
            plain
            block
            class="car-history__button"
          >
            <a
              @click="openCarSearchHistoryDialog"
            >
              {{ $t('langkey.rim-config-car-history') }}
            </a>
          </v-btn>
        </div>
      </div>
    </template>
  </base-rimconfig-card-car-selector>
</template>

<style lang="scss">
@import '@/styles/components/rimconfig-card-car-selector-type.scss';
</style>

<script>
import { mapState, mapGetters } from 'vuex';
import debounce from 'lodash/debounce';
import { breadcrumbItems } from '@/pages/rims-config/configurator/configs.js';
import IconInfo from '@/components/icons/IconInfo';

export default {
  components: {
    IconInfo,
  },
  props: {
    searchByKbaNumber: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isTpms: {
      type: Boolean,
      default: false,
    },
    step: {
      type: Number,
      default: 1,
    },
    searchByEanMan: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchTypes: '',
      filteredTypes: [],
    };
  },
  computed: {
    ...mapState({
      selectedTypeConfigurator: (state) => state.rimconfigCarSelector.selectedType,
      selectedTypeTpms: (state) => state.rimconfigCarSelectorTpms.selectedType,
      getGroupedTypesConfigurator: (state) => state.rimconfigCarSelector.types,
      getGroupedTypesTpms: (state) => state.rimconfigCarSelectorTpms.types,
    }),
    selectedType() {
      return this.isTpms ? this.selectedTypeTpms : this.selectedTypeConfigurator;
    },
    getGroupedTypes() {
      return this.isTpms ? this.getGroupedTypesTpms : this.getGroupedTypesConfigurator;
    },
    breadcrumbItems() {
      return breadcrumbItems;
    },
    typeBreadcrumbItems() {
      return this.breadcrumbItems.slice(0,3);
    },
  },
  watch: {
    getGroupedTypes(val) {
      this.filteredTypes = val;
    },
  },
  mounted() {
    this.filteredTypes = this.getGroupedTypes;
  },
  methods: {
    onClickTypeItem(option) {
      this.$emit('on-click-type-item', {
        ...option,
      });
    },
    debounceSearch: debounce(function(e) {
      this.searchTypes = e.target.value;
      if (this.searchTypes.length) {
        this.filteredTypes = this.getGroupedTypes.filter((item) => {
          return item.type.toLowerCase().includes(this.searchTypes.toLowerCase().trim()) ||
            item.production.includes(this.searchTypes.trim());
        });
      } else {
        this.filteredTypes = this.getGroupedTypes;
      }
    }, 400),
    clearSearch() {
      this.searchTypes = '';
      this.filteredTypes = this.getGroupedTypes;
    },
    setSearchByKbaNumber(status) {
      this.$emit('set-search-by-kba-number', status);
    },
    onClickKbaInfo() {
      this.$emit('on-click-kba-info');
    },
    getImagePath({ group }, size) {
      const imagePrefix = 'https://cdn.jfnet.de/carPerspective/';
      const imageSuffix = `/${size}-cc-xxxxxx.png`;
      return `${imagePrefix}${group}${imageSuffix}`;
    },
    openCarSearchHistoryDialog() {
      this.$emit('open-car-search-history-dialog');
    },
    onClickBreadcrumbItem(item) {
      this.$emit('on-click-breadcrumb-item', item);
    },
    getProductionText({ productionFrom, productionTo }) {
      const from = productionFrom.split('-');
      const to = productionTo.split('-');
      return `${from[0] !== '0000' ? from[0] : ''} - ${to[0] !== '0000' ? to[0] : ''}`;
    },
    setSearchByEanMan(status) {
      this.$emit('set-search-by-ean-man', status);
    },
  },
};
</script>