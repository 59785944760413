import { DealerCalculationService } from '@/services';
import { networkStatus } from '@/constants/networkStatus';
import {
  SET_DEALER_CALCULATION,
  SET_DEALER_CALCULATION_BY_ID,
  SET_OFFER_TOOL_SETTINGS,
} from '../mutationTypes';

const state = {
  calculations: [],
  calculationData: null,
  offerToolSettings: {
    tax: '',
    alloyMountCost: '',
    steelMountCost: '',
    heavyTyreAlloyMountCost: '',
    heavyTyreSteelMountCost: '',
    tpmsSensor: '',
    tpmsProgramming: '',
    valveRubber: '',
    valveMetal: '',
    heave: '',
    adhesiveWeightFlatRate: '',
    impactWeightFlatRate: '',
    wheelMount: '',
    wheelWash: '',
    warehousing: '',
    freightCharges: '',
    tyreDisposal: '',
    wheelAlignment: '',
    offerClosingText: '',
    round: '',
  },
};

const mutations = {
  [SET_DEALER_CALCULATION](stateData, data) {
    stateData.calculations =  data;
  },
  [SET_DEALER_CALCULATION_BY_ID](stateData, data) {
    stateData.calculationData =  data;
  },
  [SET_OFFER_TOOL_SETTINGS](stateData, data) {
    stateData.offerToolSettings = data;
  },
};

const actions = {
  async getCalculations({ commit }) {
    const response = await DealerCalculationService.getCalculations();
    if (response.status === networkStatus['ok']) {
      commit(SET_DEALER_CALCULATION, response.data);
    }
    return response;
  },
  async getCalculationById({ commit }, id) {
    const response = await DealerCalculationService.getCalculationById(id);
    if (response.status === networkStatus['ok']) {
      commit(SET_DEALER_CALCULATION_BY_ID, response.data);
    }
    return response;
  },
  async createCalculation({ commit }, payload = {}) {
    const response = await DealerCalculationService.createCalculation(payload);
    if (response.status === networkStatus['ok']) {
      commit(SET_DEALER_CALCULATION_BY_ID, response.data);
    }
    return response;
  },
  async updateCalculation({ commit }, { id, payload }) {
    const response = await DealerCalculationService.updateCalculation(id, payload);
    return response;
  },
  async deleteCalculation({ commit }, id) {
    const response = await DealerCalculationService.deleteCalculation(id);
    return response;
  },
  async getOfferToolSettings({ commit }) {
    const response = await DealerCalculationService.getOfferToolSettings();
    if (response.status === networkStatus['ok']) {
      commit(SET_OFFER_TOOL_SETTINGS, response.data);
    }
    return response;
  },
  async updateOfferToolSettings({ commit }, payload = {}) {
    const response = await DealerCalculationService.updateOfferToolSettings(payload);
    return response;
  },
  async updateSettingsLogo({ commit }, payload) {
    const response = await DealerCalculationService.updateSettingsLogo(payload);
    return response;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
