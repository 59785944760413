<template>
  <div class="base-rimconfig-car-selector">
    <transition name="slide-fade">
      <div v-if="step === 1">
        <transition
          name="fade"
          mode="out-in"
        >
          <rimconfig-car-search-kba-number
            v-if="searchByKbaNumber"
            :is-tpms="isTpms"
            :search-by-kba-number="searchByKbaNumber"
            :search-by-ean-man="searchByEanMan"
            @set-search-by-kba-number="setSearchByKbaNumber"
            @set-search-by-ean-man="setSearchByEanMan"
            @on-zoom-kba-image="onZoomKbaImage"
            @on-click-kba-info="onClickKbaInfo"
            @on-show-kba-versions="onShowKbaVersions"
            @open-car-search-history-dialog="openCarSearchHistoryDialog"
          />
          <rimconfig-car-search-ean-man
            v-else-if="searchByEanMan"
            :is-tpms="isTpms"
            :search-by-kba-number="searchByKbaNumber"
            :search-by-ean-man="searchByEanMan"
            @set-search-by-kba-number="setSearchByKbaNumber"
            @set-search-by-ean-man="setSearchByEanMan"
            @on-zoom-kba-image="onZoomKbaImage"
            @on-click-kba-info="onClickKbaInfo"
            @open-car-search-history-dialog="openCarSearchHistoryDialog"
          />
          <rimconfig-car-selector-manufacturer
            v-else
            :is-tpms="isTpms"
            :search-by-kba-number="searchByKbaNumber"
            :search-by-ean-man="searchByEanMan"
            :is-loading="isLoading"
            @on-click-manufacturer-item="onClickItem(1, $event)"
            @set-search-by-kba-number="setSearchByKbaNumber"
            @set-search-by-ean-man="setSearchByEanMan"
            @on-click-kba-info="onClickKbaInfo"
            @open-car-search-history-dialog="openCarSearchHistoryDialog"
          />
        </transition>
      </div>
      <div v-else-if="step === 2">
        <transition
          name="fade"
          mode="out-in"
        >
          <rimconfig-car-search-kba-number
            v-if="searchByKbaNumber"
            :is-tpms="isTpms"
            :search-by-kba-number="searchByKbaNumber"
            :search-by-ean-man="searchByEanMan"
            @set-search-by-kba-number="setSearchByKbaNumber"
            @set-search-by-ean-man="setSearchByEanMan"
            @on-zoom-kba-image="onZoomKbaImage"
            @on-click-kba-info="onClickKbaInfo"
            @on-show-kba-versions="onShowKbaVersions"
            @open-car-search-history-dialog="openCarSearchHistoryDialog"
          />
          <rimconfig-car-selector-model
            v-else
            :is-tpms="isTpms"
            :search-by-kba-number="searchByKbaNumber"
            :search-by-ean-man="searchByEanMan"
            :is-loading="isLoading"
            @on-click-model-item="onClickItem(2, $event)"
            @on-click-breadcrumb-item="onClickBreadcrumbItem"
            @set-search-by-kba-number="setSearchByKbaNumber"
            @set-search-by-ean-man="setSearchByEanMan"
            @on-click-kba-info="onClickKbaInfo"
            @open-car-search-history-dialog="openCarSearchHistoryDialog"
          />
        </transition>
      </div>
      <div v-else-if="step === 3">
        <transition
          name="fade"
          mode="out-in"
        >
          <rimconfig-car-search-kba-number
            v-if="searchByKbaNumber"
            :is-tpms="isTpms"
            :search-by-kba-number="searchByKbaNumber"
            :search-by-ean-man="searchByEanMan"
            @set-search-by-kba-number="setSearchByKbaNumber"
            @set-search-by-ean-man="setSearchByEanMan"
            @on-zoom-kba-image="onZoomKbaImage"
            @on-click-kba-info="onClickKbaInfo"
            @on-show-kba-versions="onShowKbaVersions"
            @open-car-search-history-dialog="openCarSearchHistoryDialog"
          />
          <rimconfig-car-selector-type
            v-else
            :is-tpms="isTpms"
            :step="step"
            :search-by-kba-number="searchByKbaNumber"
            :search-by-ean-man="searchByEanMan"
            :is-loading="isLoading"
            @on-click-type-item="onClickItem(3, $event)"
            @on-click-breadcrumb-item="onClickBreadcrumbItem"
            @set-search-by-kba-number="setSearchByKbaNumber"
            @set-search-by-ean-man="setSearchByEanMan"
            @on-click-kba-info="onClickKbaInfo"
            @open-car-search-history-dialog="openCarSearchHistoryDialog"
          />
        </transition>
      </div>
      <div v-else-if="step === 4">
        <transition
          name="fade"
          mode="out-in"
        >
          <div class="step4">
            <rimconfig-car-search-kba-number
              v-if="searchByKbaNumber"
              class="base-rimconfig-card-car-selector-kba-number"
              :is-tpms="isTpms"
              :search-by-kba-number="searchByKbaNumber"
              :search-by-ean-man="searchByEanMan"
              @set-search-by-kba-number="setSearchByKbaNumber"
              @set-search-by-ean-man="setSearchByEanMan"
              @on-zoom-kba-image="onZoomKbaImage"
              @on-click-kba-info="onClickKbaInfo"
              @on-show-kba-versions="onShowKbaVersions"
              @open-car-search-history-dialog="openCarSearchHistoryDialog"
            />
            <rimconfig-car-selector-type
              v-else
              class="base-rimconfig-card-car-selector-type"
              :is-tpms="isTpms"
              :step="step"
              :search-by-kba-number="searchByKbaNumber"
              :search-by-ean-man="searchByEanMan"
              :is-loading="isLoading"
              @set-search-by-ean-man="setSearchByEanMan"
              @on-click-type-item="onClickItem(3, $event)"
              @on-click-breadcrumb-item="onClickBreadcrumbItem"
              @set-search-by-kba-number="setSearchByKbaNumber"
              @on-click-kba-info="onClickKbaInfo"
              @open-car-search-history-dialog="openCarSearchHistoryDialog"
            />
            <rimconfig-car-selector-version
              class="base-rimconfig-card-car-selector-version"
              :is-tpms="isTpms"
              :is-loading="isLoadingVersion"
              @row-selected="onClickItem(4, $event)"
              @open-car-search-history-dialog="openCarSearchHistoryDialog"
              @on-click-breadcrumb-item="onClickBreadcrumbItem"
            />
          </div>
        </transition>
      </div>

      <div v-else>
        <transition
          name="fade"
          mode="out-in"
        >
          <div class="step-kba-versions">
            <rimconfig-car-search-kba-number
              class="base-rimconfig-card-car-selector-kba-number"
              is-kba-versions
              :is-tpms="isTpms"
              @on-zoom-kba-image="onZoomKbaImage"
              @on-click-kba-info="onClickKbaInfo"
              @on-search-kba-versions="onSearchKbaVersions"
              @open-car-search-history-dialog="openCarSearchHistoryDialog"
            />
            <rimconfig-car-selector-version
              class="base-rimconfig-card-car-selector-version"
              is-kba-versions
              :is-tpms="isTpms"
              :is-loading="isLoadingKbaVersion"
              @row-selected="onClickItem('kba-versions', $event)"
              @open-car-search-history-dialog="openCarSearchHistoryDialog"
              @on-click-breadcrumb-item="onClickBreadcrumbItem"
            />
          </div>
        </transition>
      </div>
    </transition>

    <rimconfig-kba-image-zoom-dialog
      ref="kbaImageZoomDialog"
    />
    <rimconfig-kba-info-dialog
      ref="kbaInfoDialog"
    />
    <rimconfig-car-search-history-dialog
      ref="carSearchHistoryDialog"
    />
  </div>
</template>

<script>
import RimconfigCarSelectorManufacturer from '@/components/RimconfigCarSelectorManufacturer';
import RimconfigCarSelectorModel from '@/components/RimconfigCarSelectorModel';
import RimconfigCarSelectorType from '@/components/RimconfigCarSelectorType';
import RimconfigCarSelectorVersion from '@/components/RimconfigCarSelectorVersion';
import RimconfigCarSearchKbaNumber from '@/components/RimconfigCarSearchKbaNumber';
import RimconfigKbaImageZoomDialog from '@/components/RimconfigKbaImageZoomDialog';
import RimconfigKbaInfoDialog from '@/components/RimconfigKbaInfoDialog';
import RimconfigCarSearchHistoryDialog from '@/components/RimconfigCarSearchHistoryDialog';
import RimconfigCarSearchEanMan from '@/components/RimconfigCarSearchEanMan';
import { mapState } from 'vuex';

export default {
  components: {
    RimconfigCarSelectorManufacturer,
    RimconfigCarSelectorModel,
    RimconfigCarSelectorType,
    RimconfigCarSelectorVersion,
    RimconfigCarSearchKbaNumber,
    RimconfigKbaImageZoomDialog,
    RimconfigKbaInfoDialog,
    RimconfigCarSearchHistoryDialog,
    RimconfigCarSearchEanMan,
  },
  props: {
    isTpms: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      isLoadingVersion: false,
      isLoadingKbaVersion: false,
      showCarSearchHistory: false,
    };
  },
  computed: {
    ...mapState({
      stepConfigurator: (state) => state.rimconfigCarSelector.step,
      selectedManufacturerConfigurator: (state) => state.rimconfigCarSelector.selectedManufacturer,
      selectedModelConfigurator: (state) => state.rimconfigCarSelector.selectedModel,
      searchByKbaNumberConfigurator: (state) => state.rimconfigCarSelector.searchByKbaNumber,
      carGroupByGermanCodeConfigurator: (state) => state.rimconfigCarSelector.carGroupByGermanCode,
    }),
    ...mapState({
      stepTpms: (state) => state.rimconfigCarSelectorTpms.step,
      selectedManufacturerTpms: (state) => state.rimconfigCarSelectorTpms.selectedManufacturer,
      selectedModelTpms: (state) => state.rimconfigCarSelectorTpms.selectedModel,
      searchByKbaNumberTpms: (state) => state.rimconfigCarSelectorTpms.searchByKbaNumber,
      carGroupByGermanCodeTpms: (state) => state.rimconfigCarSelectorTpms.carGroupByGermanCode,
      searchByEanMan: (state) => state.rimconfigCarSelectorTpms.searchByEanMan,
    }),
    carSelectorModule() {
      return this.isTpms ? 'rimconfigCarSelectorTpms' : 'rimconfigCarSelector';
    },
    step() {
      return this.isTpms ? this.stepTpms : this.stepConfigurator;
    },
    selectedManufacturer() {
      return this.isTpms ? this.selectedManufacturerTpms : this.selectedManufacturerConfigurator;
    },
    selectedModel() {
      return this.isTpms ? this.selectedModelTpms : this.selectedModelConfigurator;
    },
    searchByKbaNumber() {
      return this.isTpms ? this.searchByKbaNumberTpms : this.searchByKbaNumberConfigurator;
    },
    carGroupByGermanCode() {
      return this.isTpms ? this.carGroupByGermanCodeTpms : this.carGroupByGermanCodeConfigurator;
    },
  },
  mounted() {
    this.isLoading = true;
    this.$store.dispatch(`${this.carSelectorModule}/getConfiguratorManufacturers`)
      .then(() => {
        this.isLoading = false;
      });
  },
  methods: {
    onClickItem(step, value) {
      switch (step) {
      case 1:
        this.isLoading = true;
        this.$store.dispatch(`${this.carSelectorModule}/getConfiguratorClasses`, {
          manufacturer: value,
        })
          .then(() => {
            this.isLoading = false;
          });
        this.$store.dispatch(`${this.carSelectorModule}/setSelectedManufacturer`, value);
        step += 1;
        break;
      case 2:
        this.isLoading = true;
        this.$store.dispatch(`${this.carSelectorModule}/getConfiguratorTypes`, {
          manufacturer: this.selectedManufacturer,
          class: value,
        })
          .then(() => {
            this.isLoading = false;
          });
        this.$store.dispatch(`${this.carSelectorModule}/setSelectedModel`, value);
        step += 1;
        break;
      case 3:
        if (step === 3) {
          this.isLoadingVersion = true;
          this.$store.dispatch(`${this.carSelectorModule}/getConfiguratorVersions`, {
            group: value.group,
          })
            .then(() => {
              this.isLoadingVersion = false;
            });
          this.$store.dispatch(`${this.carSelectorModule}/setSelectedType`, value);
          step += 1;
        }
        break;
      case 4:
        // Set car history
        this.$emit('select-version', value);
        break;
      case 'kba-versions':
        // Set car history
        this.$emit('select-version', value);
        break;
      }
      if (step !== 'kba-versions') {
        this.$store.dispatch(`${this.carSelectorModule}/setStep`, step);
      }
    },
    onClickBreadcrumbItem(item) {
      this.$store.dispatch(`${this.carSelectorModule}/setStep`, item.step);
    },
    setSearchByKbaNumber(status) {
      this.$store.dispatch(`${this.carSelectorModule}/setSearchByKbaNumber`, status);
    },
    onZoomKbaImage() {
      this.$refs.kbaImageZoomDialog.show();
    },
    onClickKbaInfo() {
      this.$refs.kbaInfoDialog.show();
    },
    onShowKbaVersions() {
      this.$store.dispatch(`${this.carSelectorModule}/setStep`, 'kba-versions');
    },
    onSearchKbaVersions(payload) {
      this.isLoadingKbaVersion = true;
      this.$store.dispatch(`${this.carSelectorModule}/getCarGroupByGermanCode`, payload)
        .then(() => {
          const len = this.carGroupByGermanCode.length;

          if (!this.isTpms) {
            if (len === 0) {
              this.isLoadingKbaVersion = false;
              this.$store.dispatch('dialog/setShowErrorDialog', {
                status: true,
                title: this.$t('langkey.kba-no-car-found-header'),
                message: this.$t('langkey.kba-no-car-found-message'),
              });
              return;
            } else {
              this.isLoadingKbaVersion = false;
            }
          } else {
            if (len === 0) {
              this.isLoadingKbaVersion = false;
              this.$store.dispatch('dialog/setShowErrorDialog', {
                status: true,
                title: this.$t('langkey.kba-no-car-found-header'),
                message: this.$t('langkey.kba-no-car-found-message'),
              });
              return;
            } else {
              this.isLoadingKbaVersion = false;
            }
          }
        })
        .catch((err) => {
          this.isLoadingKbaVersion = false;
          this.$store.dispatch('dialog/setShowErrorDialog', {
            status: true,
            title: this.$t('langkey.kba-search-error-header'),
            message: err,
          });
        });
    },
    openCarSearchHistoryDialog() {
      this.$refs.carSearchHistoryDialog.show();
    },
    setSearchByEanMan(status) {
      this.$store.dispatch(`${this.carSelectorModule}/setSearchByEanMan`, status);
    },
  },
};
</script>