<template>
  <div
    class="base-virtual-table"
    :style="{ opacity: isLoading ? 0.5 : 1 }"
  >
    <div
      class="table-header-section"
      :style="{
        ...getGridTemplateColumnsMdVar,
        ...getGridTemplateColumnsLgVar,
        width: tableItems.length < 12 ? '100%' : null
      }"
    >
      <div
        v-for="(field, index) in tableFields"
        :key="`virtualTableHeadTh${index}`"
        :style="{
          textAlign: field.align,
          pointerEvents: tableItems.length === 0 && field.value !== 'prefix'
            ? 'none'
            : null,
        }"
        :class="[
          'table-header',
          field.value,
          field.sortable ? 'sortable' : null
        ]"
        :aria-sort="[
          field.sortable
            ? sortColStatus[field.value]
            : null
        ]"
        @click="onSort(field)"
      >
        <span
          :class="['th-text', field.sortable && field.align ? 'is-sort-center' : null]"
        >
          <slot
            v-if="$scopedSlots[`th(${field.value})`]"
            :name="`th(${field.value})`"
            :item="field"
          />
          <template v-else>
            {{ $t(field.text) }}
          </template>
        </span>
      </div>
    </div>
    <transition name="fade">
      <v-progress-linear
        v-if="isLoading"
        indeterminate
        color="accent"
      />
    </transition>
    <table
      v-if="tableItems.length === 0 && !isLoading && showEmpty"
      class="table-empty"
    >
      <colgroup>
        <col
          v-for="(field, fieldIndex) in tableFieldsForSupplier"
          :key="`virtualTableColEmpty${fieldIndex}`"
          span="1"
        >
      </colgroup>
      <tbody>
        <tr>
          <td :colspan="tableFieldsForSupplier.length">
            {{ $t('langkey.no-result') }}
          </td>
        </tr>
      </tbody>
    </table>
    <v-virtual-scroll
      v-show="tableItems.length > 0"
      :bench="10"
      :items="tableItems"
      height="500"
      :item-height="virtualTableRowHeight"
    >
      <template v-slot:default="{ item, index }">
        <table
          v-if="isTyre && item.isLoading"
          :class="['table-loading', index % 2 === 1 ? 'gray-bg' : null]"
        >
          <colgroup>
            <col
              v-for="(field, fieldIndex) in tableFieldsForSupplier"
              :key="`virtualTableColLoading${index}${fieldIndex}`"
              span="1"
            >
          </colgroup>
          <tbody>
            <tr>
              <td :colspan="tableFieldsForSupplier.length">
                <v-progress-circular
                  :size="25"
                  indeterminate
                  color="accent"
                />
              </td>
            </tr>
          </tbody>
        </table>

        <table
          v-else-if="isTyre && item.isNoMoreResult"
          :class="['table-loading', index % 2 === 1 ? 'gray-bg' : null]"
        >
          <colgroup>
            <col
              v-for="(field, fieldIndex) in tableFieldsForSupplier"
              :key="`virtualTableColNoMore${index}${fieldIndex}`"
              span="1"
            >
          </colgroup>
          <tbody>
            <tr>
              <td :colspan="tableFieldsForSupplier.length">
                {{ $t('langkey.no-more-result') }}
              </td>
            </tr>
          </tbody>
        </table>

        <table
          v-else-if="isTyre && item.isSupplierHeader"
          class="table-body-supplier-expand-header"
        >
          <colgroup>
            <col
              v-for="(field, fieldIndex) in tableFieldsForSupplier"
              :key="`virtualTableColHeadSupplier${index}${fieldIndex}`"
              span="1"
              :style="{
                width: field.width,
                minWidth: field.minWidth,
              }"
            >
          </colgroup>
          <thead>
            <tr>
              <th
                v-for="(field, fieldIndex) in tableFieldsForSupplier"
                :key="`virtualTableHeadThSupplier${fieldIndex}`"
                :style="{
                  textAlign: field.align
                }"
              >
                <slot
                  v-if="$scopedSlots[`thSupplier(${field.value})`]"
                  :name="`thSupplier(${field.value})`"
                  :field="field"
                  :item="item"
                />
                <template v-else>
                  {{ $t(field.text) }}
                </template>
              </th>
            </tr>
          </thead>
        </table>

        <table
          v-else-if="isTyre && item.isSupplier"
          :class="[
            'table-body-supplier-expand-body',
            item.index % 2 === 1 ? 'gray-bg' : null,
            item.isLast ? 'is-last' : null,
          ]"
        >
          <colgroup>
            <col
              v-for="(field, fieldIndex) in tableFieldsForSupplier"
              :key="`virtualTableColBodySupplier${index}${fieldIndex}`"
              span="1"
              :style="{
                width: field.width,
                minWidth: field.minWidth,
              }"
            >
          </colgroup>
          <tbody>
            <tr
              :key="`virtualTableBodyTrSupplier${index}${item.id}`"
            >
              <td
                v-for="(field, fieldIndex) in tableFieldsForSupplier"
                :key="`virtualTableBodyTdSupplier${index}${fieldIndex}`"
                :style="{
                  textAlign: field.align
                }"
              >
                <slot
                  v-if="$scopedSlots[`tdSupplier(${field.value})`]"
                  :name="`tdSupplier(${field.value})`"
                  :item="item"
                  :index="index"
                  :value="item[field.value]"
                />
                <template v-else>
                  {{ item[field.value] }}
                </template>
              </td>
            </tr>
          </tbody>
        </table>

        <div
          v-else
          :class="['table-body-section', index % 2 === 1 ? 'gray-bg' : null]"
          :style="{
            ...getGridTemplateColumnsMdVar,
            ...getGridTemplateColumnsLgVar,
          }"
          @click="onClickTableBodyRow(item, index)"
        >
          <div
            v-for="(field, fieldIndex) in tableFields"
            :key="`virtualTableBodyTd${index}${fieldIndex}`"
            :style="{
              textAlign: field.align
            }"
            :class="['table-body-data', field.value]"
          >
            <slot
              v-if="$scopedSlots[`td(${field.value})`]"
              :name="`td(${field.value})`"
              :item="item"
              :index="index"
              :value="item[field.value]"
            />
            <template v-else>
              {{ item[field.value] }}
            </template>
          </div>
        </div>
      </template>
    </v-virtual-scroll>
  </div>
</template>

<style lang="scss">
@import "@/styles/components/base-virtual-table.scss";
</style>

<script>
import { windowSize } from '@/utils/mixins';
export default {
  mixins: [windowSize],
  props: {
    tableFields: {
      type: Array,
      default: () => [],
    },
    tableItems: {
      type: Array,
      default: () => [],
    },
    tableFieldsSupplier: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isTyre: {
      type: Boolean,
      default: false,
    },
    showEmpty: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeSortCol: '',
      sortColStatus: {},
    };
  },
  computed: {
    virtualTableRowHeight() {
      if (this.windowWidth < 1400) {
        return 64;
      }
      return 42;
    },
    tableFieldsForSupplier() {
      return this.tableFieldsSupplier;
    },
    getSortableFields() {
      return this.tableFields.filter((item) => item.sortable);
    },
    getGridTemplateColumnsMdVar() {
      let result = '';
      this.tableFields.forEach((item, index) => {
        if (index === 0) {
          result += `minmax(${item.minWidth.desktop}, ${item.maxWidth.desktop})`;
        } else {
          result += ' ' + `minmax(${item.minWidth.desktop}, ${item.maxWidth.desktop})`;
        }
      });
      return {
        '--table-grid-template-columns-md': result,
      };
    },
    getGridTemplateColumnsLgVar() {
      let result = '';
      this.tableFields.forEach((item, index) => {
        if (index === 0) {
          result += `minmax(${item.minWidth.desktopLg}, ${item.maxWidth.desktopLg})`;
        } else {
          result += ' ' + `minmax(${item.minWidth.desktopLg}, ${item.maxWidth.desktopLg})`;
        }
      });
      return {
        '--table-grid-template-columns-lg': result,
      };
    },
    getFieldStyleVars() {
      let result = {};
      this.tableFields.forEach((item, index) => {
        result = {
          ...result,
          [`--table-min-width-md-${item.value}`]: item.minWidth.desktop,
          [`--table-max-width-md-${item.value}`]: item.maxWidth.desktop,
          [`--table-min-width-lg-${item.value}`]: item.minWidth.desktopLg,
          [`--table-max-width-lg-${item.value}`]: item.maxWidth.desktopLg,
        };
      });
      return result;
    },
  },
  created() {
    this.getSortableFields.forEach((field) => {
      if (field.value === 'purchasePrice') { // initial tyre table sort
        this.sortColStatus = {
          ...this.sortColStatus,
          [field.value]: 'ascending',
        };
      } else {
        this.sortColStatus = {
          ...this.sortColStatus,
          [field.value]: 'none',
        };
      }
    });
  },
  methods: {
    onSort(selectedColField) {
      if (!selectedColField.sortable || this.tableItems.length === 0) {
        return;
      }

      const currentStatus = this.sortColStatus[selectedColField.value];
      let newStatus = '';
      if (currentStatus === 'none') {
        newStatus = 'ascending';
      } else if (currentStatus === 'ascending') {
        newStatus = 'descending';
      } else if (currentStatus === 'descending') {
        newStatus = 'none';
      }

      this.getSortableFields.forEach((field) => {
        if (selectedColField.value === field.value) {
          this.sortColStatus = {
            ...this.sortColStatus,
            [field.value]: newStatus,
          };
        } else {
          this.sortColStatus = {
            ...this.sortColStatus,
            [field.value]: 'none',
          };
        }
      });

      document.querySelector('.v-virtual-scroll').scrollTo(0, 0);
      this.activeSortCol = selectedColField.value;
      this.$emit('on-sort', {
        activeSortCol: this.activeSortCol,
        status: newStatus,
      });
    },
    onClickTableBodyRow(item, index) {
      this.$emit('select-row', { item, index });
    },
  },
};
</script>