<template>
  <v-dialog
    v-model="showKbaInfo"
    max-width="1000"
    rounded="lg"
  >
    <v-card class="rimconfig-kba-image-zoom">
      <v-card-title>
        <v-btn
          icon
          plain
          class="close__button"
          @click="hide"
        >
          <icon-close size="18" />
        </v-btn>
      </v-card-title>
      <v-card-text>
        <img
          class="img__term-of-service"
          :src="require(`@/assets/images/kba.png`)"
        >
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style lang="scss">
@import "@/styles/components/rimconfig-kba-image-zoom__dialog.scss";
</style>

<script>
export default {
  data() {
    return {
      showKbaInfo: false,
    };
  },
  methods: {
    show() {
      this.showKbaInfo = true;
    },
    hide() {
      this.showKbaInfo = false;
    },
  },
};
</script>