<template>
  <svg
    id="Group_4500"
    data-name="Group 4500"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      id="Path_5305"
      data-name="Path 5305"
      d="M0,0H24V24H0Z"
      fill="none"
    />
    <circle
      id="Ellipse_227"
      class="svg-icon-stroke"
      data-name="Ellipse 227"
      cx="2"
      cy="2"
      r="2"
      transform="translate(4 17)"
      fill="none"
      :stroke="color"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
    />
    <circle
      id="Ellipse_228"
      class="svg-icon-stroke"
      data-name="Ellipse 228"
      cx="2"
      cy="2"
      r="2"
      transform="translate(15 17)"
      fill="none"
      :stroke="color"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
    />
    <path
      id="Path_5306"
      class="svg-icon-stroke"
      data-name="Path 5306"
      d="M17,17H6V3H4"
      fill="none"
      :stroke="color"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
    />
    <path
      id="Path_5307"
      class="svg-icon-stroke"
      data-name="Path 5307"
      d="M6,5l6.005.429M19.143,12,19,13H6"
      fill="none"
      :stroke="color"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
    />
    <g
      id="Group_4723"
      data-name="Group 4723"
    >
      <path
        id="Path_5308"
        class="svg-icon-stroke"
        data-name="Path 5308"
        d="M15,6h6M18,3l3,3"
        fill="none"
        :stroke="color"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <path
        id="Path_5402"
        class="svg-icon-stroke"
        data-name="Path 5402"
        d="M15,3h6M18,6l3-3"
        transform="translate(0 3)"
        fill="none"
        :stroke="color"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#000',
    },
  },
};
</script>