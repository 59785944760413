import { CartService } from '@/services';
import { networkStatus } from '@/constants/networkStatus';
import {
  SET_CART_SOURCES,
  SET_SOURCE_ID,
  SET_CART_ADDRESS_ITEMS,
  ADD_ARTICLE_VERSION_A,
  UPDATE_ARTICLE_VERSION_A,
  SET_COUPON,
  SET_ALLOW_CHANGE_ADDRESS_FOR_SOURCE,
} from '../mutationTypes';

const state = {
  cartSources: [],
  sourceId: null,
  cartAddresses: [],
  articlesVersionA: [],
  selectedCoupon: '',
  allowChangeAddressForSource: false,
};

const mutations = {
  [SET_CART_SOURCES](stateData, data) {
    stateData.cartSources = [...data];
  },
  [SET_SOURCE_ID](stateData, data) {
    stateData.sourceId = data;
  },
  [SET_CART_ADDRESS_ITEMS](stateData, data) {
    stateData.cartAddresses = [...data];
  },
  [ADD_ARTICLE_VERSION_A](stateData, data) {
    stateData.articlesVersionA = [
      ...stateData.articlesVersionA,
      data,
    ];
  },
  [UPDATE_ARTICLE_VERSION_A](stateData, data) {
    stateData.articlesVersionA = [...data];
  },
  [SET_COUPON](stateData, data) {
    stateData.selectedCoupon = data;
  },
  [SET_ALLOW_CHANGE_ADDRESS_FOR_SOURCE](stateData, data) {
    stateData.allowChangeAddressForSource = data;
  },
};

const actions = {
  async getCartItems({ commit }) {
    const response = await CartService.getCart();

    if (response.status === networkStatus['ok']) {
      commit(SET_CART_SOURCES, response.data.cartSources);
      // commit(SET_SOURCE_ID, response.data.addresses[0].sourceId);
    }

    return response;
  },
  async addCartItem({_}, { id, quantity, context = null }) {
    const response = await CartService.addToCart({
      id,
      quantity,
      context,
    });

    if (response.data.status === false) {
      return Promise.reject();
    }

    return response;
  },
  async editCartItemQuantityById({ dispatch }, { id, quantity }) {
    await CartService.editCartQuantiyById({
      id,
      quantity,
    });

    return dispatch('getCartItems');
  },
  async deleteCartPosition({ commit, dispatch }, id) {
    const response = await CartService.deleteCartPosition(id);
    if (response.status === networkStatus['ok']) {
      return dispatch('getCartItems');
    }
    return response;
  },
  async clearCart({ commit }) {
    const response = await CartService.clearCart();
    if (response.status === networkStatus['ok']) {
      commit(SET_CART_SOURCES, []);
    }
    return response;
  },
  async getAddress({ commit }) {
    const response = await CartService.getAddress();

    if (response.status === networkStatus['ok']) {
      commit(SET_CART_ADDRESS_ITEMS, response.data);
    }

    return response;
  },
  async addAddress({ _ }, { addressId, name, street, streetNumber, zip, country, countryCode, city }) {
    const response = await CartService.addAddress({
      addressId,
      name,
      street,
      streetNumber,
      zip,
      country,
      countryCode,
      city,
    });

    return response;
  },
  async checkAllowChangeAddressForSource({ commit }, { sourceId }) {
    let response;
    try {
      response = await CartService.checkAllowChangeAddressForSource({ sourceId });
      if (response.status === 204) {
        commit(SET_ALLOW_CHANGE_ADDRESS_FOR_SOURCE, true);
      } else {
        commit(SET_ALLOW_CHANGE_ADDRESS_FOR_SOURCE, false);
      }
    } catch (err) {
      commit(SET_ALLOW_CHANGE_ADDRESS_FOR_SOURCE, false);
    }
    return response;
  },
  async changeAddress({_}, { sourceId, addressId }) {
    const response = await CartService.changeAddress({ sourceId, addressId });
    return response;
  },
  async orderCart({ dispatch }) {
    const response = await CartService.order();
    if (response.status === networkStatus['ok'] && response.data.status === false) {
      return Promise.reject(response.data);
    }

    if (response.status === networkStatus['ok']) {
      return dispatch('getCartItems');
    }

    return response;
  },
  addCartArticleVersionA({ commit }, payload) {
    commit(ADD_ARTICLE_VERSION_A, payload);
    const articleVersionAData = JSON.parse(sessionStorage.getItem('articlesVersionA')) || [];
    sessionStorage.setItem('articlesVersionA', JSON.stringify([
      ...articleVersionAData,
      payload,
    ]));
  },
  updateCartArticleVersionA({ commit }, payload) {
    commit(UPDATE_ARTICLE_VERSION_A, payload);
    sessionStorage.setItem('articlesVersionA', JSON.stringify(payload));
  },
  async clearArticleVersionA({ commit, rootState }) {
    const userId = rootState.user.userInformation.id;
    const articleVersionAData = JSON.parse(sessionStorage.getItem('articlesVersionA')) || [];
    const payload = articleVersionAData.filter((item) => item.userId !== userId);

    commit(UPDATE_ARTICLE_VERSION_A, payload);
    sessionStorage.setItem('articlesVersionA', JSON.stringify(payload));
  },
  async addCouponToCart({ commit }, payload) {
    const response = await CartService.addCouponToCart(payload);

    return response;
  },
  setCoupon({ commit }, payload) {
    commit(SET_COUPON, payload);
  },
};

const getters = {
  getCartItems: (stateData, getters, rootState) => {
    const userId = rootState.user.userInformation.id;
    let results = [];
    if (stateData.cartSources.length === 0) {
      results = [];
    }
    stateData.cartSources.forEach((item) => {
      results = [
        ...results,
        ...item.cartArticles,
      ];
    });
    const articleVersionADataSession = JSON.parse(sessionStorage.getItem('articlesVersionA')) || [];
    const articleVersionAData = stateData.articlesVersionA.length < articleVersionADataSession.length
      ? articleVersionADataSession
      : stateData.articlesVersionA;
    const articleVersionADataUser = articleVersionAData.filter((item) => item.userId === userId);
    if (articleVersionADataUser) {
      results = [
        ...results,
        ...articleVersionADataUser,
      ].sort((a,b) => a.id.toString().localeCompare(b.id.toString()));
    }
    return results;
  },
  getOnlyCartShipmentArticles: (stateData, getters) => {
    if (stateData.cartSources.length === 0) {
      return [];
    }
    const filteredCartShipments = stateData.cartSources.slice()
      .filter((filtered) => filtered.shipment)
      .map((item) => {
        return {
          key: `${item.shipment.type}--${item.shipment.description}`,
          ...item.shipment,
        };
      });
    return filteredCartShipments;
  },
  getAddressOptions: (stateData) => {
    if (stateData.cartAddresses.length === 0) {
      return [];
    }
    return stateData.cartAddresses.slice().map((item) => {
      return {
        ...item,
        name: item.name,
        address: `${item.street}, ${item.zip} ${item.city}, ${item.countryCode}`,
      };
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};