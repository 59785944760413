<template>
  <label
    v-if="checkBoxButton"
    :class="[
      'base-checkbox',
      reverse && 'reverse',
      checkBoxButton && 'check-box-button',
      value && 'is-checked',
      disabled && 'disabled'
    ]"
  >
    <input
      type="checkbox"
      :checked="value"
      @change="onCheckboxSelect"
    >
    <div class="v-btn__content">
      <slot name="icon" />
    </div>
  </label>
  <label
    v-else
    :class="[
      'base-checkbox',
      reverse && 'reverse',
      disabled && 'disabled'
    ]"
  >
    <input
      v-if="isRadio"
      v-model="currentSelect"
      type="radio"
      :value="selectValue"
      :name="name"
      @click="onRadioSelect"
    >
    <input
      v-else
      type="checkbox"
      :checked="value"
      :disabled="disabled"
      @change="onCheckboxSelect"
    >
    <slot
      v-if="$scopedSlots['custom-text']"
      name="custom-text"
    />
    <span
      v-else
      class="text"
    >
      {{ labelText }}
    </span>

    <div class="icon-checkbox-container">
      <icon-checkbox
        class="icon-checkbox"
      />
    </div>
  </label>
</template>

<style lang="scss">
@import "@/styles/components/base-checkbox.scss";
</style>

<script>
import IconCheckbox from '@/components/icons/IconCheckbox';

export default {
  components: {
    IconCheckbox,
  },
  props: {
    selectValue: {
      type: [String, Boolean, Array],
      default: '',
    },
    value: {
      type: [String, Boolean, Array],
      default: '',
    },
    labelText: {
      type: [String, Number],
      default: '',
    },
    reverse: {
      type: Boolean,
      default: false,
    },
    isRadio: {
      type: Boolean,
      default: false,
    },
    checkBoxButton: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentSelect: '',
    };
  },
  watch: {
    value(val) {
      this.currentSelect = val;
    },
  },
  created() {
    this.currentSelect = this.value;
  },
  methods: {
    onRadioSelect(e) {
      if (this.currentSelect === this.selectValue) {
        return;
      }
      if (this.currentSelect === this.selectValue) {
        this.currentSelect = '';
        this.$emit('input', '');
      } else {
        this.currentSelect = e.target.value;
        this.$emit('input', e.target.value);
      }
    },
    onCheckboxSelect(event) {
      this.$emit('input', event.target.checked);
    },
  },
};
</script>