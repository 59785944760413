<template>
  <v-dialog
    v-model="showForgotPassword"
    max-width="660"
    persistent
  >
    <v-card class="dialog base-reset-initial-password-dialog">
      <v-card-title>
        <span>{{ $t('langkey.forgot-password') }}</span>
        <v-btn
          icon
          plain
          class="button__close"
          @click="hide"
        >
          <icon-close size="12" />
        </v-btn>
      </v-card-title>
      <v-card-text>
        <p v-html="$t('langkey.passwort-reset-text')" />
        <hr>
        <v-form
          class="reset-password__form"
          name="forgot-password-form"
          @submit.prevent="onResetPassword"
        >
          <button
            v-show="false"
            type="submit"
          />
          <v-row align="center">
            <v-col cols="6">
              <p>{{ $t('langkey.your-email') }}:</p>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="email"
                :label="$t('langkey.your-email')"
                :class="[
                  errorTexts.length > 0 ? 'show-error' : null
                ]"
                name="forgot-password-form-email-input"
                solo
                hide-details
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <transition name="fade">
          <div
            v-if="errorTexts.length > 0"
            class="error-texts"
          >
            <p
              v-for="(error, index) in errorTexts"
              :key="`errorText${index}`"
            >
              {{ error.text }}
            </p>
          </div>
        </transition>
        <v-spacer />
        <v-btn
          color="primary"
          class="icon"
          @click="onResetPassword"
        >
          {{ $t('langkey.send-email') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss">
@import '@/styles/components/base-reset-initial-password-dialog.scss';
</style>

<script>
import IconClose from '@/components/icons/IconClose';

export default {
  components: {
    IconClose,
  },
  data() {
    return {
      showForgotPassword: false,
      email: '',
      errorTexts: [],
    };
  },
  methods: {
    validateInput() {
      if (!(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.email))) {
        this.errorTexts = [
          {
            key: 'email',
            text: this.$t('langkey.please-input-email'),
          },
        ];
      } else {
        this.errorTexts = [];
      }
    },
    async onResetPassword() {
      this.validateInput();
      if (this.errorTexts.length > 0) {
        return;
      }

      this.$store.dispatch('dialog/setLoadingDialog', {
        status: true,
      });

      this.$store.dispatch('user/passwordRecovery', {
        mail: this.email,
      })
        .then((res) => {
          if (res.status === 204) {
            this.$store.dispatch('dialog/setLoadingDialog', {
              status: false,
            });
            this.$emit('on-email-sent');
            this.hide();
          }
        })
        .catch((err) => {
          if (err.response.status === 412) {
            this.$store.dispatch('dialog/setShowErrorDialog', {
              status: true,
              title: this.$t('langkey.user-not-found'),
              message: this.$t('langkey.user-not-found-message'),
            })
              .then(() => {
                this.$store.dispatch('dialog/setLoadingDialog', {
                  status: false,
                });
              });
          }
        });
    },
    show() {
      this.showForgotPassword = true;
    },
    hide() {
      this.errorTexts = [];
      this.showForgotPassword = false;
    },
  },
};
</script>
