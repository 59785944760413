<template>
  <v-dialog
    v-model="showEmailConfirm"
    max-width="660"
    persistent
  >
    <v-card class="dialog base-email-address-dialog">
      <v-card-title>
        <span>{{ $t('langkey.enter-mail-address') }}</span>
      </v-card-title>
      <v-card-text>
        <div class="welcome">
          <p v-html="$t('langkey.welcome-to-tnp')" />
          <p>
            <span v-html="$t('langkey.email-confirm-verify-text')" />
          </p>
        </div>
        <p class="more-info">
          <i v-html="$t('langkey.email-confirm-more-info')" />
        </p>
        <hr>
        <v-form class="confirm-email__form">
          <v-row align="center">
            <v-col cols="4">
              <p>{{ $t('langkey.email') }}:</p>
            </v-col>
            <v-col cols="8">
              <v-text-field
                v-model="email"
                :class="[
                  'input__email',
                  isErrorEmailInput ? 'show-error' : null
                ]"
                :label="`${$t('langkey.email')}`"
                solo
                hide-details
              />
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="4">
              <p>{{ $t('langkey.confirm-email') }}:</p>
            </v-col>
            <v-col cols="8">
              <v-text-field
                v-model="confirmEmail"
                :class="[
                  'input__confirm-email',
                  isErrorConfirmEmailInput ? 'show-error' : null
                ]"
                :label="`${$t('langkey.confirm-email')}`"
                solo
                hide-details
              />
            </v-col>
          </v-row>
        </v-form>

        <v-row>
          <v-col cols="4" />
          <v-col cols="8">
            <base-checkbox
              v-model="isAcceptTerm"
              :class="[
                isErrorAcceptTerm ? 'show-error' : null
              ]"
              reverse
            >
              <template #custom-text>
                <span
                  class="checkbox-text"
                  v-html="$t('langkey.email-confirm-condition')"
                />
              </template>
            </base-checkbox>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <transition name="fade">
          <div
            v-if="errorTexts.length > 0"
            class="error-texts"
          >
            <p
              v-for="(error, index) in errorTexts"
              :key="`errorText${index}`"
            >
              <span v-html="error.text" />
            </p>
          </div>
        </transition>
        <v-spacer />
        <v-btn
          color="primary"
          class="icon"
          @click="confirmUserEmail"
        >
          {{ $t('langkey.submit') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss">
@import '@/styles/components/base-email-address-dialog.scss';
</style>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      email: '',
      confirmEmail: '',
      isAcceptTerm: false,
      errorTexts: [],
    };
  },
  computed: {
    ...mapState('user', ['showEmailConfirm']),
    isEmailAndConfirmEmailEmpty() {
      return this.email.length === 0 && this.confirmEmail.length === 0;
    },
    isEmailAndConfirmEmailNotSame() {
      return (this.email.length > 0 || this.confirmEmail.length > 0)
        && this.email !== this.confirmEmail;
    },
    isErrorEmailInput() {
      if (this.errorTexts.length === 0) {
        return false;
      }
      return this.errorTexts.find((item) => item.key === 'email');
    },
    isErrorConfirmEmailInput() {
      if (this.errorTexts.length === 0) {
        return false;
      }
      return this.errorTexts.find((item) => item.key === 'confirmEmail');
    },
    isErrorAcceptTerm() {
      if (this.errorTexts.length === 0) {
        return false;
      }
      return this.errorTexts.find((item) => item.key === 'isAcceptTerm');
    },
  },
  methods: {
    validateInput() {
      this.errorTexts = [];
      if (this.isEmailAndConfirmEmailEmpty && !this.isAcceptTerm) {
        this.errorTexts = [
          {
            key: 'email',
            text: this.$t('langkey.please-input-email'),
          },
          {
            key: 'confirmEmail',
            text: this.$t('langkey.please-input-confirm-email'),
          },
          {
            key: 'isAcceptTerm',
            text: '',
          },
        ];
        return;
      }

      if (this.isEmailAndConfirmEmailEmpty) {
        this.errorTexts = [
          {
            key: 'email',
            text: this.$t('langkey.please-input-email'),
          },
          {
            key: 'confirmEmail',
            text: this.$t('langkey.please-input-confirm-email'),
          },
        ];
        return;
      }

      if (this.isEmailAndConfirmEmailNotSame  && !this.isAcceptTerm) {
        this.errorTexts = [
          {
            key: 'email',
            text: this.$t('langkey.email-not-same'),
          },
          {
            key: 'confirmEmail',
            text: '',
          },
          {
            key: 'isAcceptTerm',
            text: '',
          },
        ];
        return;
      }

      if (this.isEmailAndConfirmEmailNotSame) {
        this.errorTexts = [
          {
            key: 'email',
            text: this.$t('langkey.email-not-same'),
          },
          {
            key: 'confirmEmail',
            text: '',
          },
        ];
        return;
      }

      if (!this.isAcceptTerm) {
        this.errorTexts = [
          {
            key: 'isAcceptTerm',
            text: this.$t('langkey.please-accept-condition'),
          },
        ];
        return;
      }
    },
    async confirmUserEmail() {
      this.validateInput();
      if (this.errorTexts.length > 0) {
        return;
      }

      this.$store.dispatch('dialog/setLoadingDialog', {
        status: true,
      });
      try {
        await this.$store.dispatch('user/confirmEmail', { email: this.email });
      } catch (err) {
        if (err.response.status === 400) {
          this.$store.dispatch('dialog/setShowErrorDialog', {
            status: true,
            title: this.$t('langkey.mail-already-existent-subject'),
            message: this.$t('langkey.error-mail-already-existent'),
          });
        } else {
          this.$store.dispatch('dialog/setShowErrorDialog', {
            status: true,
            title: this.$t('langkey.confirm-mail-error'),
            message: err,
          });
        }
      } finally {
        this.$store.dispatch('dialog/setLoadingDialog', {
          status: false,
        });
      }
    },
  },
};
</script>
