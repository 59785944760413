import {
  UPDATE_RIMCONFIG_BREADCRUMB,
} from '../mutationTypes';

const state = {
  breadcrumbItems: [],
};

const mutations = {
  [UPDATE_RIMCONFIG_BREADCRUMB](stateData, data) {
    stateData.breadcrumbItems = [
      ...data,
    ];
  },
};

const actions = {
  async updateBreadcrumbs({ commit }, data) {
    commit(UPDATE_RIMCONFIG_BREADCRUMB, data);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
