import { DirectDebitService } from '@/services';
import { networkStatus } from '@/constants/networkStatus';
import {
  SET_DIRECT_DEBIT,
} from '../mutationTypes';

const state = {
  directDebitData: {},
};

const mutations = {
  [SET_DIRECT_DEBIT](stateData, data) {
    stateData.directDebitData = data;
  },
};

const actions = {
  async loadDirectDebit({ commit }, payload = {}) {
    const response = await DirectDebitService.loadDirectDebit();

    commit(SET_DIRECT_DEBIT, response.data);

    return response;
  },
  async getDirectDebitPdf({ commit }, payload = {}) {
    const response = await DirectDebitService.getDirectDebitPdf();

    return response;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};