<template>
  <v-menu
    v-model="isShowMenu"
    offset-y
    :close-on-content-click="!isDisableSelection"
    :disabled="isDisable"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        depressed
        plain
        v-bind="attrs"
        :class="[
          'base-dropdown currency-dropdown',
          isShowMenu && 'show-menu'
        ]"
        :disabled="isDisable"
        v-on="on"
      >
        <span class="mr-1">€</span>
        <div
          :class="[
            'icon-caret',
            isShowMenu && 'open'
          ]"
        >
          <icon-caret />
        </div>
      </v-btn>
    </template>
    <v-list class="currency-list">
      <v-list-item
        v-for="(type, index) in priceTypeItems"
        :key="`${type.title}${index}`"
        :class="[
          'currency-item',
          isDisableSelection && type.value !== priceType && 'disabled'
        ]"
        @click="selectPriceType(type.value)"
      >
        <v-list-item-title>
          <span
            :class="priceType === type.value ? 'active' : ''"
          >
            {{ $t(type.title) }}
          </span>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<style lang="scss">
@import '@/styles/components/base-dropdown.scss';
@import '@/styles/components/currency-dropdown.scss';
</style>

<script>
import IconCaret from '@/components/icons/IconCaret';
import { mapState } from 'vuex';
import { priceTypeItems } from '@/constants/priceTypes';

export default {
  components: {
    IconCaret,
  },
  props: {
    isDisable: {
      type: Boolean,
      default: false,
    },
    isDisableSelection: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isShowMenu: false,
      priceTypeItems: priceTypeItems,
      currentIndex: 0,
    };
  },
  computed: {
    ...mapState('pricing', {
      priceType: 'priceType',
      isOptIn: 'isOptIn',
    }),
  },
  mounted() {
    if (!this.priceType) {
      if (!this.isOptIn) {
        this.$store.dispatch('pricing/setPriceType', 'purchasePrice');
      } else {
        this.$store.dispatch('pricing/setPriceType', 'retailPrice');
      }
    }
  },
  methods: {
    selectPriceType(priceType) {
      if (!this.isDisableSelection) {
        this.$store.dispatch('pricing/setPriceType', priceType);
      } else {
        this.isShowMenu = false;
      }
    },
  },
};
</script>