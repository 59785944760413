<template>
  <v-dialog
    v-model="showDialog"
    max-width="700px"
  >
    <v-card class="pa-10">
      <h3
        class="mb-8 text-center"
        v-html="$t('langkey.select-matchcode-tyre-vehicle')"
      />
      <div class="d-flex justify-space-around align-center">
        <navigation-tab
          v-for="(tab, index) in tabs"
          :key="`select-matchcode-tab${index}`"
          :item="menu[tab]"
          :search-text="searchText"
          is-show-text
          is-search
          is-select
          class="select select-search-tyre-navigation-tab"
          @click="onSelectTab(tab)"
          @on-select="onSelectTab(tab)"
        />
      </div>
    </v-card>
  </v-dialog>
</template>

<style lang="scss">
@import "@/styles/components/rimconfig-wheel-info__dialog.scss";
</style>

<script>
import NavigationTab from '@/components/NavigationTab';

export default {
  components: {
    NavigationTab,
  },
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
    searchText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showDialog: false,
      menu: {
        car: {
          to: 'tyre-car',
          class: 'tyre-car',
          isGroup: true,
          children: [
            {
              title: 'langkey.car',
              icon: 'car',
            },
            {
              title: 'langkey.suv',
              icon: 'suv',
            },
            {
              title: 'langkey.van',
              icon: 'van',
            },
          ],
        },
        truck: {
          title: 'langkey.truck',
          icon: 'truck',
          to: 'tyre-truck',
        },
        bike: {
          title: 'langkey.motorbike',
          icon: 'bike',
          to: 'tyre-motorbike',
        },
        industrial: {
          title: 'langkey.industrial',
          icon: 'industrial',
          to: 'tyre-industrial',
        },
        agriculture: {
          title: 'langkey.agriculture',
          icon: 'agriculture',
          to: 'tyre-agriculture',
        },
        earthMover: {
          title: 'langkey.earthMover',
          icon: 'earth-mover',
          to: 'tyre-earthMover',
        },
      },
    };
  },
  watch: {
    $route() {
      this.showDialog = false;
    },
  },
  methods: {
    onShowDialog() {
      this.showDialog = true;
    },
    onSelectTab(tab) {
      this.$emit('on-select-tab', tab);
      this.showDialog = false;
    },
  },
};
</script>