<template>
  <v-footer
    height="64"
    :fixed="!notFixedRoute"
  >
    <v-btn
      v-for="(item, index) in footerMenu"
      :key="`${item.title}${index}`"
      plain
      @click="goTo(item.to)"
    >
      {{ $t(item.title) }}
    </v-btn>
  </v-footer>
</template>

<style lang="scss">
@import '@/styles/components/footer-menu.scss';
</style>

<script>
export default {
  data() {
    return {
      footerMenu: [
        {
          title: 'langkey.imprint',
          to: 'impressum',
        },
        {
          title: 'langkey.terms-of-use',
          to: 'nutzungsbedingungen',
        },
        {
          title: 'langkey.privacy-policy',
          to: 'privacy',
        },
        {
          title: 'langkey.downloads',
          to: 'downloads',
        },
        {
          title: 'langkey.cookies-usage',
          to: 'cookie-policy',
        },
      ],
    };
  },
  computed: {
    notFixedRoute() {
      return this.$route.name === 'rims-config-configurator_carTag';
    },
  },
  methods: {
    goTo(toName) {
      this.$router.push(this.$i18nRoute({
        name: toName,
      }));
    },
  },
};
</script>
