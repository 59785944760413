<template>
  <svg
    :height="size"
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.364 5.046L5.68201 9.72798L1.00002 5.046"
      :stroke="color"
      stroke-width="1.5"
    />
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: '11',
    },
    color: {
      type: String,
      default: '#8B8B8B',
    },
  },
};
</script>