<template>
  <svg
    :height="size"
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="5.84912"
      cy="5"
      r="4"
      class="svg-icon-stroke"
      :stroke="color"
      stroke-width="2"
    />
    <line
      x1="8.55623"
      y1="8.29289"
      x2="13.5562"
      y2="13.2929"
      class="svg-icon-stroke"
      :stroke="color"
      stroke-width="2"
    />
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: '14',
    },
    color: {
      type: String,
      default: '#B9BABB',
    },
  },
};
</script>