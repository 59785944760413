<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12.778"
    height="15.667"
    viewBox="0 0 12.778 15.667"
  >
    <path
      id="Icon_awesome-angle-double-down"
      data-name="Icon awesome-angle-double-down"
      d="M5.26,11.333l-5-5a.879.879,0,0,1,0-1.247l.832-.832a.879.879,0,0,1,1.247,0L5.882,7.8,9.429,4.25a.879.879,0,0,1,1.247,0l.839.828a.879.879,0,0,1,0,1.247l-5,5A.881.881,0,0,1,5.26,11.333ZM6.511,18.4l5-5a.879.879,0,0,0,0-1.247l-.832-.832a.879.879,0,0,0-1.247,0L5.886,14.858,2.339,11.311a.879.879,0,0,0-1.247,0l-.835.832a.879.879,0,0,0,0,1.247l5,5A.881.881,0,0,0,6.511,18.4Z"
      transform="translate(0.504 -3.49)"
      class="svg-icon-fill"
      :fill="solid ? '#fff' : 'black'"
      :stroke="solid ? 'null' : '#fff'"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1"
    />
  </svg>
</template>

<script>
export default {
  props: {
    solid: {
      type: Boolean,
      default: false,
    },
  },
};
</script>