import { apiClient } from '@/services';

export const RegisterService = {
  customerRegisterOrUpgrade(action, {
    businessName,
    businessType,
    address,
    addressSuffix = '',
    zipCode,
    city,
    country,
    operationMode = '',
    employees = '',
    taxIdentificationNumber,
    taxId,
    tradeRegisterNumber = '',
    bankName,
    bankAccountHolder,
    bankBic,
    bankIban,
    bankSepaAuthorized,
    type,
    website,
    contactPersons,
    sepaGlobalApproval,
    marketingApproved,
  }) {
    let data = {
      businessName,
      businessType: businessType || 'type',
      address,
      addressSuffix,
      zipCode,
      city,
      country,
      operationMode,
      employees,
      taxIdentificationNumber,
      taxId,
      tradeRegisterNumber,
      bankName,
      bankAccountHolder,
      bankBic,
      bankIban,
      bankSepaAuthorized,
      type,
      website,
      contactPersons,
      sepaGlobalApproval,
      marketingApproved,
    };

    if (action === 'upgrade') {
      return apiClient.put('/customer/', data);
    }

    return apiClient.post('/customer/', data);
  },
  getCustomerInformation() {
    return apiClient.get('/customer/');
  },
};
