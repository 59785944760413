import { apiClient } from '@/services';

export const OrderService = {
  getOrder(params) {
    return apiClient.get('/order/', {
      params: {
        ...params,
      },
    });
  },
  getOrderStatus(params) {
    return apiClient.get('/order/status');
  },
  getPdf(id) {
    return apiClient.get(`/order/invoice_pdf/${id}`, {
      responseType: 'blob',
    });
  },
  orderMail(payload) {
    return apiClient.put('/order/mail', payload);
  },
};
