<template>
  <div>
    <navigation-landing />
    <v-main>
      <slot />
      <footer-menu />
    </v-main>
  </div>
</template>

<script>
import NavigationLanding from '@/components/NavigationLanding';
import FooterMenu from '@/components/FooterMenu';

export default {
  components: {
    NavigationLanding,
    FooterMenu,
  },
};
</script>