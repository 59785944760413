import { UserService } from '@/services';
import { networkStatus } from '@/constants/networkStatus';

import {
  SHOW_EMAIL_CONFIRM_DIALOG,
  SHOW_RESET_INITIAL_PASSWORD_DIALOG,
  SET_USER_INFORMATION,
  SET_USER_ADDRESSES,
  UPDATE_USER_ADDRESS,
  DELETE_USER_ADDRESS,
  SET_USER_TYPE,
  SET_USER_ALLOW_ADDRESS,
} from '../mutationTypes';

const state = {
  showEmailConfirm: false,
  showResetInitialPassword: false,
  userInformation: null,
  userAddresses: [],
  userType: '',
  isAllowAddressUser: null,
};

const mutations = {
  [SHOW_EMAIL_CONFIRM_DIALOG](stateData, data) {
    stateData.showEmailConfirm = data;
  },
  [SHOW_RESET_INITIAL_PASSWORD_DIALOG](stateData, data) {
    stateData.showResetInitialPassword = data;
  },
  [SET_USER_INFORMATION](stateData, data) {
    stateData.userInformation = {...data};
  },
  [SET_USER_ADDRESSES](stateData, data) {
    stateData.userAddresses = [...data];
  },
  [SET_USER_ALLOW_ADDRESS](stateData, data) {
    stateData.isAllowAddressUser = data;
  },
  [UPDATE_USER_ADDRESS](stateData, { id, data }) {
    const stateAddresses = stateData.userAddresses.slice();
    stateData.userAddresses = stateAddresses.map((item) => {
      if (item.id === id) {
        return {
          ...data,
          id: item.id,
        };
      } else {
        return {
          ...item,
        };
      }
    });
  },
  [DELETE_USER_ADDRESS](stateData, id) {
    const stateAddresses = stateData.userAddresses.slice();
    stateData.userAddresses = stateAddresses.filter((item) => item.id !== id);
  },
  [SET_USER_TYPE](stateData, data) {
    stateData.userType = data;
  },
};

const actions = {
  async checkConfirmEmail({ commit }) {
    const response = await UserService.checkChangePasswordStatus();
    if (response.status === 422) {
      commit(SHOW_EMAIL_CONFIRM_DIALOG, true);
    } else {
      sessionStorage.setItem('mailpwpopupopen', true);
    }
    return response;
  },
  async checkChangePassword({ commit }) {
    const response = await UserService.checkChangePasswordStatus();
    if (response.status === 422) {
      commit(SHOW_RESET_INITIAL_PASSWORD_DIALOG, true);
    }
    return response;
  },
  async confirmEmail({ commit }, { email }) {
    const response = await UserService.confirmEmail({ email });
    if (response.status === networkStatus.ok) {
      localStorage.setItem('tnpconfirmemail', email);
      commit(SHOW_EMAIL_CONFIRM_DIALOG, false);

      setTimeout(() => {
        commit(SHOW_RESET_INITIAL_PASSWORD_DIALOG, true);
      }, 200);
    }
    return response;
  },
  async confirmPasswordChange({ commit }, { password_current, password_new, token }) {
    if (token) {
      const response = await UserService.confirmPasswordChangeWithToken({
        password_current,
        password_new,
        token,
      });
      if (response.status === networkStatus.ok) {
        localStorage.removeItem('tnpconfirmemail');
        sessionStorage.setItem('mailpwpopupopen', true);
        commit(SHOW_RESET_INITIAL_PASSWORD_DIALOG, false);
      }
      return response;
    }

    const response = await UserService.confirmPasswordChange({
      password_current,
      password_new,
    });
    if (response.status === networkStatus.ok) {
      localStorage.removeItem('tnpconfirmemail');
      sessionStorage.setItem('mailpwpopupopen', true);
      commit(SHOW_RESET_INITIAL_PASSWORD_DIALOG, false);
    }
    return response;
  },
  closeConfirmEmailDialog({ commit }) {
    commit(SHOW_EMAIL_CONFIRM_DIALOG, false);
  },
  showResetPasswordDialog({ commit }) {
    commit(SHOW_RESET_INITIAL_PASSWORD_DIALOG, true);
  },
  closeResetPasswordDialog({ commit }) {
    commit(SHOW_RESET_INITIAL_PASSWORD_DIALOG, false);
  },
  async passwordRecovery({ commit }, payload) {
    const response = await UserService.passwordRecovery(payload);

    return response;
  },
  async checkPasswordRecoveryToken({ commit }, payload) {
    const response = await UserService.checkPasswordRecoveryToken(payload);

    return response;
  },
  async confirmPasswordRecovery({ commit }, payload) {
    const response = await UserService.confirmPasswordRecovery(payload);

    return response;
  },
  async getUserInformation({ commit }) {
    const response = await UserService.getUserInformation();
    if (response.status === networkStatus.ok) {
      commit(SET_USER_INFORMATION, response.data);
      sessionStorage.setItem('userInformation', JSON.stringify(response.data));
    }
    return response;
  },
  async clearUserInformation({ commit }) {
    commit(SET_USER_INFORMATION, null);
    sessionStorage.removeItem('userInformation');
  },
  async getUserAdresses({ commit }) {
    const response = await UserService.getUserAddresses();
    if (response.status === networkStatus.ok) {
      commit(SET_USER_ADDRESSES, response.data);
    }
    return response;
  },
  async addUserAddress({ dispatch }, data) {
    const response = await UserService.addUserAddresses(data);
    if (response.status !== networkStatus.ok &&
      response.status !== networkStatus.no_content) {
      return Promise.reject();
    }
    return dispatch('getUserAdresses');
  },
  async editUserAddress({ commit }, data) {
    const response = await UserService.updateUserAddresses(data);
    if (response.status === networkStatus.ok ||
      response.status === networkStatus.no_content) {
      commit(UPDATE_USER_ADDRESS, {
        id: data.id,
        data: data,
      });
    }
    return response;
  },
  async deleteUserAddress({ commit }, id) {
    const response = await UserService.deleteUserAddress(id);
    if (response.status === networkStatus.ok ||
      response.status === networkStatus.no_content) {
      commit(DELETE_USER_ADDRESS, id);
    }
    return response;
  },
  async deleteAllUserAddress({ commit }) {
    const response = await UserService.deleteAllAddress();
    if (response.status === networkStatus.ok ||
      response.status === networkStatus.no_content) {
      commit(SET_USER_ADDRESSES, []);
    }
    return response;
  },
  async checkUserType({ commit }) {
    let response;
    try {
      response = await UserService.checkUserType();
      if (response.status === 204) {
        commit(SET_USER_TYPE, 'TEST USER');
        sessionStorage.setItem('userType', 'TEST USER');
      }
    } catch (err) {
      if (err.response.status === 422) {
        commit(SET_USER_TYPE, 'NORMAL USER');
        sessionStorage.setItem('userType', 'NORMAL USER');
      }
    }

    return response;
  },

  async checkAllowAddress({ commit }) {
    let response;
    try {
      response = await UserService.checkAllowAddress();
      if (response.status === 204) {
        commit(SET_USER_ALLOW_ADDRESS, true);
      } else {
        commit(SET_USER_ALLOW_ADDRESS, false);
      }
    } catch (err) {
      commit(SET_USER_ALLOW_ADDRESS, false);
    }
    return response;
  },
};

const getters = {
  getUserAddressOptions: (stateData) => {
    if (stateData.userAddresses.length === 0) {
      return [];
    }
    return stateData.userAddresses.slice().map((item) => {
      return {
        label: `${item.name} ${item.street}, ${item.zip} ${item.city}, ${item.countryCode}`,
        value: `${item.name} ${item.street}, ${item.zip} ${item.city}, ${item.countryCode}`,
      };
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
