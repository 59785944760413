<template>
  <base-rimconfig-card-car-selector
    class="rimconfig-card-car-selector-manufacturer"
    :title="$t('langkey.rim-config-search-by')"
  >
    <template #custom-header-content>
      <ul
        v-if="selectedManufacturer"
        class="card-breadcrumb"
      >
        <li
          v-for="(item, index) in manufacturerBreadcrumbItems"
          :key="`${item.key}${index}`"
          class="card-breadcrumb__item"
        >
          {{ $t(item.label) }}
        </li>
      </ul>

      <div class="top-manufacturer__wrapper">
        <ul class="top-manufacturer__list">
          <li
            v-for="(brand, index) in topManufacturers"
            :key="index"
            class="top-manufacturer__item"
          >
            <a @click="onClickManufacturerItem(brand)">
              <img
                :src="`https://cdn.jfnet.de/carLogos/${brand.replace(/\s/g, '')}/100x100-cc-xxxxxx.png`"
              >
            </a>
          </li>
        </ul>
      </div>

      <div class="button-wrapper">
        <button
          :class="['button-menu first', !searchByKbaNumber && !searchByEanMan && 'selected']"
          @click="setSearchByKbaNumber(false)"
        >
          {{ $t('langkey.rim-config-configurator') }}
        </button>
        <button
          :class="['button-menu', searchByKbaNumber && 'selected']"
          @click="setSearchByKbaNumber(true)"
        >
          {{ $t('langkey.rim-config-kba-number') }}
        </button>
        <v-btn
          icon
          plain
          style="position: relative;top: -1px;"
          @click="onClickKbaInfo"
        >
          <icon-info color="red" />
        </v-btn>
        <button
          v-if="isTpms"
          :class="['button-menu', searchByEanMan && 'selected']"
          class="last"
          @click="setSearchByEanMan(true)"
        >
          {{ $t('langkey.tpms-search-ean-man') }}
        </button>
      </div>
    </template>

    <template #body>
      <div class="car-selector-manufacturer__container">
        <div class="select-manufacturer__section">
          <div class="input-wrapper">
            <input
              v-model="searchManufacturers"
              type="search"
              class="input__search"
              :placeholder="$t('langkey.rim-config-search-placeholder')"
              @input="debounceSearch"
              @keydown.esc="clearSearch"
            >
            <v-btn
              v-if="searchManufacturers"
              icon
              plain
              class="button__input-clear"
              @click="clearSearch"
            >
              <icon-close />
            </v-btn>
            <span class="icon__search">
              <icon-search color="var(--theme-black)" />
            </span>
          </div>

          <div v-bar>
            <transition name="page">
              <div
                v-if="isLoading"
                class="loading__wrapper"
              >
                <div class="loader">
                  <v-progress-circular
                    indeterminate
                    color="accent"
                  />
                </div>
              </div>
              <div
                v-else
                class="select-manufacturer__wrapper"
              >
                <v-list flat>
                  <v-list-item
                    v-for="(option, index) in filteredManufacturers"
                    :key="`${option}${index}`"
                    :class="[
                      selectedManufacturer === option && 'selected'
                    ]"
                    @click="onClickManufacturerItem(option)"
                  >
                    <v-list-item-avatar
                      size="30"
                      tile
                    >
                      <v-img
                        :src="`https://cdn.jfnet.de/carLogos/${option.replace(/\s/g, '')}/60x60-cc-xxxxxx.png`"
                        :lazy-src="`https://cdn.jfnet.de/carLogos/${option.replace(/\s/g, '')}/60x60-cc-xxxxxx.png`"
                        contain
                      />
                    </v-list-item-avatar>
                    <v-list-item-title>{{ option }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </div>
            </transition>
          </div>
        </div>

        <div class="car-history">
          <v-btn
            depressed
            plain
            block
            class="car-history__button"
          >
            <a
              @click="openCarSearchHistoryDialog"
            >
              {{ $t('langkey.rim-config-car-history') }}
            </a>
          </v-btn>
        </div>
      </div>
    </template>
  </base-rimconfig-card-car-selector>
</template>

<style lang="scss">
@import '@/styles/components/rimconfig-card-car-selector-manufacturer.scss';
</style>

<script>
import { mapState } from 'vuex';
import debounce from 'lodash/debounce';
import { breadcrumbItems } from '@/pages/rims-config/configurator/configs.js';
import IconInfo from '@/components/icons/IconInfo';

export default {
  components: {
    IconInfo,
  },
  props: {
    searchByKbaNumber: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isTpms: {
      type: Boolean,
      default: false,
    },
    searchByEanMan: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchManufacturers: '',
      filteredManufacturers: [],
      topManufacturers: ['AUDI', 'BMW', 'FORD', 'HYUNDAI', 'MERCEDES-BENZ', 'OPEL', 'SKODA', 'VOLKSWAGEN'],
    };
  },
  computed: {
    ...mapState({
      manufacturersConfigurator: (state) => state.rimconfigCarSelector.manufacturers,
      selectedManufacturerConfigurator: (state) => state.rimconfigCarSelector.selectedManufacturer,
    }),
    ...mapState({
      manufacturersTpms: (state) => state.rimconfigCarSelectorTpms.manufacturers,
      selectedManufacturerTpms: (state) => state.rimconfigCarSelectorTpms.selectedManufacturer,
    }),
    manufacturers() {
      return this.isTpms ? this.manufacturersTpms : this.manufacturersConfigurator;
    },
    selectedManufacturer() {
      return this.isTpms ? this.selectedManufacturerTpms : this.selectedManufacturerConfigurator;
    },
    breadcrumbItems() {
      return breadcrumbItems;
    },
    manufacturerBreadcrumbItems() {
      return this.breadcrumbItems.slice(0,1);
    },
  },
  watch: {
    manufacturers(val) {
      this.filteredManufacturers = val;
    },
  },
  mounted() {
    this.filteredManufacturers = this.manufacturers;
  },
  methods: {
    onClickManufacturerItem(manufacturer) {
      this.$emit('on-click-manufacturer-item', manufacturer);
    },
    debounceSearch: debounce(function(e) {
      this.searchManufacturers = e.target.value;
      if (this.searchManufacturers.length) {
        this.filteredManufacturers = this.manufacturers.filter((item) => {
          return item.toLowerCase().includes(this.searchManufacturers.toLowerCase().trim());
        });
      } else {
        this.filteredManufacturers = this.manufacturers;
      }
    }, 400),
    clearSearch() {
      this.searchManufacturers = '';
      this.filteredManufacturers = this.manufacturers;
    },
    setSearchByKbaNumber(status) {
      this.$emit('set-search-by-kba-number', status);
    },
    onClickKbaInfo() {
      this.$emit('on-click-kba-info');
    },
    openCarSearchHistoryDialog() {
      this.$emit('open-car-search-history-dialog');
    },
    setSearchByEanMan(status) {
      this.$emit('set-search-by-ean-man', status);
    },
  },
};
</script>