<template>
  <v-dialog
    v-model="showResetInitialPassword"
    max-width="660"
    persistent
  >
    <v-card class="dialog base-reset-initial-password-dialog">
      <v-card-title>
        <span>{{ $t('langkey.password-change') }}</span>
        <v-btn
          icon
          plain
          class="button__close"
          @click="hide"
        >
          <icon-close size="12" />
        </v-btn>
      </v-card-title>
      <v-card-text>
        <p v-html="$t('langkey.password-change-title')" />
        <hr>
        <div class="password-policy">
          <ul>
            <li :class="errorAtLeast ? 'error-text' : null">
              {{ $t('langkey.password-policy-1') }}
            </li>
            <li :class="errorCase ? 'error-text' : null">
              {{ $t('langkey.password-policy-2') }}
            </li>
          </ul>
        </div>
        <v-form
          class="reset-initial-password__form"
          @submit.prevent="onResetPassword"
        >
          <button
            v-show="false"
            type="submit"
          />

          <v-row
            v-if="$route.name !== 'reset-password'"
            align="center"
          >
            <v-col cols="6">
              <p>{{ $t('langkey.old-password') }}:</p>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="oldPassword"
                :class="[
                  isErrorOldPasswordInput ? 'show-error' : null
                ]"
                :label="$t('langkey.old-password')"
                type="password"
                solo
                hide-details
              />
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="6">
              <p>{{ $t('langkey.new-password') }}:</p>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="password"
                :class="[
                  isErrorPasswordInput ? 'show-error' : null
                ]"
                :label="$t('langkey.new-password')"
                type="password"
                solo
                hide-details
              />
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="6">
              <p>{{ $t('langkey.new-password-confirm') }}:</p>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="confirmPassword"
                :class="[
                  isErrorConfirmPasswordInput ? 'show-error' : null
                ]"
                :label="$t('langkey.new-password-confirm')"
                type="password"
                solo
                hide-details
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <transition name="fade">
          <div
            v-if="errorTexts.length > 0"
            class="error-texts"
          >
            <p
              v-for="(error, index) in errorTexts"
              :key="`errorText${index}`"
            >
              <span v-html="$t(error.text)" />
            </p>
          </div>
        </transition>
        <v-spacer />
        <v-btn
          color="primary"
          class="icon"
          @click="onResetPassword"
        >
          {{ $t('langkey.password-change') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss">
@import '@/styles/components/base-reset-initial-password-dialog.scss';
</style>

<script>
import { mapState } from 'vuex';
import IconClose from '@/components/icons/IconClose';

export default {
  components: {
    IconClose,
  },
  data() {
    return {
      oldPassword: '',
      password: '',
      confirmPassword: '',
      checkPasswordCount: 0,
      errorTexts: [],
      errorAtLeast: false,
      errorCase: false,
    };
  },
  computed: {
    ...mapState('user', ['showResetInitialPassword']),
    hasAtLeastEightCharacters() {
      return this.password.length >= 8;
    },
    hasUppercase() {
      return /[A-Z]/.test(this.password);
    },
    hasLowerCase() {
      return /[a-z]/.test(this.password);
    },
    hasNumeric() {
      return /[0-9]/.test(this.password);
    },
    hasSpecialCharacter() {
      // eslint-disable-next-line
      return /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(this.password)
    },
    isPasswordAndConfirmPasswordEmpty() {
      return this.password.length === 0 && this.confirmPassword.length === 0;
    },
    isPasswordAndConfirmPasswordNotSame() {
      return (this.password.length > 0 || this.confirmPassword.length > 0)
        && this.password !== this.confirmPassword;
    },
    isErrorOldPasswordInput() {
      if (this.errorTexts.length === 0) {
        return false;
      }
      return this.errorTexts.find((item) => item.key === 'oldPassword');
    },
    isErrorPasswordInput() {
      if (this.errorTexts.length === 0) {
        return false;
      }
      return this.errorTexts.find((item) => item.key === 'password');
    },
    isErrorConfirmPasswordInput() {
      if (this.errorTexts.length === 0) {
        return false;
      }
      return this.errorTexts.find((item) => item.key === 'confirmPassword');
    },
  },
  methods: {
    validateInput() {
      this.checkPasswordCount = 0;
      this.errorAtLeast = false;
      this.errorCase = false;
      this.errorTexts = [];

      if (this.oldPassword === '' && this.$route.name !== 'reset-password') {
        this.errorTexts = [
          {
            key: 'oldPassword',
            text: this.$t('langkey.please-input-old-password'),
          },
        ];
        return;
      }

      if (this.isPasswordAndConfirmPasswordEmpty) {
        this.errorTexts = [
          {
            key: 'password',
            text: this.$t('langkey.please-input-password'),
          },
          {
            key: 'confirmPassword',
            text: this.$t('langkey.please-input-confirm-password'),
          },
        ];
        return;
      }

      if (this.isPasswordAndConfirmPasswordNotSame) {
        this.errorTexts = [
          {
            key: 'password',
            text: this.$t('langkey.password-not-same'),
          },
          {
            key: 'confirmPassword',
            text: '',
          },
        ];
        return;
      }

      if (!this.hasAtLeastEightCharacters) {
        this.errorAtLeast = true;
        this.errorTexts = [
          {
            key: 'password',
            text: this.$t('langkey.wrong-password-case'),
          },
          {
            key: 'confirmPassword',
            text: '',
          },
        ];
        return;
      }

      if (this.hasUppercase) {
        this.checkPasswordCount++;
      }
      if (this.hasLowerCase) {
        this.checkPasswordCount++;
      }
      if (this.hasNumeric) {
        this.checkPasswordCount++;
      }
      if (this.hasSpecialCharacter) {
        this.checkPasswordCount++;
      }

      if (this.checkPasswordCount < 3) {
        this.errorTexts = [
          {
            key: 'password',
            text: this.$t('langkey.wrong-password-case'),
          },
          {
            key: 'confirmPassword',
            text: '',
          },
        ];
        this.errorCase = true;
      }
    },
    async onResetPassword() {
      this.validateInput();
      if (this.errorTexts.length > 0) {
        return;
      }

      this.$store.dispatch('dialog/setLoadingDialog', {
        status: true,
      });

      if (this.$route.name === 'reset-password') {
        const token = this.$route.params.token;
        const secret = this.$route.params.secret;
        this.$store.dispatch('user/confirmPasswordRecovery', {
          token: token,
          secret: secret,
          password: this.password,
        })
          .then((res) => {
            if (res.status === 204) {
              // success
              this.$store.dispatch('dialog/setLoadingDialog', {
                status: false,
              });
              this.hide();
            }
          })
          .catch((err) => {
            if (err.response.status === 412) {
              this.$store.dispatch('dialog/setShowErrorDialog', {
                status: true,
                title: this.$t('langkey.confirm-password-error'),
                message: err,
              });
            }
            this.$store.dispatch('dialog/setLoadingDialog', {
              status: false,
            });
          });
      } else {
        const username = localStorage.getItem('tnpconfirmemail');
        if (username) {
          this.$store.dispatch('security/loginCheck', {
            username: username,
            password: this.oldPassword,
          })
            .then((loginResponse) => {
              this.$store.dispatch('user/confirmPasswordChange', {
                password_current: this.oldPassword,
                password_new: this.password,
                token: loginResponse.data.token,
              })
                .catch((err) => {
                  this.$store.dispatch('dialog/setShowErrorDialog', {
                    status: true,
                    title: this.$t('langkey.confirm-password-error'),
                    message: err,
                  });
                })
                .finally(() => {
                  this.$store.dispatch('dialog/setLoadingDialog', {
                    status: false,
                  });
                });
            })
            .catch((err) => {
              this.$store.dispatch('dialog/setLoadingDialog', {
                status: false,
              });
              this.$store.dispatch('dialog/setShowErrorDialog', {
                status: true,
                title: this.$t('langkey.confirm-password-error'),
                message: `${err} ${this.$t('langkey.invalid-old-password')}`,
              });
            });
        } else {
          const userSaveSession = sessionStorage.getItem('userInformation');
          const getUserEmail = userSaveSession ? JSON.parse(userSaveSession) : null;
          this.$store.dispatch('security/loginCheck', {
            username: getUserEmail.email,
            password: this.oldPassword,
          })
            .then((loginResponse) => {
              this.$store.dispatch('user/confirmPasswordChange', {
                password_current: this.oldPassword,
                password_new: this.password,
                token: loginResponse.data.token,
              })
                .catch((err) => {
                  this.$store.dispatch('dialog/setShowErrorDialog', {
                    status: true,
                    title: this.$t('langkey.confirm-password-error'),
                    message: err,
                  });
                })
                .finally(() => {
                  this.$store.dispatch('dialog/setLoadingDialog', {
                    status: false,
                  });
                });
            })
            .catch((err) => {
              this.$store.dispatch('dialog/setLoadingDialog', {
                status: false,
              });
              this.$store.dispatch('dialog/setShowErrorDialog', {
                status: true,
                title: this.$t('langkey.confirm-password-error'),
                message: `${err} ${this.$t('langkey.invalid-old-password')}`,
              });
            });
        }
      }
    },
    hide() {
      this.$store.dispatch('user/closeResetPasswordDialog');
      if (this.$route.name === 'reset-password') {
        this.$router.replace(
          this.$i18nRoute({
            name: 'login',
          }),
        );
      }
    },
  },
};
</script>
