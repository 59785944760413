import { apiClient } from '@/services';

export const CarService = {
  getCarByCarTag({ carTag }) {
    return apiClient.get('/cars/car', {
      params: {
        CarTag: carTag,
      },
    });
  },
  getConfiguratorManufacturers() {
    return apiClient.get('/configurator/manufacturers');
  },
  getConfiguratorClasses(payload) {
    return apiClient.get('/configurator/classes', {
      params: payload,
    });
  },
  getConfiguratorTypes(payload) {
    return apiClient.get('/configurator/type-groups', {
      params: payload,
    });
  },
  getConfiguratorVersions(payload) {
    return apiClient.get('/configurator/version-groups', {
      params: payload,
    });
  },
  getCarGroupByGermanCode(payload) {
    return apiClient.get('/cars/groups/german-code', {
      params: payload,
    });
  },
  getCarWheels(payload) {
    return apiClient.get('/configurator/car-wheels', {
      params: payload,
    });
  },
  getCarImage(payload) {
    return apiClient.get('/configurator/image', {
      params: payload,
    });
  },
  getCarWheelsConcatenated(payload) {
    return apiClient.get('/configurator/car-wheels-concatenated', {
      params: payload,
    });
  },
  getCertificateTexts(payload) {
    return apiClient.get('/configurator/certificate-texts', {
      params: payload,
    });
  },
  getCarHistory() {
    return apiClient.get('/cars/history');
  },
  setCarHistory(carTag) {
    return apiClient.post(`/cars/history/${carTag}`);
  },
  getCarCoc(carTags) {
    return apiClient.get('/cars/cocs', {
      params: {
        CarTags: carTags,
      },
    });
  },
};