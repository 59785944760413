
export const primary = '#00CCCC';
export const primaryDark = '#0BA3A6';

const themeColor = {
  'theme-primary': primary,
  'theme-primary-dark': primaryDark,
  'theme-black': '#000000',
  'theme-background': '#F5F5F5',
  'theme-placeholder': '#DDDDDD',
  'theme-white': '#FFFFFF',
  'dark-gray-1': '#707070',
  'light-gray-btn-bg': '#DDDDDD',
  'theme-gray-1': '#F5F5F5',
  'theme-gray-2': '#DCDCDC',
  'theme-gray-3': '#B9BABB',
  'theme-gray-4': '#8B8B8B',
  'error': '#DE0000',
  'theme-primary-fade': 'rgba(0, 204, 204, 0.12)',
  'theme-accent-dark': '#0EB1B1',
};

/*
----- Usage in scss or css -------
EX:
background-color: var(--theme-primary);

color: var(--grau-1);
*/

export function themeSetup() {
  Object.keys(themeColor).forEach((key) => {
    document.documentElement.style.setProperty(`--${key}`, themeColor[key]);
  });
}