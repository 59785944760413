var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-menu',{attrs:{"offset-y":"","close-on-content-click":!_vm.isDisableSelection,"disabled":_vm.isDisable},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({class:[
        'base-dropdown currency-dropdown',
        _vm.isShowMenu && 'show-menu'
      ],attrs:{"depressed":"","plain":"","disabled":_vm.isDisable}},'v-btn',attrs,false),on),[_c('span',{staticClass:"mr-1"},[_vm._v("€")]),_c('div',{class:[
          'icon-caret',
          _vm.isShowMenu && 'open'
        ]},[_c('icon-caret')],1)])]}}]),model:{value:(_vm.isShowMenu),callback:function ($$v) {_vm.isShowMenu=$$v},expression:"isShowMenu"}},[_c('v-list',{staticClass:"currency-list"},_vm._l((_vm.priceTypeItems),function(type,index){return _c('v-list-item',{key:`${type.title}${index}`,class:[
        'currency-item',
        _vm.isDisableSelection && type.value !== _vm.priceType && 'disabled'
      ],on:{"click":function($event){return _vm.selectPriceType(type.value)}}},[_c('v-list-item-title',[_c('span',{class:_vm.priceType === type.value ? 'active' : ''},[_vm._v(" "+_vm._s(_vm.$t(type.title))+" ")])])],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }