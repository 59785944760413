var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"660","persistent":""},model:{value:(_vm.showEmailConfirm),callback:function ($$v) {_vm.showEmailConfirm=$$v},expression:"showEmailConfirm"}},[_c('v-card',{staticClass:"dialog base-email-address-dialog"},[_c('v-card-title',[_c('span',[_vm._v(_vm._s(_vm.$t('langkey.enter-mail-address')))])]),_c('v-card-text',[_c('div',{staticClass:"welcome"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('langkey.welcome-to-tnp'))}}),_c('p',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('langkey.email-confirm-verify-text'))}})])]),_c('p',{staticClass:"more-info"},[_c('i',{domProps:{"innerHTML":_vm._s(_vm.$t('langkey.email-confirm-more-info'))}})]),_c('hr'),_c('v-form',{staticClass:"confirm-email__form"},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"4"}},[_c('p',[_vm._v(_vm._s(_vm.$t('langkey.email'))+":")])]),_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{class:[
                'input__email',
                _vm.isErrorEmailInput ? 'show-error' : null
              ],attrs:{"label":`${_vm.$t('langkey.email')}`,"solo":"","hide-details":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)],1),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"4"}},[_c('p',[_vm._v(_vm._s(_vm.$t('langkey.confirm-email'))+":")])]),_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{class:[
                'input__confirm-email',
                _vm.isErrorConfirmEmailInput ? 'show-error' : null
              ],attrs:{"label":`${_vm.$t('langkey.confirm-email')}`,"solo":"","hide-details":""},model:{value:(_vm.confirmEmail),callback:function ($$v) {_vm.confirmEmail=$$v},expression:"confirmEmail"}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}}),_c('v-col',{attrs:{"cols":"8"}},[_c('base-checkbox',{class:[
              _vm.isErrorAcceptTerm ? 'show-error' : null
            ],attrs:{"reverse":""},scopedSlots:_vm._u([{key:"custom-text",fn:function(){return [_c('span',{staticClass:"checkbox-text",domProps:{"innerHTML":_vm._s(_vm.$t('langkey.email-confirm-condition'))}})]},proxy:true}]),model:{value:(_vm.isAcceptTerm),callback:function ($$v) {_vm.isAcceptTerm=$$v},expression:"isAcceptTerm"}})],1)],1)],1),_c('v-card-actions',[_c('transition',{attrs:{"name":"fade"}},[(_vm.errorTexts.length > 0)?_c('div',{staticClass:"error-texts"},_vm._l((_vm.errorTexts),function(error,index){return _c('p',{key:`errorText${index}`},[_c('span',{domProps:{"innerHTML":_vm._s(error.text)}})])}),0):_vm._e()]),_c('v-spacer'),_c('v-btn',{staticClass:"icon",attrs:{"color":"primary"},on:{"click":_vm.confirmUserEmail}},[_vm._v(" "+_vm._s(_vm.$t('langkey.submit'))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }