var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-dialog',{ref:"infoRef",attrs:{"title":_vm.$t('langkey.modal-info-header'),"dialog-class":"info-dialog"}},[_c('div',{staticClass:"nav__items"},[_c('div',{staticClass:"nav__scroll"},[_c('navigation-tab',{class:[
          _vm.navbar.carTire.to === _vm.selectedTab ? 'link-active' : null
        ],attrs:{"item":_vm.navbar.carTire,"is-select":""},on:{"click":function($event){_vm.selectedTab = _vm.navbar.carTire.to}}}),_c('navigation-tab',{class:[
          _vm.navbar.truckTire.to === _vm.selectedTab ? 'link-active' : null
        ],attrs:{"item":_vm.navbar.truckTire,"is-select":""},on:{"on-select":function($event){_vm.selectedTab = _vm.navbar.truckTire.to}}}),_c('navigation-tab',{class:[
          _vm.navbar.motorbikeTire.to === _vm.selectedTab ? 'link-active' : null
        ],attrs:{"item":_vm.navbar.motorbikeTire,"is-select":""},on:{"on-select":function($event){_vm.selectedTab = _vm.navbar.motorbikeTire.to}}}),_c('navigation-tab',{class:[
          _vm.selectedTab.includes(_vm.navbar.rimsConfig.to) ? 'link-active' : null
        ],attrs:{"item":_vm.navbar.rimsConfig,"is-select":""},on:{"on-select":function($event){_vm.selectedTab = _vm.navbar.rimsConfig.to}}}),_c('navigation-tab',{class:[
          _vm.navbar.service.to === _vm.selectedTab ? 'link-active' : null
        ],attrs:{"item":_vm.navbar.service,"is-select":""},on:{"on-select":function($event){_vm.selectedTab = _vm.navbar.service.to}}}),_c('navigation-tab',{class:[
          _vm.navbar.industrial.to === _vm.selectedTab ? 'link-active' : null
        ],attrs:{"item":_vm.navbar.industrial,"is-select":""},on:{"on-select":function($event){_vm.selectedTab = _vm.navbar.industrial.to}}}),_c('navigation-tab',{class:[
          _vm.navbar.agriculture.to === _vm.selectedTab ? 'link-active' : null
        ],attrs:{"item":_vm.navbar.agriculture,"is-select":""},on:{"on-select":function($event){_vm.selectedTab = _vm.navbar.agriculture.to}}}),_c('navigation-tab',{class:[
          _vm.navbar.earthMover.to === _vm.selectedTab ? 'link-active' : null
        ],attrs:{"item":_vm.navbar.earthMover,"is-select":""},on:{"on-select":function($event){_vm.selectedTab = _vm.navbar.earthMover.to}}})],1)]),(_vm.isShowMatchcodeInfo)?_c('img',{staticClass:"info-img",attrs:{"src":require('@/assets/images/MatchcodeInfo.png')}}):_vm._e(),(_vm.selectedTab.includes(_vm.navbar.rimsConfig.to))?_c('div',[_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('langkey.modal-info-message_wheels'))}}),_c('img',{staticClass:"wheels-module-img",attrs:{"src":require('@/assets/images/RimsConfigModule.webp')}})]):(_vm.selectedTab === _vm.navbar.service.to)?_c('div',[_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('langkey.modal-info-message_spare-parts'))}}),_c('img',{staticClass:"spare-parts-module-img",attrs:{"src":require('@/assets/images/SparePartsModule.webp')}})]):_c('v-row',[_c('v-col',{attrs:{"cols":"\n      4"}},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.getInfoMessageOne)}})]),_c('v-col',{attrs:{"cols":"8"}},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.getInfoMessageTwo)}})])],1),(_vm.selectedTab === _vm.navbar.agriculture.to)?_c('v-row',[_c('v-col',[_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('langkey.angkey.modal-info-message_as3'))}})])],1):_vm._e(),_c('div',{staticClass:"mt-7",staticStyle:{"display":"inline-block"}},[_c('base-checkbox',{attrs:{"label-text":_vm.$t('langkey.unset-modal-matchcode-info'),"reverse":""},on:{"input":_vm.onSelectItem},model:{value:(_vm.isNotDisplay),callback:function ($$v) {_vm.isNotDisplay=$$v},expression:"isNotDisplay"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }