import Vue from 'vue';
import { getPriceType } from '@/utils';

Vue.filter('colorCase', (v) => {
  if (v == null || typeof v == 'undefined') {
    return '';
  }
  return v.toLowerCase();
});

Vue.filter('capitalize', (v) => {
  if (v == null || typeof v == 'undefined') {
    return '-';
  }
  return v.charAt(0).toUpperCase() + v.slice(1);
});

Vue.filter('dot2comma', (v) => {
  if (v == null || typeof v == 'undefined') {
    return '';
  }
  return v.toString().replace('.', ',');
});

Vue.filter('wheelWidth', (v) => {
  if (v == null || typeof v == 'undefined') {
    return '';
  }
  const intValue = parseFloat(v);
  return intValue.toFixed(1).toLocaleString().replace('.', ',');
});


Vue.filter('price', (value, currency = '€') => {
  const intValue = parseFloat(value);

  if (intValue === 0) {
    return `${currency} ${intValue
      .toFixed(2)
      .toLocaleString()
      .replace('.', ',')}`;
  }

  if (!intValue) {
    return `${currency} -`;
  }

  if ('hidePrice' === getPriceType()) {
    return `${currency} -`;
  }

  return `${currency} ${intValue
    .toFixed(2)
    .toLocaleString()
    .replace('.', ',')}`;
});

Vue.filter('tel', (v) => {
  if (v == null || typeof v == 'undefined') {
    return '-';
  }
  if (v.substr(0, 2) == '02')
    return v.replace(/(\d{2})(\d{3})(\d{4})/, '$1-$2-$3');
  else return v.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
});

Vue.filter('uppercase', (str) => str.toUpperCase());

Vue.filter('lowercase', (str) => str.toLowerCase());

Vue.filter('concatAddress', (a) => {
  if (a) {
    return `${a.streetName} ${a.streetNumber}, ${a.zipCode} ${a.city}${
      a.country ? `, ${a.country}` : ''
    }`;
  }
});

Vue.filter('replacebr', function (value) {
  value = value.replace('<br>', ', ');
  return value.replace('<br>', ', ');
});

Vue.filter('cleanSupIndUrl', function (value) {
  value = value.replace('https://', '');
  return value.replace('/', '');
});

Vue.filter('addDashBeforeAddress', function (value) {
  return value.replace('Address', '-address');
});

Vue.filter('shortYear', (v) => '‘' + v.toString().substring(2));

Vue.filter('toMillimeter', (v) => v + 'mm');

Vue.filter('getDateFromDatetime', (v) => v.substring(0, 10));