<template>
  <v-app-bar
    height="84"
    app
    class="navigation"
  >
    <div class="upper__wrapper navigation-landing">
      <!-- <language-dropdown
        class="language-dropdown"
        show-flag
      /> -->
      <router-link
        :to="{ name: 'login' }"
        class="logo"
        rel="nofollow"
      >
        <img :src="require('@/assets/images/Logo.svg')">
      </router-link>
      <v-form
        class="login__section"
        name="login-form"
        @submit.prevent="login"
      >
        <v-text-field
          id="realusername"
          v-model="loginData.username"
          :label="$t('langkey.username')"
          class="input__username"
          name="login-username-input"
          type="text"
          solo
          hide-details
        >
          <template #prepend-inner>
            <icon-user size="22" />
          </template>
        </v-text-field>
        <base-checkbox
          v-model="rememberMe"
          :label-text="$t('langkey.remember-me')"
          class="checkbox"
          reverse
        />
        <v-text-field
          id="realpassword"
          ref="loginPasswordRef"
          v-model="loginData.password"
          :label="$t('langkey.password')"
          class="input__password"
          name="login-password-input"
          type="password"
          solo
          hide-details
        >
          <template #prepend-inner>
            <icon-lock />
          </template>
        </v-text-field>
        <a
          class="forgot-password"
          @click="onClickForgotPassword"
        >
          {{ $t('langkey.forgot-password') }}
        </a>
        <v-btn
          icon
          plain
          class="login__button"
          type="submit"
          @submit.prevent="login"
        >
          <icon-login />
          <span>Login</span>
        </v-btn>
      </v-form>
    </div>

    <v-dialog
      v-model="errorLoginDialog"
      max-width="500"
      persistent
    >
      <v-card>
        <v-card-title class="headline">
          {{ $t('langkey.invalid-login-data') }}
        </v-card-title>
        <v-card-text>{{ $t('langkey.please-try-again') }}</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="errorLoginDialog = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <forgot-password-dialog
      ref="forgotPasswordDialog"
      @on-email-sent="onEmailSent"
    />

    <v-dialog
      key="recoveryEmailSentDialog"
      v-model="recoveryEmailSentDialog"
      max-width="330"
    >
      <v-card>
        <v-card-title />
        <v-card-text>
          {{ $t('langkey.recovery-email-sent') }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="recoveryEmailSentDialog = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app-bar>
</template>

<style lang="scss">
@import '@/styles/components/navigation.scss';
</style>

<script>
// import LanguageDropdown from '@/components/LanguageDropdown';
import ForgotPasswordDialog from '@/components/ForgotPasswordDialog';
import IconUser from '@/components/icons/IconUser';
import IconLock from '@/components/icons/IconLock';
import IconLogin from '@/components/icons/IconLogin';

export default {
  components: {
    // LanguageDropdown,
    ForgotPasswordDialog,
    IconUser,
    IconLock,
    IconLogin,
  },
  data() {
    return {
      loginData: {
        username: '',
        password: '',
      },
      rememberMe: false,
      errorLoginDialog: false,
      recoveryEmailSentDialog: false,
    };
  },
  methods: {
    login() {
      if (this.validate()) {
        this.$store.dispatch('dialog/setLoadingDialog', {
          status: true,
        });
        this.$store.dispatch('security/loginCheck', {
          username: this.loginData.username,
          password: this.loginData.password,
        })
          .then(async () => {
            try {
              await this.$store.dispatch('user/checkUserType');
              await this.$store.dispatch('user/getUserInformation');
              await this.$store.dispatch('dialog/getUserLoginInformation');
            } finally {
              this.$store.dispatch('dialog/setLoadingDialog', {
                status: false,
              });
              window.location.href = `/${this.$route.params.lang}/tyre`;
            }
          })
          .catch((err) => {
            if(err && err.response.config.url === '/user/loginInformation') {
              return;
            }
            this.loginData = {
              username: '',
              password: '',
            };
            this.$store.dispatch('dialog/setLoadingDialog', {
              status: false,
            });
            this.errorLoginDialog = true;
          });
      }
    },
    validate() {
      return this.loginData.username !== '' && this.loginData.password !== '';
    },
    onClickForgotPassword() {
      this.$refs.forgotPasswordDialog.show();
    },
    onEmailSent() {
      this.recoveryEmailSentDialog = true;
    },
    // onPasswordInput() {
    //   const inputPasswordEl = document.getElementById('realpassword');
    //   if (this.loginData.password.length > 0 && inputPasswordEl.getAttribute('type') === 'text') {
    //     inputPasswordEl.setAttribute('type', 'password');
    //   } else if (this.loginData.password.length === 0) {
    //     this.onReload();
    //   }
    // },
    // onReload() {
    //   window.location.reload();
    // },
  },
};
</script>