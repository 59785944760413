<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22.875"
    height="22.876"
    viewBox="0 0 22.875 22.876"
  >
    <path
      id="Path_5390"
      data-name="Path 5390"
      d="M11.442,0A11.438,11.438,0,1,0,22.879,11.438,11.44,11.44,0,0,0,11.442,0ZM13.13,2.282c.187-.3.585-.545,1.623-.187A9.439,9.439,0,0,1,17.106,3.3a9.582,9.582,0,0,1,2.262,2.209c.382.692.464,1.027.277,1.232A18.363,18.363,0,0,1,15.572,8.21a2.218,2.218,0,0,1-1.631-.228,1.963,1.963,0,0,1-.755-1.612A22.281,22.281,0,0,1,13.13,2.282Zm-1.861,7.967a1.32,1.32,0,1,1-1.32,1.32A1.32,1.32,0,0,1,11.269,10.249ZM3.548,5.441A9.312,9.312,0,0,1,5.415,3.57,9.6,9.6,0,0,1,8.207,2.087c.776-.148,1.12-.129,1.256.114a18.322,18.322,0,0,1,.154,4.326A2.219,2.219,0,0,1,8.9,8.01a1.957,1.957,0,0,1-1.767.229,22.774,22.774,0,0,1-3.91-1.2C3,6.775,2.887,6.321,3.548,5.441ZM4.842,18.028c-.324.139-.789.116-1.439-.772a9.138,9.138,0,0,1-1.25-2.328,9.639,9.639,0,0,1-.611-3.1c.086-.786.2-1.11.479-1.171A18.258,18.258,0,0,1,6.2,11.762a2.235,2.235,0,0,1,1.211,1.115,1.96,1.96,0,0,1-.294,1.757A22.475,22.475,0,0,1,4.842,18.028Zm9.219,2.987a9.413,9.413,0,0,1-2.619.351,9.744,9.744,0,0,1-3.123-.518c-.7-.355-.966-.58-.928-.855A18.135,18.135,0,0,1,9.9,16.463a2.222,2.222,0,0,1,1.468-.743,1.96,1.96,0,0,1,1.541.892,22.505,22.505,0,0,1,2.379,3.326C15.306,20.289,15.121,20.718,14.061,21.016Zm6.807-6.475a9.673,9.673,0,0,1-1.471,2.8c-.553.561-.849.74-1.1.618a18.117,18.117,0,0,1-2.572-3.486,2.217,2.217,0,0,1-.247-1.625,1.954,1.954,0,0,1,1.329-1.187,22.25,22.25,0,0,1,3.9-1.22c.344.092.689.4.642,1.5A9.47,9.47,0,0,1,20.868,14.541Z"
      transform="translate(-0.004)"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'var(--theme-gray-4)',
    },
  },
};
</script>