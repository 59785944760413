<template>
  <v-dialog
    v-model="showLoading"
    max-width="100"
    persistent
    content-class="loading-dialog"
  >
    <v-card>
      <v-progress-circular
        indeterminate
        color="accent"
      />
    </v-card>
  </v-dialog>
</template>

<style lang="scss">
@import "@/styles/components/loading-dialog.scss";
</style>

<script>
import { mapState } from 'vuex';
export default {
  computed: {
    ...mapState('dialog', ['showLoading']),
  },
};
</script>