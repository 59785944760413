<template>
  <v-dialog
    v-model="showDialog"
    max-width="1000"
  >
    <v-card class="dialog add-to-cart">
      <v-card-title>
        <h5 class="text">
          {{ $t('langkey.cart-dialog-head') }}
        </h5>
        <small class="description">{{ $t('langkey.cart-dialog-description') }}</small>
        <v-btn
          icon
          plain
          class="close-dialog__button"
          @click="hide"
        >
          <icon-close size="12" />
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div
          v-if="kitMatches && kitItems.length > 0"
          class="kit-matches"
        >
          <span>{{ $t('langkey.manufacturer') }}</span>
          <base-select-filter-dropdown
            ref="selectDropdownKitManufacturer"
            v-model="selectKit.manufacturer"
            :placeholder="$t('langkey.select')"
            :option-items="filteredKitItemsSelectOptions.manufacturer"
            single
            sortable
            @input="onSelectKit($event, 1)"
          />
          <span>{{ $t('langkey.breadcrumb-model') }}</span>
          <base-select-filter-dropdown
            ref="selectDropdownKitClass"
            v-model="selectKit.class"
            :placeholder="$t('langkey.select')"
            :option-items="filteredKitItemsSelectOptions.class"
            single
            :disabled="selectKit.manufacturer.length === 0"
            sortable
            @input="onSelectKit($event, 2)"
          />
          <span>{{ $t('langkey.breadcrumb-type') }}</span>
          <base-select-filter-dropdown
            ref="selectDropdownKitType"
            v-model="selectKit.type"
            :placeholder="$t('langkey.select')"
            :option-items="filteredKitItemsSelectOptions.type"
            single
            :disabled="selectKit.manufacturer.length === 0 || selectKit.class.length === 0"
            sortable
            @input="onSelectKit($event, 3)"
          />
          <span>{{ $t('langkey.multipack') }}</span>
          <base-select-filter-dropdown
            ref="selectDropdownKitMultipack"
            v-model="selectKit.kitHan"
            :placeholder="$t('langkey.select')"
            :option-items="filteredKitItemsSelectOptions.kitHan"
            single
            :disabled="selectKit.manufacturer.length === 0 || selectKit.class.length === 0 || selectKit.type.length === 0"
            sortable
            @input="onSelectKit($event, 4)"
          />
        </div>
        <v-data-table
          class="primary-style add-to-cart-table"
          :headers="tableFieldAddToCart"
          :items="cartItems"
          :expanded="expanded"
          show-expand
          hide-default-footer
          disable-sort
        >
          <template
            v-for="(field, index) in tableFieldAddToCart"
            v-slot:[`header.${field.value}`]="{ header }"
          >
            <span :key="`${field.value}${index}`">{{ $t(header.text) }}</span>
          </template>
          <template v-slot:[`item.image`]="{ item }">
            <img
              v-show="item.imageTemplate"
              v-lazy="getTpmsImageBySize(item.imageTemplate)"
              class="cart-img"
            >
          </template>
          <template v-slot:[`item.description`]="{ item }">
            <div
              v-if="item.isCombination"
              class="combination"
            >
              <div>{{ item.F.description }}</div>
              <div class="mt-3">
                {{ item.R.description }}
              </div>
            </div>
            <span v-else>{{ item.description }}</span>
          </template>
          <template v-slot:[`item.articleId`]="{ item }">
            <div
              v-if="item.isCombination"
              class="combination"
            >
              <div>{{ item.F.articleId }}</div>
              <div class="mt-3">
                {{ item.R.articleId }}
              </div>
            </div>
            <span v-else>{{ item.articleId }}</span>
          </template>
          <template v-slot:[`item.quantity`]="{ item }">
            <div
              v-if="item.isCombination && !supplierSource"
              class="combination qty"
            >
              <div
                v-for="(wheel, wheelIndex) in [item.F, item.R]"
                :key="wheelIndex"
                class="qty__wrapper"
              >
                <v-btn
                  icon
                  plain
                  @click="onSubtractQuantity(wheel)"
                >
                  <icon-subtract />
                </v-btn>
                <input
                  v-model.number="selectQuantity[wheel.combinationKey]"
                  class="qty__input"
                  @change="onUpdateQuantity($event, wheel)"
                >
                <v-btn
                  icon
                  plain
                  @click="onAddQuantity(wheel)"
                >
                  <icon-add />
                </v-btn>
              </div>
            </div>
            <div
              v-else-if="!supplierSource"
              class="qty__wrapper"
            >
              <v-btn
                icon
                plain
                @click="onSubtractQuantity(item)"
              >
                <icon-subtract />
              </v-btn>
              <input
                v-model.number="selectQuantity[item.articleId]"
                class="qty__input"
                @change="onUpdateQuantity($event, item)"
              >
              <v-btn
                icon
                plain
                @click="onAddQuantity(item)"
              >
                <icon-add />
              </v-btn>
            </div>
          </template>
          <template v-slot:[`item.total`]="{ item }">
            <div
              v-if="item.isCombination && !supplierSource"
              class="combination"
            >
              <span>{{ selectQuantity[item.F.combinationkey] * item.F.price | price }}</span>
              <span>{{ selectQuantity[item.R.combinationkey] * item.R.price | price }}</span>
            </div>
            <span v-else-if="!supplierSource">{{ selectQuantity[item.articleId] * item.price | price }}</span>
          </template>
          <template v-slot:expanded-item="expandedItem">
            <td
              v-if="supplierSource"
              :colspan="expandedItem.headers.length"
            >
              <v-data-table
                class="primary-style"
                :headers="tableFieldAddToCartSuppliers"
                :items="expandedItem.item.suppliers"
                sort-by="purchasePrice"
                hide-default-footer
              >
                <template
                  v-for="(field, supplierIndex) in tableFieldAddToCartSuppliers"
                  v-slot:[`header.${field.value}`]="{ header }"
                >
                  <span :key="`${field.value}${supplierIndex}`">{{ $t(header.text) }}</span>
                </template>
                <template v-slot:[`item.supplier`]="{ item }">
                  <router-link
                    v-if="userType === 'TEST USER'"
                    class="link__register-full"
                    :to="{ name: 'register-full' }"
                    rel="nofollow"
                  >
                    {{ $t('langkey.register_full') }}
                  </router-link>
                  <span v-else>{{ item.supplier }}</span>
                </template>
                <template v-slot:[`item.listPrice`]="{ item }">
                  {{ item.listPrice | price }}
                </template>
                <template v-slot:[`item.discount`]="{ item }">
                  {{ parseInt(item.listPrice) === 0
                    ? '0%'
                    : `${((1 - (parseFloat(item.purchasePrice) / parseFloat(item.listPrice))) * 100).toFixed(2)}%`
                  }}
                </template>
                <template v-slot:[`item.purchasePrice`]="{ item }">
                  {{ item.price.purchasePrice | price }}
                </template>
                <template v-slot:[`item.retailPrice`]="{ item }">
                  {{ item.price.retailPrice | price }}
                </template>
                <template v-slot:[`item.retailTaxPrice`]="{ item }">
                  {{ item.price.retailTaxPrice | price }}
                </template>
                <template v-slot:[`item.expressShipmentAvailable`]="{ item }">
                  <icon-express-truck
                    v-if="item.expressShipmentAvailable"
                    style="position: relative; top: 2px;"
                  />
                </template>
                <template v-slot:[`item.qty`]="{ item }">
                  <div class="qty-container">
                    <v-btn
                      :disabled="selectQuantitySupplierSource[item.id] < 2"
                      icon
                      plain
                      @click="onSubtractQuantity(item.id)"
                    >
                      <icon-subtract />
                    </v-btn>
                    <input
                      v-model="selectQuantitySupplierSource[item.id]"
                      class="qty__input"
                      type="number"
                      @change="onChangeQuantity($event, item)"
                    >
                    <v-btn
                      :disabled="selectQuantitySupplierSource[item.id] >= item.stock"
                      icon
                      plain
                      @click="onAddQuantity(item.id)"
                    >
                      <icon-add />
                    </v-btn>
                  </div>
                </template>
                <template v-slot:[`item.total`]="{ item }">
                  <span
                    v-if="priceType === 'retailPrice' || priceType === 'retailTaxPrice'"
                  >
                    {{ item.price.retailTaxPrice * selectQuantitySupplierSource[item.id] | price }}
                  </span>
                  <span
                    v-else
                  >
                    {{ item.price.purchasePrice * selectQuantitySupplierSource[item.id] | price }}
                  </span>
                </template>
                <template v-slot:[`item.order`]="{ item }">
                  <v-btn
                    icon
                    plain
                    @click="onAddToCartSupplier(item)"
                  >
                    <icon-add-to-cart color="var(--theme-gray-3)" />
                  </v-btn>
                </template>
              </v-data-table>
            </td>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <template v-if="!supplierSource">
          <template v-if="isRimConfig">
            <v-btn
              outlined
              plain
              class="icon"
              @click="onConfirmAndShowCart"
            >
              {{ $t('langkey.cart-dialog-confirm') }} <icon-cart-show />
            </v-btn>
            <v-btn
              color="primary"
              class="icon"
              @click="onRimConfigNextStep"
            >
              {{ $t('langkey.cart-dialog-next') }} <icon-arrow />
            </v-btn>
          </template>
          <template v-else>
            <v-btn
              outlined
              plain
              class="icon"
              @click="onConfirmAndShowCart"
            >
              {{ $t('langkey.cart-dialog-confirm') }} <icon-cart-show />
            </v-btn>
            <v-btn
              color="primary"
              class="icon"
              @click="onContinueShopping"
            >
              {{ $t('langkey.cart-dialog-continue') }} <icon-arrow />
            </v-btn>
          </template>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss">
@import "@/styles/components/base-add-to-cart-dialog.scss";
</style>

<script>
import { mapState, mapGetters } from 'vuex';
import IconClose from '@/components/icons/IconClose';
import IconCartShow from '@/components/icons/IconCartShow';
import IconArrow from '@/components/icons/IconArrow';
import IconAdd from '@/components/icons/IconAdd';
import IconSubtract from '@/components/icons/IconSubtract';
import IconExpressTruck from '@/components/icons/IconExpressTruck';
import { tableFieldAddToCart, tableFieldAddToCartSuppliers, tableFieldAddToCartSuppliersRetailPrice } from '@/configs/components/baseAddToCartDialog';
import { getImageBySize } from '@/utils/index.js';

export default {
  components: {
    IconClose,
    IconCartShow,
    IconArrow,
    IconAdd,
    IconSubtract,
    IconExpressTruck,
  },
  props: {
    isRimConfig: {
      type: Boolean,
      default: false,
    },
    cartItems: {
      type: Array,
      default: () => [],
    },
    supplierSource: {
      type: Boolean,
      default: false,
    },
    isCombinationWheel: {
      type: Boolean,
      default: false,
    },
    selectQuantityTable: {
      type: Object,
      default: () => {},
    },
    kitMatches: {
      type: Boolean,
      default: false,
    },
    isWheelConfigurator: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showDialog: false,
      expanded: [],
      selectQuantitySupplierSource: {},
      selectKit: {
        manufacturer: [],
        class: [],
        type: [],
        kitHan: [],
      },
      filteredKitItemsSelectOptions: {},
    };
  },
  computed: {
    ...mapState('baseAddToCartDialog', ['cartItem']),
    ...mapState('user', ['userType']),
    ...mapState('rimconfigWheelDesign', ['kitItems']),
    ...mapState('pricing', ['priceType']),
    ...mapGetters('rimconfigWheelDesign', ['getKitItemsSelectOptions']),
    tableFieldAddToCart() {
      return tableFieldAddToCart;
    },
    tableFieldAddToCartSuppliers() {
      if (this.priceType === 'retailPrice' || this.priceType === 'retailTaxPrice') {
        return tableFieldAddToCartSuppliersRetailPrice;
      } else {
        return tableFieldAddToCartSuppliers;
      }
    },
    selectQuantity() {
      let result = {};
      this.cartItems.forEach((item) => {
        if (item.isCombination) {
          const combinationItems = [item.F, item.R];
          combinationItems.forEach((wheel) => {
            result[wheel.combinationKey] = wheel.quantity;
          });
        } else {
          result[item.articleId] = item.quantity;
        }
      });
      return result;
    },
    filteredKitItems() {
      const filtered = this.kitItems.filter((item) => {
        return Object.keys(this.selectKit).every((key) => {
          if (this.selectKit[key].length === 0) {
            return true;
          }

          return this.selectKit[key].some((filterEl) => {
            return item[key] === filterEl;
          });
        });
      });
      return filtered;
    },
  },
  methods: {
    show() {
      this.showDialog = true;
      if (this.supplierSource) {
        this.expanded = this.cartItems;
        const tableItems = this.cartItems;
        if (this.isCombinationWheel) {
          tableItems[0].suppliers.forEach((assignItem) => {
            this.selectQuantitySupplierSource = {
              ...this.selectQuantitySupplierSource,
              [assignItem.id]: assignItem.stock < 4 ? assignItem.stock : this.selectQuantityTable[`${tableItems[0].man}${tableItems[0].axisOnly}${tableItems[0].combination}`],
            };
          });
          tableItems[1].suppliers.forEach((assignItem) => {
            this.selectQuantitySupplierSource = {
              ...this.selectQuantitySupplierSource,
              [assignItem.id]: assignItem.stock < 4 ? assignItem.stock : this.selectQuantityTable[`${tableItems[1].man}${tableItems[1].axisOnly}${tableItems[1].combination}`],
            };
          });
        } else {
          tableItems[0].suppliers.forEach((assignItem) => {
            if (this.isWheelConfigurator) {
              this.selectQuantitySupplierSource = {
                ...this.selectQuantitySupplierSource,
                [assignItem.id]: assignItem.stock < 4 ? assignItem.stock : this.selectQuantityTable[`${tableItems[0].man}${tableItems[0].axisOnly}${tableItems[0].combination}`],
              };
            } else {
              this.selectQuantitySupplierSource = {
                ...this.selectQuantitySupplierSource,
                [assignItem.id]: assignItem.stock < 4 ? assignItem.stock : this.selectQuantityTable[`${tableItems[0].man}${tableItems[0].ean}`],
              };
            }
          });
        }

        this.setInitFilteredKitItemsSelectOptions();
      }
    },
    hide() {
      this.showDialog = false;
    },
    onRimConfigNextStep() {
      const userType = sessionStorage.getItem('userType');
      if (this.isRimConfig &&
        userType &&
        userType.toString().toLowerCase().includes('test')
      ) {
        this.$router.push(this.$i18nRoute({
          name: 'register-full',
        }));
        return;
      }
      this.$emit('rimconfig-next');
    },
    onContinueShopping() {
      this.$emit('continue-shopping');
    },
    onConfirmAndShowCart() {
      const userType = sessionStorage.getItem('userType');
      if (this.isRimConfig &&
        userType &&
        userType.toString().toLowerCase().includes('test')
      ) {
        this.$router.push(this.$i18nRoute({
          name: 'register-full',
        }));
        return;
      }
      this.$emit('confirm-and-show-cart');
    },
    onAddToCartSupplier(item) {
      if (this.kitMatches) {
        if (this.selectKit.kitHan.length === 0) {
          if (this.kitItems.length > 0) {
            this.$store.dispatch('dialog/setShowErrorDialog', {
              status: true,
              title: this.$t('langkey.kit-is-missing'),
              message: this.$t('langkey.please-select-a-kit'),
            });
            return;
          } else if (this.kitItems.length === 0) {
            this.selectKit.kitHan.push('Serie');
          }
        }
      }
      let payload = {};
      const userType = sessionStorage.getItem('userType');
      if (this.isRimConfig &&
        userType &&
        userType.toString().toLowerCase().includes('test')
      ) {
        this.$router.push(this.$i18nRoute({
          name: 'register-full',
        }));
        return;
      }
      if (this.isCombinationWheel) {
        payload = {
          cartItems: this.cartItems,
          item: item,
          quantity: this.selectQuantitySupplierSource[this.cartItems[0].suppliers[0].id],
          quantityCombination: this.selectQuantitySupplierSource[this.cartItems[1].suppliers[0].id],
          kitHan: this.selectKit.kitHan.join(),
        };
      } else {
        if (this.kitMatches) {
          payload = {
            cartItems: this.cartItems,
            item: item,
            quantity: this.selectQuantitySupplierSource[item.id],
            kitHan: this.selectKit.kitHan.join(),
            carDescription: this.kitItems.length > 0 ?
              `${this.selectKit.manufacturer} ${this.selectKit.class} ${this.selectKit.type}` :
              '',
          };
        } else {
          payload = {
            cartItems: this.cartItems,
            item: item,
            quantity: this.selectQuantitySupplierSource[item.id],
          };
        }
      }
      this.$emit('supplier-add-cart', payload);
    },
    onSubtractQuantity(articleId) {
      if (this.supplierSource) {
        let quantity = this.selectQuantitySupplierSource[articleId];
        if (quantity > 0) {
          this.selectQuantitySupplierSource[articleId] = quantity - 1;
          this.$forceUpdate();
          this.$emit('on-subtract-quantity', {
            isSupplierSource: true,
            articleId,
            quantity: this.selectQuantitySupplierSource[articleId],
          });
        }
      } else {
        this.$emit('on-subtract-quantity', articleId);
      }
    },
    onAddQuantity(articleId) {
      if (this.supplierSource) {
        let quantity = this.selectQuantitySupplierSource[articleId];
        this.selectQuantitySupplierSource[articleId] = quantity + 1;
        this.$forceUpdate();
        this.$emit('on-add-quantity', {
          isSupplierSource: true,
          articleId,
          quantity: this.selectQuantitySupplierSource[articleId],
        });
      } else {
        this.$emit('on-add-quantity', articleId);
      }
    },
    onChangeQuantity(e, item) {
      const max = item.stock;
      const value = Math.abs(parseInt(e.target.value, 10)) || 0;
      if (max !== null && value > max) {
        this.selectQuantitySupplierSource = {
          ...this.selectQuantitySupplierSource,
          [item.id]: max,
        };
        this.$emit('on-change-quantity', {
          isSupplierSource: true,
          articleId: item.id,
          quantity: this.selectQuantitySupplierSource[item.id],
        });
        return;
      }
      if (value === 0) {
        this.selectQuantitySupplierSource = {
          ...this.selectQuantitySupplierSource,
          [item.id]: 1,
        };
        this.$emit('on-change-quantity', {
          isSupplierSource: true,
          articleId: item.id,
          quantity: this.selectQuantitySupplierSource[item.id],
        });
        return;
      }
      this.selectQuantitySupplierSource = {
        ...this.selectQuantitySupplierSource,
        [item.id]: value,
      };
      this.$emit('on-change-quantity', {
        isSupplierSource: true,
        articleId: item.id,
        quantity: this.selectQuantitySupplierSource[item.id],
      });
    },
    onUpdateQuantity (event, item) {
      let newQuantity;
      let previousQuantity;
      if (this.supplierSource) {
        previousQuantity = item.qty || 0;
      }

      if (!event.target.value) {
        this.selectQuantitySupplierSource[item.id] = previousQuantity;
        return;
      }

      const max = item.stock;
      if (event.target.value > max) {
        this.$store.dispatch('dialog/setShowErrorDialog', {
          status: true,
          title: this.$t('langkey.stock-limit'),
          message: `${this.$t('langkey.stock-limit-at')} ${max}`,
        });
        this.selectQuantitySupplierSource[item.id] = max;
        newQuantity = max;
      } else if (event.target.value < 0) {
        this.selectQuantitySupplierSource[item.id] = 0;
        newQuantity = 0;
      } else {
        newQuantity = parseInt(event.target.value);
      }
      this.$emit('on-update-quantity', {
        articleId: item.id,
        quantity: newQuantity,
      });
    },
    getTpmsImageBySize(image) {
      if (!image) {
        return;
      }
      if (image.includes('https://cdn.jfnet.de/tpms')) {
        return getImageBySize(image, 120, 120);
      }

      return getImageBySize(image, 120, 120);
      // .replace('https://cdn.jfnet.de/', 'https://cdn.jfnet.de/tpms/');
    },
    onSelectKit(selectedValue, step) {
      let field;
      let nextField;
      switch (step) {
      case 1:
        this.selectKit.manufacturer = selectedValue;
        field = 'manufacturer';
        nextField = 'class';
        this.selectKit = {
          ...this.selectKit,
          class: [],
          type: [],
          kitHan: [],
        };
        this.filteredKitItemsSelectOptions = {
          ...this.filteredKitItemsSelectOptions,
          type: [],
          kitHan: [],
        };
        break;
      case 2:
        this.selectKit.class = selectedValue;
        field = 'class';
        nextField = 'type';
        this.selectKit = {
          ...this.selectKit,
          type: [],
          kitHan: [],
        };
        this.filteredKitItemsSelectOptions = {
          ...this.filteredKitItemsSelectOptions,
          kitHan: [],
        };
        break;
      case 3:
        this.selectKit.type = selectedValue;
        field = 'type';
        nextField = 'kitHan';
        this.selectKit = {
          ...this.selectKit,
          kitHan: [],
        };
        break;
      case 4:
        this.selectKit.kitHan = selectedValue;
        field = 'kitHan';
        break;
      }

      this.onSelectFilter();
    },
    onSelectFilter() {
      let eachFilterPossibleOption = {};
      let nonFilterOption = {};
      const filterDataKeys = Object.keys(this.selectKit).filter((key) => this.selectKit[key].length !== 0).map((key) => key);
      const nonFilterDataKeys = Object.keys(this.selectKit).filter((key) => this.selectKit[key].length === 0).map((key) => key);

      filterDataKeys.forEach((key) => {
        const customFilterData = {
          ...this.selectKit,
          [key]: [],
        };

        const customFilterItems = this.kitItems.filter((item) => {
          let tmp = [];

          Object.keys(customFilterData).forEach((filterKey) => {
            if (customFilterData[filterKey].length === 0 ) {
              tmp.push(true);
            } else {
              const result = customFilterData[filterKey].some((filterEl) => {
                return item[filterKey] === filterEl;
              });

              tmp.push(result);
            }
          });

          return tmp.every((item) => item);
        });

        eachFilterPossibleOption = {
          ...eachFilterPossibleOption,
          [key]: customFilterItems,
        };
      });

      nonFilterDataKeys.forEach((nonFilter) => {
        nonFilterOption = {
          ...nonFilterOption,
          [nonFilter]: this.filteredKitItems,
        };
      });

      Object.keys(this.selectKit).forEach((field) => {
        if (eachFilterPossibleOption[field]) {
          this.filteredKitItemsSelectOptions = {
            ...this.filteredKitItemsSelectOptions,
            [field]: [...new Set(eachFilterPossibleOption[field].map((item) => item[field]))]
              .map((item) => {
                return {
                  label: item,
                  value: item,
                };
              }),
          };
        } else {
          this.filteredKitItemsSelectOptions = {
            ...this.filteredKitItemsSelectOptions,
            [field]: [...new Set(nonFilterOption[field].map((item) => item[field]))]
              .map((item) => {
                return {
                  label: item,
                  value: item,
                };
              }),
          };
        }
      });
    },
    setInitFilteredKitItemsSelectOptions() {
      Object.keys(this.selectKit).forEach((field) => {
        this.filteredKitItemsSelectOptions = {
          ...this.filteredKitItemsSelectOptions,
          [field]: [
            ...new Set(this.kitItems.map((item) => {
              return item[field];
            })),
          ]
            .map((item) => {
              return {
                label: item,
                value: item,
              };
            }),
        };
      });
    },
  },
};
</script>