<template>
  <v-menu
    v-model="isShowMenu"
    offset-y
    :disabled="isDisable"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        v-if="!isFilter"
        depressed
        plain
        v-bind="attrs"
        :class="[
          'base-dropdown',
          isShowMenu ? 'show-menu' : null
        ]"
        :disabled="isDisable"
        v-on="on"
      >
        <component
          :is="`Icon${item.icon}`"
          v-if="item.icon"
          :class="[ isShowText && item.title && 'mr-1' ]"
          :is-opt-in="isOptIn"
        />
        <span v-if="isShowText">{{ $t(item.title) }}</span>
        <div
          :class="[
            'icon-caret',
            isShowMenu && 'open'
          ]"
        >
          <icon-caret />
        </div>
      </v-btn>
      <v-btn
        v-else
        depressed
        plain
        v-bind="attrs"
        :class="[
          'filter-dropdown',
          isShowMenu && 'show-menu'
        ]"
        v-on="on"
      >
        <span>Filter</span>
        <div
          :class="[
            'icon-caret',
            isShowMenu && 'open'
          ]"
        >
          <icon-caret />
        </div>
      </v-btn>
    </template>
    <v-list
      v-if="!isFilter"
      flat
    >
      <v-list-item
        v-for="(listItem, index) in item.items"
        :key="`${listItem.title}${index}`"
        :class="[listItem.isActive && 'active']"
        :to="{ name: listItem.to }"
        @click="onEmitCustomEvent(listItem.customEvent)"
      >
        <v-list-item-title v-if="!listItem.customContent">
          {{ $t(listItem.title) }}
        </v-list-item-title>
        <slot
          :name="`content(${listItem.key})`"
          :data="listItem"
        />
      </v-list-item>
    </v-list>
    <v-card
      v-else
      tile
    >
      <v-card-text>filters</v-card-text>
    </v-card>
  </v-menu>
</template>

<style lang="scss">
@import '@/styles/components/base-dropdown.scss';
</style>

<script>
import IconCaret from '@/components/icons/IconCaret';
import IconMarketplace from '@/components/icons/IconMarketplace';
import IconWheel from '@/components/icons/IconWheel';
import IconUser from '@/components/icons/IconUser';

export default {
  components: {
    IconCaret,
    IconMarketplace,
    IconWheel,
    IconUser,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    isShowText: {
      type: Boolean,
      default: false,
    },
    isFilter: {
      type: Boolean,
      default: false,
    },
    isDisable: {
      type: Boolean,
      default: false,
    },
    isOptIn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isShowMenu: false,
    };
  },
  methods: {
    onEmitCustomEvent(event) {
      if (event) {
        this.$emit(event);
      }
    },
  },
};
</script>