var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.checkBoxButton)?_c('label',{class:[
    'base-checkbox',
    _vm.reverse && 'reverse',
    _vm.checkBoxButton && 'check-box-button',
    _vm.value && 'is-checked',
    _vm.disabled && 'disabled'
  ]},[_c('input',{attrs:{"type":"checkbox"},domProps:{"checked":_vm.value},on:{"change":_vm.onCheckboxSelect}}),_c('div',{staticClass:"v-btn__content"},[_vm._t("icon")],2)]):_c('label',{class:[
    'base-checkbox',
    _vm.reverse && 'reverse',
    _vm.disabled && 'disabled'
  ]},[(_vm.isRadio)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentSelect),expression:"currentSelect"}],attrs:{"type":"radio","name":_vm.name},domProps:{"value":_vm.selectValue,"checked":_vm._q(_vm.currentSelect,_vm.selectValue)},on:{"click":_vm.onRadioSelect,"change":function($event){_vm.currentSelect=_vm.selectValue}}}):_c('input',{attrs:{"type":"checkbox","disabled":_vm.disabled},domProps:{"checked":_vm.value},on:{"change":_vm.onCheckboxSelect}}),(_vm.$scopedSlots['custom-text'])?_vm._t("custom-text"):_c('span',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.labelText)+" ")]),_c('div',{staticClass:"icon-checkbox-container"},[_c('icon-checkbox',{staticClass:"icon-checkbox"})],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }