<template>
  <v-dialog
    v-model="showKbaInfo"
    max-width="860"
    rounded="lg"
  >
    <v-card class="rimconfig-kba-info">
      <v-card-title>
        <h5
          class="text"
          v-html="$t('langkey.rim-config-kba-number-modal-header')"
        />
        <v-btn
          icon
          plain
          class="close__button"
          @click="hide"
        >
          <icon-close size="18" />
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <h2
              v-html="$t('langkey.rim-config-kba-number-modal-message1')"
            />
          </v-col>
          <v-col cols="6">
            <h2
              v-html="$t('langkey.rim-config-kba-number-modal-message2')"
            />
          </v-col>
        </v-row>
        <br>
        <h2
          v-html="$t('langkey.rim-config-kba-number-modal-header2')"
        />
        <v-row>
          <v-col cols="6">
            <h2
              v-html="$t('langkey.rim-config-kba-number-modal-message3')"
            />
          </v-col>
          <v-col cols="6">
            <h2
              v-html="$t('langkey.rim-config-kba-number-modal-message4')"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style lang="scss">
@import "@/styles/components/rimconfig-kba-info__dialog.scss";
</style>

<script>
export default {
  data() {
    return {
      showKbaInfo: false,
    };
  },
  methods: {
    show() {
      this.showKbaInfo = true;
    },
    hide() {
      this.showKbaInfo = false;
    },
  },
};
</script>