export const tableFields = [
  { value: 'productionFrom', text: 'langkey.car-dialog-year', align: 'center' },
  { value: 'power', text: 'langkey.car-dialog-power', align: 'center' },
  { value: 'version', text: 'langkey.car-dialog-type', align: 'center' },
  { value: 'type', text: 'langkey.car-dialog-type', align: 'center' },
  { value: 'fuelCode', text: 'langkey.car-dialog-fuel', align: 'center' },
  { value: 'boltPattern', text: 'langkey.car-dialog-bolt', align: 'center' },
  { value: 'cocMinSize', text: 'langkey.coc', align: 'center' },
];

export const initSelectFilter = {
  construction: [],
  powerPs: [],
  version: [],
  typeName: [],
  fuel: [],
  boltCircle: [],
  coc: [],
};