<template>
  <svg
    id="Group_5033"
    data-name="Group 5033"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      id="Path_5403"
      data-name="Path 5403"
      d="M0,0H24V24H0Z"
      fill="none"
    />
    <line
      id="Line_204"
      data-name="Line 204"
      x2="14"
      transform="translate(5 12)"
      fill="none"
      class="svg-icon-stroke"
      :stroke="color"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
    />
    <line
      id="Line_205"
      data-name="Line 205"
      y1="6"
      x2="6"
      transform="translate(13 12)"
      fill="none"
      class="svg-icon-stroke"
      :stroke="color"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
    />
    <line
      id="Line_206"
      data-name="Line 206"
      x2="6"
      y2="6"
      transform="translate(13 6)"
      fill="none"
      class="svg-icon-stroke"
      :stroke="color"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'var(--theme-white)',
    },
  },
};
</script>