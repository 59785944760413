<template>
  <svg
    :height="size"
    viewBox="0 0 15 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.36686 9.04762C9.77578 9.04762 11.7286 7.02224 11.7286 4.52381C11.7286 2.02538 9.77578 0 7.36686 0C4.95794 0 3.00513 2.02538 3.00513 4.52381C3.00513 7.02224 4.95794 9.04762 7.36686 9.04762Z"
      :fill="color"
    />
    <path
      d="M12.2692 9.92061C11.5603 9.71968 10.8042 9.80537 10.1538 10.1604C9.30006 10.6173 8.35397 10.8577 7.39326 10.8616C6.43255 10.8655 5.48463 10.633 4.62745 10.183C4.01609 9.85563 3.31116 9.76886 2.6429 9.93871C2.10466 10.0789 1.618 10.38 1.24344 10.8046C0.868877 11.2293 0.622909 11.7588 0.536147 12.3273L0.0345373 15.6161C-0.0291746 16.0349 -0.00452851 16.463 0.106736 16.8709C0.218 17.2789 0.413273 17.6569 0.679054 17.979C0.944834 18.3011 1.27481 18.5596 1.64623 18.7366C2.01765 18.9137 2.42166 19.005 2.83042 19.0044H12.1645C12.5853 19.0044 13.0008 18.907 13.3806 18.7192C13.7605 18.5315 14.0951 18.2581 14.3601 17.9191C14.625 17.5801 14.8137 17.184 14.9123 16.7598C15.0109 16.3355 15.0169 15.8938 14.9299 15.4668L14.2538 12.1373C14.149 11.6154 13.9087 11.1331 13.559 10.7424C13.2092 10.3518 12.7633 10.0676 12.2692 9.92061Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: '20',
    },
    isOptIn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      baseColor: '#8B8B8B',
    };
  },
  computed: {
    color() {
      return this.isOptIn ? '#000000' : this.baseColor;
    },
  },
};
</script>