import { CarService } from '@/services';
// import { carSelectorStepCount, carSelectorStepText } from '@/constants/carSelectorStep';
import { networkStatus } from '@/constants/networkStatus';

import {
  SET_MANUFACTURERS,
  SET_MODELS,
  SET_TYPES,
  SET_VERSIONS,
  SET_SELECTED_MANUFACTURER,
  SET_SELECTED_MODEL,
  SET_SELECTED_TYPE,
  SET_STEP,
  SET_CAR_HISTORY,
  SET_SEARCH_BY_KBA_NUMBER,
  SET_CAR_GROUP_BY_GERMAN_CODE,
} from '../mutationTypes';

const state = {
  step: 1,
  manufacturers: [],
  models: [],
  types: [],
  versions: [],
  selectedManufacturer: '',
  selectedModel: '',
  selectedType: {},
  searchByKbaNumber: false,
  carHistory: [],
  carGroupByGermanCode: [],
};

const mutations = {
  [SET_MANUFACTURERS](stateData, data) {
    stateData.manufacturers = [
      ...data,
    ];
  },
  [SET_MODELS](stateData, data) {
    stateData.models = [
      ...data,
    ];
  },
  [SET_TYPES](stateData, data) {
    stateData.types = [
      ...data,
    ];
  },
  [SET_VERSIONS](stateData, data) {
    stateData.versions = [
      ...data,
    ];
  },
  [SET_SELECTED_MANUFACTURER](stateData, data) {
    stateData.selectedManufacturer = data;
  },
  [SET_SELECTED_MODEL](stateData, data) {
    stateData.selectedModel = data;
  },
  [SET_SELECTED_TYPE](stateData, data) {
    stateData.selectedType = data;
  },
  [SET_STEP](stateData, data) {
    stateData.step = data;
  },
  [SET_CAR_HISTORY](stateData, data) {
    stateData.carHistory = [...data];
  },
  [SET_SEARCH_BY_KBA_NUMBER](stateData, data) {
    stateData.searchByKbaNumber = data;
  },
  [SET_CAR_GROUP_BY_GERMAN_CODE](stateData, data) {
    stateData.carGroupByGermanCode = [...data];
  },
};

const actions = {
  async getConfiguratorManufacturers({ commit }) {
    const response = await CarService.getConfiguratorManufacturers();

    commit(SET_MANUFACTURERS, response.data);

    return response;
  },
  async getConfiguratorClasses({ commit }, payload) {
    const response = await CarService.getConfiguratorClasses(payload);

    commit(SET_MODELS, response.data);

    return response;
  },
  async getConfiguratorTypes({ commit }, payload) {
    const response = await CarService.getConfiguratorTypes(payload);

    commit(SET_TYPES, response.data);

    return response;
  },
  async getConfiguratorVersions({ commit }, payload) {
    const response = await CarService.getConfiguratorVersions(payload);

    commit(SET_VERSIONS, response.data);

    return response;
  },
  setSelectedManufacturer({ commit }, payload) {
    commit(SET_SELECTED_MANUFACTURER, payload);
  },
  setSelectedModel({ commit }, payload) {
    commit(SET_SELECTED_MODEL, payload);
  },
  setSelectedType({ commit }, payload) {
    commit(SET_SELECTED_TYPE, payload);
  },
  setStep({ commit }, step) {
    commit(SET_STEP, step);
  },
  clearCarSelector({ commit }) {
    commit(SET_SELECTED_MANUFACTURER, '');
    commit(SET_SELECTED_MODEL, '');
    commit(SET_SELECTED_TYPE, '');
    commit(SET_STEP, 1);
    commit(SET_SEARCH_BY_KBA_NUMBER, false);
  },
  setSearchByKbaNumber({ commit }, payload) {
    commit(SET_SEARCH_BY_KBA_NUMBER, payload);
  },
  async getCarHistory({ commit }) {
    const response = await CarService.getCarHistory();

    commit(SET_CAR_HISTORY, response.data);

    return response;
  },
  async setCarHistory({ commit }, carTag) {
    const response = await CarService.setCarHistory(carTag);

    return response;
  },
  async getCarGroupByGermanCode({ commit }, payload) {
    const response = await CarService.getCarGroupByGermanCode(payload);

    if (response.status === networkStatus['ok']) {
      commit(SET_CAR_GROUP_BY_GERMAN_CODE, response.data);
    } else {
      return Promise.reject(response.data);
    }

    return response;
  },
};

const getters = {
  getGroupedTypes: (state) => {
    let result;
    result = [
      ...state.types.reduce((acc, cur) => {
        const key = `${cur.type}${cur.productionFrom}${cur.productionTo}`;
        const item = acc.get(key) ?
          {
            ...acc.get(key),
            children: [
              ...acc.get(key).children,
              cur,
            ],
          } :
          {
            ...cur,
            children: [cur],
          };
        return acc.set(key, item);
      }, new Map()).values(),
    ].map((group) => {
      return {
        ...group,
        children: group.children,
      };
    })
      .sort((a,b) => b.productionFrom - a.productionFrom)
      .sort((a,b) => a.type.localeCompare(b.type));
    return result;
  },
  getSortedVersions: (state) => {
    return state.versions.sort((a,b) => a.version.localeCompare(b.version));
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
