<template>
  <div>
    <navigation rims-config />
    <v-main class="rims-config__layout">
      <transition name="page">
        <slot />
      </transition>
    </v-main>
    <footer-menu />
  </div>
</template>

<script>
import Navigation from '@/components/Navigation';
import FooterMenu from '@/components/FooterMenu';

export default {
  components: {
    Navigation,
    FooterMenu,
  },
};
</script>
