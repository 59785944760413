<template>
  <base-rimconfig-card-car-selector
    class="rimconfig-card-car-selector-version"
    :title="$t('langkey.car-selector-select-version')"
  >
    <template #custom-header-content>
      <ul
        class="card-breadcrumb"
      >
        <li
          v-for="(item, index) in versionBreadcrumbItems"
          :key="`${item.key}${index}`"
          class="card-breadcrumb__item"
          @click="onClickBreadcrumbItem(item)"
        >
          {{ $t(item.label) }}
        </li>
      </ul>
    </template>
    <template #body>
      <div class="car-selector-version__container">
        <div v-bar>
          <v-data-table
            v-if="!isKbaVersions"
            class="primary-style version-table"
            :headers="tableFields"
            :items="getSortedVersions"
            :loading="isLoading"
            :no-data-text="$t('langkey.no-tpms-found-for-car')"
            fixed-header
            hide-default-footer
            height="372"
            single-select
            disable-pagination
            @click:row="onRowSelected"
          >
            <template
              v-for="(field, index) in tableFields"
              v-slot:[`header.${field.value}`]="{ header }"
            >
              <div
                :key="`${field.value}${index}`"
                class="d-flex align-center justify-center"
              >
                <span>{{ $t(header.text) }}</span>
                <div class="icon__sort" />
              </div>
            </template>
            <template v-slot:[`item.productionFrom`]="{ item }">
              {{ getProductionText(item) }}
            </template>
            <template v-slot:[`item.power`]="{ item }">
              {{ `${item.power}/${parseInt(item.power * 1.3596)}` }}
            </template>
            <template v-slot:[`item.fuelCode`]="{ item }">
              {{ $t(`langkey.${item.fuelCode}`) }}
            </template>
          </v-data-table>
          <v-data-table
            v-else
            class="primary-style version-table"
            :headers="tableFields"
            :items="carGroupByGermanCode"
            :loading="isLoading"
            fixed-header
            hide-default-footer
            height="372"
            single-select
            disable-pagination
            @click:row="onRowSelected"
          >
            <template
              v-for="(field, index) in tableFields"
              v-slot:[`header.${field.value}`]="{ header }"
            >
              <div
                :key="`${field.value}${index}`"
                class="d-flex align-center justify-center"
              >
                <span>{{ $t(header.text) }}</span>
                <div class="icon__sort" />
              </div>
            </template>
            <template v-slot:[`item.productionFrom`]="{ item }">
              {{ getProductionText(item) }}
            </template>
            <template v-slot:[`item.power`]="{ item }">
              {{ `${item.power}/${parseInt(item.power * 1.3596)}` }}
            </template>
            <template v-slot:[`item.fuelCode`]="{ item }">
              {{ $t(`langkey.${item.fuelCode}`) }}
            </template>
          </v-data-table>
        </div>
      </div>
    </template>
  </base-rimconfig-card-car-selector>
</template>

<style lang="scss">
@import '@/styles/components/rimconfig-card-car-selector-version.scss';
</style>

<script>
import { tableFields } from '@/configs/components/tableVersionCarSelector.js';
import { mapState, mapGetters } from 'vuex';
import { breadcrumbItems } from '@/pages/rims-config/configurator/configs.js';

export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    isTpms: {
      type: Boolean,
      default: false,
    },
    isKbaVersions: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedVersion: {},
    };
  },
  computed: {
    ...mapState({
      versionsConfigurator: (state) => state.rimconfigCarSelector.versions,
      carGroupByGermanCodeConfigurator: (state) => state.rimconfigCarSelector.carGroupByGermanCode,
    }),
    ...mapState({
      versionsTpms: (state) => state.rimconfigCarSelectorTpms.versions,
      carGroupByGermanCodeTpms: (state) => state.rimconfigCarSelectorTpms.carGroupByGermanCode,
    }),
    ...mapGetters({
      getSortedVersionsConfigurator: 'rimconfigCarSelector/getSortedVersions',
      getSortedVersionsTpms: 'rimconfigCarSelectorTpms/getSortedVersions',
    }),
    versions() {
      return this.isTpms ? this.versionsTpms : this.versionsConfigurator;
    },
    carGroupByGermanCode() {
      return this.isTpms ? this.carGroupByGermanCodeTpms : this.carGroupByGermanCodeConfigurator;
    },
    getSortedVersions() {
      if (this.isTpms) {
        return this.getSortedVersionsTpms.filter((item) => {
          const productionFrom = item.productionFrom.split('-');
          const productionFromDate = new Date(productionFrom[0], (Number(productionFrom[1]) - 1), productionFrom[2]);
          const productionFromTpmsDate = new Date(2013, 0, 1);
          return productionFromDate.getTime() > productionFromTpmsDate.getTime();
        });
      } else {
        return this.getSortedVersionsConfigurator;
      }
    },
    tableFields() {
      return tableFields;
    },
    breadcrumbItems() {
      return breadcrumbItems;
    },
    versionBreadcrumbItems() {
      if (!this.isKbaVersions) {
        return this.breadcrumbItems.slice(0,4);
      } else {
        return this.breadcrumbItems.slice(0,1).concat(this.breadcrumbItems.slice(3));
      }
    },
  },
  methods: {
    back() {
      this.$emit('on-click-back');
    },
    onRowSelected(item) {
      this.selectedVersion = item;
      this.$emit('row-selected', this.selectedVersion);
    },
    onClickBreadcrumbItem(item) {
      this.$emit('on-click-breadcrumb-item', item);
    },
    getProductionText({ productionFrom, productionTo }) {
      if (!this.isKbaVersions) {
        const from = productionFrom.split('-');
        const to = productionTo.split('-');
        return `${from[0] !== '0000' ? from[0] : ''} - ${to[0] !== '0000' ? to[0] : ''}`;
      } else {
        const from = productionFrom;
        const to = productionTo !== '0' ? productionTo : '';
        return `${from} - ${to}`;
      }
    },
  },
};
</script>