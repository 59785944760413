import { TyreService, AssignService } from '@/services';
import { networkStatus } from '@/constants/networkStatus';
import { tyreAllAvailableVehicleTypes } from '@/constants/tyreMenuGroup';
import { usageTranslationItems } from '@/constants/usageTranslation';

import {
  SET_TYRE_DATA_SEARCH,
  UPDATE_TYRE_DATA_SEARCH,
  SET_TYRE_CONCATENATED_SEARCH,
  SET_TYRE_DATA_CAR,
  UPDATE_TYRE_DATA_CAR,
  SET_TYRE_CONCATENATED_CAR,
  SET_TYRE_DATA_TRUCK,
  UPDATE_TYRE_DATA_TRUCK,
  SET_TYRE_CONCATENATED_TRUCK,
  SET_TYRE_DATA_MOTORBIKE,
  UPDATE_TYRE_DATA_MOTORBIKE,
  SET_TYRE_CONCATENATED_MOTORBIKE,
  SET_TYRE_DATA_INDUSTRIAL,
  UPDATE_TYRE_DATA_INDUSTRIAL,
  SET_TYRE_CONCATENATED_INDUSTRIAL,
  SET_TYRE_DATA_AGRICULTURE,
  UPDATE_TYRE_DATA_AGRICULTURE,
  SET_TYRE_CONCATENATED_AGRICULTURE,
  SET_TYRE_DATA_EARTHMOVER,
  UPDATE_TYRE_DATA_EARTHMOVER,
  SET_TYRE_CONCATENATED_EARTHMOVER,
  SET_TYRE_ASSIGNS,
} from '../mutationTypes';
import { isObjectEmpty } from '@/utils';

const state = {
  tyreDataCar: [],
  tyreConcatenatedCar: {},

  tyreDataTruck: [],
  tyreConcatenatedTruck: {},

  tyreDataMotorbike: [],
  tyreConcatenatedMotorbike: {},

  tyreDataIndustrial: [],
  tyreConcatenatedIndustrial: {},

  tyreDataAgriculture: [],
  tyreConcatenatedAgriculture: {},

  tyreDataEarthMover: [],
  tyreConcatenatedEarthMover: {},

  tyreDataSearch: [],
  tyreConcatenatedSearch: {},

  tyreAssignByTyreTag: {},
};

const mutations = {
  [SET_TYRE_DATA_SEARCH](stateData, data) {
    stateData.tyreDataSearch = data;
  },
  [UPDATE_TYRE_DATA_SEARCH](stateData, data) {
    stateData.tyreDataSearch = [
      ...stateData.tyreDataSearch,
      ...data,
    ];
  },
  [SET_TYRE_CONCATENATED_SEARCH](stateData, data) {
    stateData.tyreConcatenatedSearch = data;
  },
  [SET_TYRE_DATA_CAR](stateData, data) {
    stateData.tyreDataCar = data;
  },
  [UPDATE_TYRE_DATA_CAR](stateData, data) {
    stateData.tyreDataCar = [
      ...stateData.tyreDataCar,
      ...data,
    ];
  },
  [SET_TYRE_CONCATENATED_CAR](stateData, data) {
    stateData.tyreConcatenatedCar = data;
  },
  [SET_TYRE_DATA_TRUCK](stateData, data) {
    stateData.tyreDataTruck = data;
  },
  [UPDATE_TYRE_DATA_TRUCK](stateData, data) {
    stateData.tyreDataTruck = [
      ...stateData.tyreDataTruck,
      ...data,
    ];
  },
  [SET_TYRE_CONCATENATED_TRUCK](stateData, data) {
    stateData.tyreConcatenatedTruck = data;
  },

  [SET_TYRE_DATA_MOTORBIKE](stateData, data) {
    stateData.tyreDataMotorbike = data;
  },
  [UPDATE_TYRE_DATA_MOTORBIKE](stateData, data) {
    stateData.tyreDataMotorbike = [
      ...stateData.tyreDataMotorbike,
      ...data,
    ];
  },
  [SET_TYRE_CONCATENATED_MOTORBIKE](stateData, data) {
    stateData.tyreConcatenatedMotorbike = data;
  },

  [SET_TYRE_DATA_INDUSTRIAL](stateData, data) {
    stateData.tyreDataIndustrial = data;
  },
  [UPDATE_TYRE_DATA_INDUSTRIAL](stateData, data) {
    stateData.tyreDataIndustrial = [
      ...stateData.tyreDataIndustrial,
      ...data,
    ];
  },
  [SET_TYRE_CONCATENATED_INDUSTRIAL](stateData, data) {
    stateData.tyreConcatenatedIndustrial = data;
  },

  [SET_TYRE_DATA_AGRICULTURE](stateData, data) {
    stateData.tyreDataAgriculture = data;
  },
  [UPDATE_TYRE_DATA_AGRICULTURE](stateData, data) {
    stateData.tyreDataAgriculture = [
      ...stateData.tyreDataAgriculture,
      ...data,
    ];
  },
  [SET_TYRE_CONCATENATED_AGRICULTURE](stateData, data) {
    stateData.tyreConcatenatedAgriculture = data;
  },

  [SET_TYRE_DATA_EARTHMOVER](stateData, data) {
    stateData.tyreDataEarthMover = data;
  },
  [UPDATE_TYRE_DATA_EARTHMOVER](stateData, data) {
    stateData.tyreDataEarthMover = [
      ...stateData.tyreDataEarthMover,
      ...data,
    ];
  },
  [SET_TYRE_CONCATENATED_EARTHMOVER](stateData, data) {
    stateData.tyreConcatenatedEarthMover = data;
  },

  [SET_TYRE_ASSIGNS](stateData, { tyreTag, data}) {
    stateData.tyreAssignByTyreTag = {
      ...stateData.tyreAssignByTyreTag,
      [tyreTag]: data,
    };
  },
};

const actions = {
  async getTyres({ commit }, payload = {}) {
    let adjustedPayload = payload;
    if (payload.isSearch && payload.isVehicleType) {
      adjustedPayload = {
        ...adjustedPayload,
      };
    } else if (payload.vehicleTypes === 'van') {
      adjustedPayload = {
        ...adjustedPayload,
        vehicleTypes: 'lightTruck',
      };
    }

    const response = await TyreService.getTyres(adjustedPayload);

    if (payload.isCart) {
      return response;
    }

    if (response.status === networkStatus['ok']) {
      if (response.data.length === 0 && payload.isSearchFromNavigation) {
        return Promise.reject('noResult');
      }
      if (payload.isSearch) {
        commit(SET_TYRE_DATA_SEARCH, response.data);
      } else if (
        payload.vehicleTypes === 'car' ||
        payload.vehicleTypes === 'offRoad' ||
        payload.vehicleTypes === 'van'
      ) {
        commit(SET_TYRE_DATA_CAR, response.data);
      } else if (payload.vehicleTypes === 'truck') {
        commit(SET_TYRE_DATA_TRUCK, response.data);
      } else if (payload.vehicleTypes === 'bike') {
        commit(SET_TYRE_DATA_MOTORBIKE, response.data);
      } else if (payload.vehicleTypes === 'industrial') {
        commit(SET_TYRE_DATA_INDUSTRIAL, response.data);
      } else if (payload.vehicleTypes === 'agriculture') {
        commit(SET_TYRE_DATA_AGRICULTURE, response.data);
      } else if (payload.vehicleTypes === 'earthMover') {
        commit(SET_TYRE_DATA_EARTHMOVER, response.data);
      }
    }

    return response;
  },
  async getTyresNextPage({ commit, state: stateData }, payload = {}) {
    let adjustedPayload = payload;
    if (payload.isSearch && payload.isVehicleType) {
      adjustedPayload = {
        ...adjustedPayload,
      };
    } else if (payload.vehicleTypes === 'van') {
      adjustedPayload = {
        ...adjustedPayload,
        vehicleTypes: 'lightTruck',
      };
    }

    const response = await TyreService.getTyres(adjustedPayload);

    if (response.status === networkStatus['ok']) {
      if (response.data.length === 0) {
        if (payload.isSearch) {
          const removeLoadingItems = stateData.tyreDataSearch.slice();
          commit(SET_TYRE_DATA_SEARCH, [
            ...removeLoadingItems,
            { isNoMoreResult: true },
          ]);
        } else if (
          payload.vehicleTypes === 'car' ||
          payload.vehicleTypes === 'offRoad' ||
          payload.vehicleTypes === 'van'
        ) {
          const removeLoadingItems = stateData.tyreDataCar.slice();
          commit(SET_TYRE_DATA_CAR, [
            ...removeLoadingItems,
            { isNoMoreResult: true },
          ]);
        } else if (payload.vehicleTypes === 'truck') {
          const removeLoadingItems = stateData.tyreDataTruck.slice();
          commit(SET_TYRE_DATA_TRUCK, [
            ...removeLoadingItems,
            { isNoMoreResult: true },
          ]);
        } else if (payload.vehicleTypes === 'bike') {
          const removeLoadingItems = stateData.tyreDataMotorbike.slice();
          commit(SET_TYRE_DATA_MOTORBIKE, [
            ...removeLoadingItems,
            { isNoMoreResult: true },
          ]);
        } else if (payload.vehicleTypes === 'industrial') {
          const removeLoadingItems = stateData.tyreDataIndustrial.slice();
          commit(SET_TYRE_DATA_INDUSTRIAL, [
            ...removeLoadingItems,
            { isNoMoreResult: true },
          ]);
        } else if (payload.vehicleTypes === 'agriculture') {
          const removeLoadingItems = stateData.tyreDataMotorbike.slice();
          commit(SET_TYRE_DATA_AGRICULTURE, [
            ...removeLoadingItems,
            { isNoMoreResult: true },
          ]);
        } else if (payload.vehicleTypes === 'earthMover') {
          const removeLoadingItems = stateData.tyreDataEarthMover.slice();
          commit(SET_TYRE_DATA_EARTHMOVER, [
            ...removeLoadingItems,
            { isNoMoreResult: true },
          ]);
        }
        return Promise.reject('noResult');
      }

      if (payload.isSearch) {
        commit(UPDATE_TYRE_DATA_SEARCH, response.data);
      } else if (
        payload.vehicleTypes === 'car' ||
        payload.vehicleTypes === 'offRoad' ||
        payload.vehicleTypes === 'van'
      ) {
        commit(UPDATE_TYRE_DATA_CAR, response.data);
      } else if (payload.vehicleTypes === 'truck') {
        commit(UPDATE_TYRE_DATA_TRUCK, response.data);
      } else if (payload.vehicleTypes === 'bike') {
        commit(UPDATE_TYRE_DATA_MOTORBIKE, response.data);
      } else if (payload.vehicleTypes === 'industrial') {
        commit(UPDATE_TYRE_DATA_INDUSTRIAL, response.data);
      } else if (payload.vehicleTypes === 'agriculture') {
        commit(UPDATE_TYRE_DATA_AGRICULTURE, response.data);
      } else if (payload.vehicleTypes === 'earthMover') {
        commit(UPDATE_TYRE_DATA_EARTHMOVER, response.data);
      }
    }

    return response;
  },
  async getTyresByTyreTag({ commit }, payload = {}) {
    const response = await TyreService.getTyresByTyreTag(payload);
    return response;
  },
  async getTyresConcatenatedSearch({ commit }, payload = {}) {
    const response = await TyreService.getTyresConcatenated(payload);
    if (response.status === networkStatus['ok']) {
      commit(SET_TYRE_CONCATENATED_SEARCH, response.data);
    }
    return response;
  },
  async getTyresConcatenated({ commit }, payload = {}) {
    let adjustedPayload = {...payload};
    if (payload.isSearch && payload.isVehicleType) {
      adjustedPayload = {
        ...adjustedPayload,
      };
    } else if (payload.vehicleTypes === 'van') {
      adjustedPayload = {
        ...adjustedPayload,
        vehicleTypes: 'lightTruck',
      };
    }

    delete adjustedPayload.commitVehicleType;

    const response = await TyreService.getTyresConcatenated(adjustedPayload);

    if (response.status === networkStatus['ok']) {
      if (payload.isSearch) {
        commit(SET_TYRE_CONCATENATED_SEARCH, response.data);
      } else if (payload.vehicleTypes === 'car' || payload.commitVehicleType === 'car') {
        commit(SET_TYRE_CONCATENATED_CAR, response.data);
      } else if (payload.vehicleTypes === 'truck') {
        commit(SET_TYRE_CONCATENATED_TRUCK, response.data);
      } else if (payload.vehicleTypes === 'bike') {
        commit(SET_TYRE_CONCATENATED_MOTORBIKE, response.data);
      } else if (payload.vehicleTypes === 'industrial') {
        commit(SET_TYRE_CONCATENATED_INDUSTRIAL, response.data);
      } else if (payload.vehicleTypes === 'agriculture') {
        commit(SET_TYRE_CONCATENATED_AGRICULTURE, response.data);
      } else if (payload.vehicleTypes === 'earthMover') {
        commit(SET_TYRE_CONCATENATED_EARTHMOVER, response.data);
      }
    }

    return response;
  },
  async getTyreAssigns({ commit }, { tyreTag, assigns, expressDelivery, marketplace_address }) {
    const response = await AssignService.getAssigns({
      ids: assigns.join(),
      expressDelivery,
      marketplace_address,
    });

    if (response.status === networkStatus['ok']) {
      commit(SET_TYRE_ASSIGNS, {
        tyreTag: tyreTag,
        data: response.data,
      });
    }

    return response;
  },
  async searchTyreResult({ dispatch }, { searchValue, filters = {} }) {
    let adjustedFilters = filters;
    if (filters.vehicleTypes === 'van') {
      adjustedFilters = {
        ...adjustedFilters,
        vehicleTypes: 'lightTruck',
      };
    }

    const response = await TyreService.getTyreTagFromSearch({ searchValue, adjustedFilters });

    if (response.status === networkStatus['ok']) {
      if (response.data.length === 0) {
        return Promise.reject('noResult');
      }
      return dispatch('getTyres', {
        ...adjustedFilters,
        isSearch: true,
        tags: response.data.join(),
      });
    }
    return response;
  },
  async searchTyreResultNextPage({ commit, dispatch, state: stateData  }, { searchValue, filters = {} }) {
    let adjustedFilters = filters;
    if (filters.vehicleTypes === 'van') {
      adjustedFilters = {
        ...adjustedFilters,
        vehicleTypes: 'lightTruck',
      };
    }

    const response = await TyreService.getTyreTagFromSearch({ searchValue, adjustedFilters });

    if (response.status === networkStatus['ok']) {
      if (response.data.length === 0) {
        const removeLoadingItems = stateData.tyreDataSearch.slice();
        commit(SET_TYRE_DATA_SEARCH, [
          ...removeLoadingItems,
          { isNoMoreResult: true },
        ]);
        return Promise.reject('noResult');
      }

      return dispatch('getTyresNextPage', {
        ...adjustedFilters,
        isSearch: true,
        tags: response.data.join(),
      });
    }
    return response;
  },
  clearTyresTable({ commit }, payload = {}) {
    if (payload.isSearch) {
      commit(SET_TYRE_DATA_SEARCH, []);
    } else if (payload.commitVehicleType === 'car') {
      commit(SET_TYRE_DATA_CAR, []);
    } else if (payload.vehicleTypes === 'truck') {
      commit(SET_TYRE_DATA_TRUCK, []);
    } else if (payload.vehicleTypes === 'bike') {
      commit(SET_TYRE_DATA_MOTORBIKE, []);
    } else if (payload.vehicleTypes === 'industrial') {
      commit(SET_TYRE_DATA_INDUSTRIAL, []);
    } else if (payload.vehicleTypes === 'agriculture') {
      commit(SET_TYRE_DATA_AGRICULTURE, []);
    } else if (payload.vehicleTypes === 'earthMover') {
      commit(SET_TYRE_DATA_EARTHMOVER, []);
    }
  },
};

const getters = {
  getTyreSearchSelectFilterOptions: (stateData) => (fields) => {
    if (isObjectEmpty(stateData.tyreConcatenatedSearch)) {
      return {};
    }

    let result = {};
    fields.forEach((field) => {
      const options = stateData.tyreConcatenatedSearch[field.key]
        ? stateData.tyreConcatenatedSearch[field.key]
          .filter((val) => val !== '' && val !== ' ')
        : [];

      if (field.customOptions) {
        result = {
          ...result,
          [field.key]: field.customOptions,
        };
      } else if (field.key === 'aspectRatio') {
        options.splice(0, 0, ' ');
        result = {
          ...result,
          [field.key]: options.map((value) => {
            if (value === ' ') {
              return {
                label: '-',
                value: value,
              };
            } else {
              return {
                label: value,
                value: value,
              };
            }
          }),
        };
      } else {
        result = {
          ...result,
          [field.key]: options.map((value) => {
            return {
              label: value,
              value: value,
            };
          }),
        };
      }
    });

    return result;
  },

  getTyreCarSelectFilterOptions: (stateData) => (fields) => {
    if (isObjectEmpty(stateData.tyreConcatenatedCar)) {
      return {};
    }

    let result = {};
    fields.forEach((field) => {
      const options = stateData.tyreConcatenatedCar[field.key]
        ? stateData.tyreConcatenatedCar[field.key]
          .filter((val) => val !== '' && val !== ' ')
        : [];

      if (field.customOptions) {
        result = {
          ...result,
          [field.key]: field.customOptions,
        };
      } else if (field.key === 'aspectRatio') {
        options.splice(0, 0, ' ');
        result = {
          ...result,
          [field.key]: options.map((value) => {
            if (value === ' ') {
              return {
                label: '-',
                value: value,
              };
            } else {
              return {
                label: value,
                value: value,
              };
            }
          }),
        };
      } else {
        result = {
          ...result,
          [field.key]: options.map((value) => {
            return {
              label: value,
              value: value,
            };
          }),
        };
      }
    });

    return result;
  },
  getTyreTruckSelectFilterOptions: (stateData) => (fields) => {
    if (isObjectEmpty(stateData.tyreConcatenatedTruck)) {
      return {};
    }

    let result = {};
    fields.forEach((field) => {
      const options = stateData.tyreConcatenatedTruck[field.key]
        ? stateData.tyreConcatenatedTruck[field.key]
          .filter((val) => val !== '' && val !== ' ')
        : [];

      if (field.customOptions) {
        result = {
          ...result,
          [field.key]: field.customOptions,
        };
      } else if (field.key === 'aspectRatio') {
        options.splice(0, 0, ' ');
        result = {
          ...result,
          [field.key]: options.map((value) => {
            if (value === ' ') {
              return {
                label: '-',
                value: value,
              };
            } else {
              return {
                label: value,
                value: value,
              };
            }
          }),
        };
      } else if (field.key === 'usage') {
        result = {
          ...result,
          [field.key]: options.map((value) => {
            let langkeyValue = '';
            if (value === 'CITY') {
              langkeyValue = 'langkey.truck_tyre_city';
            } else if (value === 'CITY BUS') {
              langkeyValue = 'langkey.truck_tyre_citybus';
            } else if (value === 'CONSTRUCTION') {
              langkeyValue = 'langkey.truck_tyre_construction';
            } else if (value === 'LONG HAUL') {
              langkeyValue = 'langkey.truck_tyre_longhaul';
            } else if (value === 'OFF ROAD') {
              langkeyValue = 'langkey.truck_tyre_offroad';
            } else if (value === 'REGIONAL + LONG HAUL') {
              langkeyValue = 'langkey.truck_tyre_regional_long';
            } else if (value === 'REGIONAL HAUL') {
              langkeyValue = 'langkey.truck_tyre_regional';
            }
            return {
              label: langkeyValue,
              value: value,
            };
          }),
        };
      } else if (field.key === 'aspectRatio') {
        options.splice(0, 0, ' ');
        result = {
          ...result,
          [field.key]: options.map((value) => {
            if (value === ' ') {
              return {
                label: '-',
                value: value,
              };
            } else {
              return {
                label: value,
                value: value,
              };
            }
          }),
        };
      } else {
        result = {
          ...result,
          [field.key]: options.map((value) => {
            return {
              label: value,
              value: value,
            };
          }),
        };
      }
    });

    return result;
  },
  getTyreMotorbikeSelectFilterOptions: (stateData) => (fields) => {
    if (isObjectEmpty(stateData.tyreConcatenatedMotorbike)) {
      return {};
    }

    let result = {};
    fields.forEach((field) => {
      const options = stateData.tyreConcatenatedMotorbike[field.key]
        ? stateData.tyreConcatenatedMotorbike[field.key]
          .filter((val) => val !== '' && val !== ' ')
        : [];

      if (field.customOptions) {
        result = {
          ...result,
          [field.key]: field.customOptions,
        };
      } else if (field.key === 'aspectRatio') {
        options.splice(0, 0, ' ');
        result = {
          ...result,
          [field.key]: options.map((value) => {
            if (value === ' ') {
              return {
                label: '-',
                value: value,
              };
            } else {
              return {
                label: value,
                value: value,
              };
            }
          }),
        };
      } else {
        result = {
          ...result,
          [field.key]: options.map((value) => {
            return {
              label: value,
              value: value,
            };
          }),
        };
      }
    });

    return result;
  },
  getTyreIndustrialSelectFilterOptions: (stateData) => (fields) => {
    if (isObjectEmpty(stateData.tyreConcatenatedIndustrial)) {
      return {};
    }

    let result = {};
    fields.forEach((field) => {
      const options = stateData.tyreConcatenatedIndustrial[field.key]
        ? stateData.tyreConcatenatedIndustrial[field.key]
          .filter((val) => val !== '' && val !== ' ')
        : [];

      if (field.customOptions) {
        result = {
          ...result,
          [field.key]: field.customOptions,
        };
      } else if (field.key === 'aspectRatio') {
        options.splice(0, 0, ' ');
        result = {
          ...result,
          [field.key]: options.map((value) => {
            if (value === ' ') {
              return {
                label: '-',
                value: value,
              };
            } else {
              return {
                label: value,
                value: value,
              };
            }
          }),
        };
      } else if (field.key === 'usage') {
        result = {
          ...result,
          [field.key]: options.map((value) => {
            return {
              label: usageTranslationItems[value.toLowerCase()],
              value: value,
            };
          }),
        };
      } else {
        result = {
          ...result,
          [field.key]: options.map((value) => {
            return {
              label: value,
              value: value,
            };
          }),
        };
      }
    });

    return result;
  },
  getTyreAgricultureSelectFilterOptions: (stateData) => (fields) => {
    if (isObjectEmpty(stateData.tyreConcatenatedAgriculture)) {
      return {};
    }

    let result = {};
    fields.forEach((field) => {
      const options = stateData.tyreConcatenatedAgriculture[field.key]
        ? stateData.tyreConcatenatedAgriculture[field.key]
          .filter((val) => val !== '' && val !== ' ')
        : [];

      if (field.customOptions) {
        result = {
          ...result,
          [field.key]: field.customOptions,
        };
      } else if (field.key === 'aspectRatio') {
        options.splice(0, 0, ' ');
        result = {
          ...result,
          [field.key]: options.map((value) => {
            if (value === ' ') {
              return {
                label: '-',
                value: value,
              };
            } else {
              return {
                label: value,
                value: value,
              };
            }
          }),
        };
      } else if (field.key === 'usage') {
        result = {
          ...result,
          [field.key]: options.map((value) => {
            return {
              label: usageTranslationItems[value.toLowerCase()],
              value: value,
            };
          }),
        };
      } else {
        result = {
          ...result,
          [field.key]: options.map((value) => {
            return {
              label: value,
              value: value,
            };
          }),
        };
      }
    });

    return result;
  },
  getTyreEarthMoverSelectFilterOptions: (stateData) => (fields) => {
    if (isObjectEmpty(stateData.tyreConcatenatedEarthMover)) {
      return {};
    }

    let result = {};
    fields.forEach((field) => {
      const options = stateData.tyreConcatenatedEarthMover[field.key]
        ? stateData.tyreConcatenatedEarthMover[field.key]
          .filter((val) => val !== '' && val !== ' ')
        : [];

      if (field.customOptions) {
        result = {
          ...result,
          [field.key]: field.customOptions,
        };
      } else if (field.key === 'aspectRatio') {
        options.splice(0, 0, ' ');
        result = {
          ...result,
          [field.key]: options.map((value) => {
            if (value === ' ') {
              return {
                label: '-',
                value: value,
              };
            } else {
              return {
                label: value,
                value: value,
              };
            }
          }),
        };
      } else if (field.key === 'usage') {
        result = {
          ...result,
          [field.key]: options.map((value) => {
            return {
              label: usageTranslationItems[value.toLowerCase()],
              value: value,
            };
          }),
        };
      } else {
        result = {
          ...result,
          [field.key]: options.map((value) => {
            return {
              label: value,
              value: value,
            };
          }),
        };
      }
    });

    return result;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
