<template>
  <div>
    <navigation />
    <v-main>
      <slot />
    </v-main>
    <footer-menu />
  </div>
</template>

<script>
import Navigation from '@/components/Navigation';
import FooterMenu from '@/components/FooterMenu';

export default {
  components: {
    Navigation,
    FooterMenu,
  },
};
</script>
