var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"660","persistent":""},model:{value:(_vm.showResetInitialPassword),callback:function ($$v) {_vm.showResetInitialPassword=$$v},expression:"showResetInitialPassword"}},[_c('v-card',{staticClass:"dialog base-reset-initial-password-dialog"},[_c('v-card-title',[_c('span',[_vm._v(_vm._s(_vm.$t('langkey.password-change')))]),_c('v-btn',{staticClass:"button__close",attrs:{"icon":"","plain":""},on:{"click":_vm.hide}},[_c('icon-close',{attrs:{"size":"12"}})],1)],1),_c('v-card-text',[_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('langkey.password-change-title'))}}),_c('hr'),_c('div',{staticClass:"password-policy"},[_c('ul',[_c('li',{class:_vm.errorAtLeast ? 'error-text' : null},[_vm._v(" "+_vm._s(_vm.$t('langkey.password-policy-1'))+" ")]),_c('li',{class:_vm.errorCase ? 'error-text' : null},[_vm._v(" "+_vm._s(_vm.$t('langkey.password-policy-2'))+" ")])])]),_c('v-form',{staticClass:"reset-initial-password__form",on:{"submit":function($event){$event.preventDefault();return _vm.onResetPassword.apply(null, arguments)}}},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"type":"submit"}}),(_vm.$route.name !== 'reset-password')?_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('p',[_vm._v(_vm._s(_vm.$t('langkey.old-password'))+":")])]),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{class:[
                _vm.isErrorOldPasswordInput ? 'show-error' : null
              ],attrs:{"label":_vm.$t('langkey.old-password'),"type":"password","solo":"","hide-details":""},model:{value:(_vm.oldPassword),callback:function ($$v) {_vm.oldPassword=$$v},expression:"oldPassword"}})],1)],1):_vm._e(),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('p',[_vm._v(_vm._s(_vm.$t('langkey.new-password'))+":")])]),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{class:[
                _vm.isErrorPasswordInput ? 'show-error' : null
              ],attrs:{"label":_vm.$t('langkey.new-password'),"type":"password","solo":"","hide-details":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)],1),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('p',[_vm._v(_vm._s(_vm.$t('langkey.new-password-confirm'))+":")])]),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{class:[
                _vm.isErrorConfirmPasswordInput ? 'show-error' : null
              ],attrs:{"label":_vm.$t('langkey.new-password-confirm'),"type":"password","solo":"","hide-details":""},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})],1)],1)],1)],1),_c('v-card-actions',[_c('transition',{attrs:{"name":"fade"}},[(_vm.errorTexts.length > 0)?_c('div',{staticClass:"error-texts"},_vm._l((_vm.errorTexts),function(error,index){return _c('p',{key:`errorText${index}`},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t(error.text))}})])}),0):_vm._e()]),_c('v-spacer'),_c('v-btn',{staticClass:"icon",attrs:{"color":"primary"},on:{"click":_vm.onResetPassword}},[_vm._v(" "+_vm._s(_vm.$t('langkey.password-change'))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }