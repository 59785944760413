import { SecurityService } from '@/services';
import { networkStatus } from '@/constants/networkStatus';
import { setCookie } from '@/utils/js-cookie';

import {
  SET_LOGIN_TOKEN,
} from '../mutationTypes';

const state = {
  loginToken: '',
};

const mutations = {
  [SET_LOGIN_TOKEN](stateData, data) {
    stateData.loginToken = data;
  },
};

const actions = {
  async loginCheck({ commit, dispatch }, payload) {
    const response = await SecurityService.loginCheck(payload);

    if (response.status === networkStatus['ok']) {
      const accessToken = response.data.token;
      setCookie('user_access_token', accessToken);
      commit(SET_LOGIN_TOKEN, accessToken);
    }
    return response;
  },
  async loginOsKeyCheck({ commit }, payload) {
    const response = await SecurityService.loginOsKeyCheck(payload);

    if (response.status === networkStatus['ok']) {
      const accessToken = response.data.token;
      setCookie('user_access_token', accessToken);
      commit(SET_LOGIN_TOKEN, accessToken);
    }
    return response;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};