/**
 * To check is it desktop size, use `isDesktop` property
 */
export const windowSize = {
  data() {
    return {
      windowWidth: 0,
      windowHeight: 0,
    };
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
    },
    dispatch(componentName, eventName, ...rest) {
      let parent = this.$parent || this.$root;
      let name = parent.$options.name;

      while (parent && (!name || name !== componentName)) {
        parent = parent.$parent;
        if (parent) {
          name = parent.$options.name;
        }
      }

      if (parent) {
        parent.$emit.apply(parent, [eventName].concat(rest));
      }
    },
  },
};

export const touchScreenDevice = {
  data() {
    return {
      hasTouchScreen: true,
      windowWidth: 0,
    };
  },
  computed: {
    isTablet() {
      return this.hasTouchScreen && this.windowWidth < 1300 && this.windowWidth >= 768;
    },
    isMobile() {
      return this.hasTouchScreen && this.windowWidth < 768;
    },
  },
  mounted() {
    this.checkTouchScreen();
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    checkTouchScreen() {
      if ('maxTouchPoints' in navigator) {
        this.hasTouchScreen = navigator.maxTouchPoints > 0;
      } else if ('msMaxTouchPoints' in navigator) {
        this.hasTouchScreen = navigator.msMaxTouchPoints > 0;
      } else {
        let mQ = window.matchMedia && matchMedia('(pointer:coarse)');
        if (mQ && mQ.media === '(pointer:coarse)') {
          this.hasTouchScreen = !!mQ.matches;
        } else if ('orientation' in window) {
          this.hasTouchScreen = true; // deprecated, but good fallback
        } else {
          // Only as a last resort, fall back to user agent sniffing
          let UA = navigator.userAgent;
          this.hasTouchScreen = (
            /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
                /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA)
          );
        }
      }
    },
    handleResize() {
      this.windowWidth = window.innerWidth > window.screen.width ?
        window.screen.width :
        window.innerWidth;
    },
  },
};