import { ConfiguratorService, AssignService } from '@/services';
import { networkStatus } from '@/constants/networkStatus';

import {
  SET_WHEEL_CERTIFICATES,
  SET_KIT_ITEMS,
  SET_WHEEL_ASSIGNS,
} from '../mutationTypes';

const state = {
  wheelCertificates: [],
  kitItems: [],
  wheelAssignByWheelTag: {},
};

const mutations = {
  [SET_WHEEL_CERTIFICATES](stateData, data) {
    stateData.wheelCertificates = data;
  },
  [SET_KIT_ITEMS](stateData, data) {
    stateData.kitItems = data;
  },
  [SET_WHEEL_ASSIGNS](stateData, { wheelTag, data}) {
    stateData.wheelAssignByWheelTag = {
      ...stateData.wheelAssignByWheelTag,
      [wheelTag]: data,
    };
  },
};

const actions = {
  async getWheelCertificates({ commit }, payload) {
    const response = await ConfiguratorService.getWheelCertificates(payload);

    if (response.status === networkStatus['ok']) {
      commit(SET_WHEEL_CERTIFICATES, response.data);
    }

    return response;
  },
  async getKitMatches({ commit }, payload) {
    const response = await ConfiguratorService.getKitMatches(payload);

    commit(SET_KIT_ITEMS, response.data);

    return response;
  },
  async getWheelAssigns({ commit }, { wheelTag, assigns }) {
    const response = await AssignService.getAssigns({ ids: assigns.join() });

    if (response.status === networkStatus['ok']) {
      commit(SET_WHEEL_ASSIGNS, {
        wheelTag: wheelTag,
        data: response.data,
      });
    }

    return response;
  },
};

const getters = {
  getKitItemsSelectOptions: (stateData) => {
    if (stateData.kitItems.length === 0) {
      return {};
    }

    let result = {};

    const fields = ['manufacturer', 'class', 'type', 'kitHan'];
    fields.forEach((field) => {
      let kitItemsByField = stateData.kitItems.map((item) => item[field]);
      result[field] = kitItemsByField.reduce((acc,cur) => {
        return acc.includes(cur) ?
          acc :
          acc.concat(cur);
      }, [])
        .map((item) => {
          return {
            label: item,
            value: item,
          };
        });
    });

    return result;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};