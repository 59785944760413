<template>
  <div class="last-search-dropdown">
    <span class="label">{{ $t('langkey.previous-search') }}:</span>
    <v-menu
      v-model="isShowMenu"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          depressed
          plain
          v-bind="attrs"
          :class="[
            'dropdown',
            isShowMenu && 'show-menu'
          ]"
          :loading="isLoading"
          :disabled="!previousSearchCar.image"
          v-on="on"
          @click="openCarSearchHistoryDialog"
        >
          <div class="selected">
            <template v-if="previousSearchCar.image">
              <div class="text-wrapper">
                <span>{{ previousSearchCar.description }}</span>
                <span>{{ previousSearchCar.production }}</span>
                <span>{{ previousSearchCar.type }}</span>
              </div>
              <img
                :src="previousSearchCar.image"
              >
            </template>
            <div
              v-else
              class="text-wrapper"
            >
              <span style="color:var(--theme-gray-4);">{{ $t('langkey.previous-search-message') }}</span>
            </div>
          </div>
          <div
            :class="[
              'icon-caret',
              isShowMenu && 'open'
            ]"
          >
            <icon-caret />
          </div>
        </v-btn>
      </template>
    </v-menu>
  </div>
</template>

<style lang="scss">
@import '@/styles/components/last-search-dropdown.scss';
</style>

<script>
import IconCaret from '@/components/icons/IconCaret';
import { mapState } from 'vuex';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export default {
  components: {
    IconCaret,
  },
  data() {
    return {
      isShowMenu: false,
      isLoading: false,
      lastSearchItems: [],
    };
  },
  computed: {
    ...mapState({
      carHistory: (state) => state.rimconfigCarSelector.carHistory,
    }),
    previousSearchCar() {
      if (this.carHistory && this.carHistory.length > 0) {
        const lastestHistorySearchIndex = this.carHistory.length - 1;
        return {
          image: this.getImagePath(this.carHistory[lastestHistorySearchIndex].car.group, '250x200'),
          description: `${this.carHistory[lastestHistorySearchIndex].car.manufacturer} ${this.carHistory[lastestHistorySearchIndex].car.modelShortcut}`,
          production: this.getProduction(this.carHistory[lastestHistorySearchIndex].car),
          type: this.carHistory[lastestHistorySearchIndex].car.type,
        };
      } else {
        return {
          image: null,
          description: '',
          production: '',
          type: '',
        };
      }
    },
  },
  async created() {
    this.isLoading = true;
    try {
      await this.$store.dispatch('rimconfigCarSelector/getCarHistory');
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    getImagePath(group, size) {
      const imagePrefix = 'https://cdn.jfnet.de/carPerspective/';
      const imageSuffix = `/${size}-cc-xxxxxx.png`;
      return `${imagePrefix}${group.toLowerCase()}${imageSuffix}`;
    },
    getProduction({ productionFrom, productionTo }) {
      const from = productionFrom.split('-');
      const to = productionTo.split('-');
      return `${from[0] !== '0000' ? from[0] : ''} - ${to[0] !== '0000' ? to[0] : ''}`;
    },
    openCarSearchHistoryDialog() {
      this.$emit('open-car-search-history-dialog');
    },
  },
};
</script>