<template>
  <v-app-bar
    :height="rimsConfig ? 209 : 169"
    app
    :class="['navigation', isOptInStyle && 'is-optin']"
  >
    <div class="upper__wrapper">
      <router-link
        :to="{ name: 'tyre' }"
        class="logo"
        rel="nofollow"
      >
        <img :src="require('@/assets/images/Logo.svg')">
      </router-link>
      <navigation-search-bar />
      <div class="info__wrapper">
        <v-btn
          icon
          plain
          class="info__icon"
          @click="$refs.searchInfoRef.show()"
        >
          <icon-info
            color="var(--theme-primary)"
            size="50"
          />
        </v-btn>
        <span>{{ $t('langkey.explanation-info-button') }}</span>
      </div>
      <router-link
        v-if="userType === 'TEST USER'"
        class="link__register-full"
        :to="{ name: 'register-full' }"
        rel="nofollow"
        v-html="`${$t('langkey.register_full_text')}`"
      />
      <v-spacer />
      <div class="opt-in__wrapper">
        <opt-in-field />
      </div>
      <v-btn
        icon
        plain
        class="dealer-calculation-info__icon"
        @click="$refs.dealerCalculationDialog.show()"
      >
        <icon-info color="var(--theme-primary)" />
      </v-btn>
      <div class="right">
        <div class="divider" />
        <currency-dropdown
          :is-disable-selection="isOptIn"
        />
        <v-btn
          plain
          @click="openSupportModal"
        >
          {{ $t(navbar.support.title) }}
        </v-btn>
        <base-dialog
          ref="simpleDialog"
          :title="navbar.support.title"
        >
          <!-- <h1>{{ $t() }}</h1> -->
          <br>
          <p v-html="$t(navbar.support.content)" />
        </base-dialog>
        <!-- <base-dropdown
          :item="navbar.marketplace"
          :is-show-text="isShowText"
          :is-disable="navbar.marketplace.inactive"
        /> -->
        <base-dropdown
          :item="navbar.user"
          :is-show-text="isShowText"
          :is-opt-in="isOptIn"
          @logout="logout"
        >
          <template #content(user)>
            <v-list-item-avatar
              size="24"
              tile
            >
              <icon-user />
            </v-list-item-avatar>
            <v-list-item-title>
              {{ getUsername }}
            </v-list-item-title>
          </template>
          <template #content(adminArea)="{ data }">
            <v-list-item-avatar
              size="24"
              tile
            >
              <icon-admin-area :is-active="data.isActive" />
            </v-list-item-avatar>
            <v-list-item-title>
              {{ $t(data.title) }}
            </v-list-item-title>
          </template>
          <template #content(shop)="{ data }">
            <v-list-item-avatar
              size="24"
              tile
            >
              <icon-cart
                outlined
                :is-active="data.isActive"
              />
            </v-list-item-avatar>
            <v-list-item-title>
              {{ $t(data.title) }}
            </v-list-item-title>
          </template>
          <template #content(logout)="{ data }">
            <v-list-item-avatar
              size="24"
              tile
            >
              <icon-logout />
            </v-list-item-avatar>
            <v-list-item-title>
              {{ $t(data.title) }}
            </v-list-item-title>
          </template>
        </base-dropdown>
        <v-btn
          icon
          plain
          class="cart__icon"
          :to="{ name: 'cart' }"
        >
          <icon-cart :is-opt-in="isOptIn" />
        </v-btn>
      </div>
    </div>
    <div class="sub__navbar">
      <last-search-dropdown
        @open-car-search-history-dialog="openCarSearchHistoryDialog"
      />
      <div class="nav__items">
        <div
          :class="[
            'nav__scroll',
            isShowText && 'show-text'
          ]"
        >
          <navigation-tab
            :item="navbar.carTire"
            :is-show-text="isShowText"
          />
          <navigation-tab
            :item="navbar.truckTire"
            :is-show-text="isShowText"
          />
          <navigation-tab
            :item="navbar.motorbikeTire"
            :is-show-text="isShowText"
          />
          <navigation-tab
            :item="navbar.rimAndConfig"
            :is-show-text="isShowText"
          />
          <navigation-tab
            :item="navbar.industrialTire"
            :is-show-text="isShowText"
          />
          <navigation-tab
            :item="navbar.agricultureTire"
            :is-show-text="isShowText"
          />
          <navigation-tab
            :item="navbar.heavyTruckTire"
            :is-show-text="isShowText"
          />
          <navigation-tab
            :item="navbar.service"
            :is-show-text="isShowText"
          />
          <v-spacer />
          <navigation-tab
            :item="navbar.title"
            :is-show-text="isShowText"
          />
        </div>
      </div>
    </div>
    <rimconfig-menu v-if="rimsConfig" />
    <info-dialog ref="searchInfoRef" />
    <rimconfig-car-search-history-dialog ref="carSearchHistoryDialog" />

    <base-dialog ref="dealerCalculationDialog">
      <img
        class="img__popup-fenster"
        :src="require(`@/assets/images/Popup-Fenster.webp`)"
      >
    </base-dialog>
  </v-app-bar>
</template>

<style lang="scss">
@import '@/styles/components/navigation.scss';
</style>

<script>
import NavigationTab from '@/components/NavigationTab';
import IconInfo from '@/components/icons/IconInfo';
import IconAdminArea from '@/components/icons/IconAdminArea';
import IconCart from '@/components/icons/IconCart';
import IconUser from '@/components/icons/IconUser';
import IconLogout from '@/components/icons/IconLogout';
import LastSearchDropdown from '@/components/LastSearchDropdown';
import RimconfigMenu from '@/components/RimconfigMenu';
import InfoDialog from '@/components/InfoDialog';
import RimconfigCarSearchHistoryDialog from '@/components/RimconfigCarSearchHistoryDialog';
import CurrencyDropdown from '@/components/CurrencyDropdown';
import OptInField from '@/components/OptInField';
import NavigationSearchBar from '@/components/NavigationSearchBar';

import { removeCookie } from '@/utils/js-cookie';
import { mapState } from 'vuex';
import { tyreCarVehicleTypesGroup, tyreAllAvailableVehicleTypes } from '@/constants/tyreMenuGroup';

export default {
  components: {
    NavigationTab,
    IconInfo,
    IconAdminArea,
    IconCart,
    IconUser,
    IconLogout,
    LastSearchDropdown,
    RimconfigMenu,
    InfoDialog,
    RimconfigCarSearchHistoryDialog,
    CurrencyDropdown,
    OptInField,
    NavigationSearchBar,
  },
  props: {
    rimsConfig: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isShowText: false,
      navbar: {
        carTire: {
          to: 'tyre-car',
          class: 'tyre-car',
          isGroup: true,
          children: [
            {
              title: 'langkey.car',
              icon: 'car',
            },
            {
              title: 'langkey.suv',
              icon: 'suv',
            },
            {
              title: 'langkey.van',
              icon: 'van',
            },
          ],
        },
        truckTire: {
          title: 'langkey.truck',
          icon: 'truck',
          to: 'tyre-truck',
        },
        industrialTire: {
          title: 'langkey.industrial',
          icon: 'industrial',
          to: 'tyre-industrial',
        },
        agricultureTire: {
          title: 'langkey.agriculture',
          icon: 'agriculture',
          to: 'tyre-agriculture',
        },
        heavyTruckTire: {
          title: 'langkey.earthMover',
          icon: 'earth-mover',
          to: 'tyre-earthMover',
        },
        motorbikeTire: {
          title: 'langkey.motorbike',
          icon: 'bike',
          to: 'tyre-motorbike',
        },
        rimAndConfig: {
          title: 'langkey.rims-config',
          icon: 'rim',
          to: 'rims-config',
        },
        service: {
          title: 'langkey.car-service',
          icon: 'service',
          to: 'car-service',
        },
        title: {
          title: 'langkey.streering',
          icon: 'steering-wheel',
          to: 'steering',
          inactive: true,
        },
        support: {
          title: 'langkey.support',
          content: 'langkey.support-content',
        },
        marketplace: {
          title: '',
          icon: 'Marketplace',
          items: [
            { title: 'Offering', to: 'marketplace-offering' },
            { title: 'Offer', to: 'marketplace-offer' },
            { title: 'Sale', to: 'marketplace-sale' },
          ],
          inactive: true,
        },
        user: {
          title: '',
          icon: 'User',
          items: [
            { key: 'user', customContent: true },
            { key: 'adminArea', title: 'langkey.admin-area', customContent: true, to: 'admin-overview' },
            { key: 'shop', title: 'langkey.shop', customContent: true, to: 'tyre', isActive: true },
            { key: 'logout', title: 'langkey.logout', customEvent: 'logout', customContent: true },
          ],
        },
      },
      logoutButton: {
        title: 'Logout',
      },
    };
  },
  computed: {
    ...mapState('user', {
      userType: 'userType',
      userInformation: 'userInformation',
    }),
    ...mapState('pricing', {
      isOptIn: 'isOptIn',
    }),
    getUsername() {
      if (this.userInformation) {
        return this.userInformation.username;
      } else {
        return '';
      }
    },
    isOptInStyle() {
      return this.isOptIn && (
        this.$route.name === 'tyre-car' ||
        this.$route.name === 'tyre-truck' ||
        this.$route.name === 'tyre-motorbike' ||
        this.$route.name === 'tyre-industrial' ||
        this.$route.name === 'tyre-agriculture' ||
        this.$route.name === 'tyre-earthMover' ||
        this.$route.name === 'tyre-offer'
      );
    },
  },
  methods: {
    logout() {
      removeCookie('user_access_token');
      sessionStorage.removeItem('userInformation');
      sessionStorage.removeItem('userType');
      sessionStorage.removeItem('customerInformation');
      sessionStorage.removeItem('mailpwpopupopen');
      sessionStorage.removeItem('showInfoDialog');
      this.$router.replace(
        this.$i18nRoute({
          name: 'login',
        }),
      );
    },
    openSupportModal() {
      this.$refs.simpleDialog.show();
    },
    openCarSearchHistoryDialog() {
      this.$refs.carSearchHistoryDialog.show();
    },
  },
};
</script>
