export function getTextBeforeWord(beforeWord, word) {
  return word.slice(0, word.indexOf(beforeWord));
}

export function getTextAfterWord(afterWord, word) {
  return word.slice(word.indexOf(afterWord) + afterWord.length);
}

export function isNot360Wheel(imgPath) {
  return imgPath.slice(imgPath.length - 5).includes('0');
}

/**
 * @param {*[]} items
 * @param {string[]} checkContainItems
 * @return {boolean}
 */
export function isContainSome(items, checkContainItems) {
  return items.some((val) => checkContainItems.includes(val));
}

export function isObjectEmpty(obj) {
  if (obj === undefined || obj === null) {
    return true;
  }
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

export function getPriceType() {
  return sessionStorage.getItem('priceType') || 'purchasePrice';
}

/**
 * @param {string} image
 * @param {number} width
 * @param {number} height
 * @return {string} image path with pass size
 */
export function getImageBySize(image, width, height) {
  let bgImage = image;
  bgImage = bgImage.replace('{width}', width);
  bgImage = bgImage.replace('{height}', height);
  bgImage = bgImage.replace('{orientation}', 'cc');
  bgImage = bgImage.replace('{backgroundColor}', 'xxxxxx');
  bgImage = bgImage.replace('{fileExtension}', 'png');
  return bgImage;
}

export function getTyreSeasonText(text) {
  const lower = text.toLowerCase();
  if (lower === 'summer' || lower === 's') {
    return 'summer';
  }
  if (lower === 'winter' || lower === 'w') {
    return 'winter';
  }
  if (lower === 'allseason' || lower === 'a') {
    return 'allSeason';
  }
  return 'summer';
}

export function getTyreSizeText(width, height, construction, diameter) {
  return `${width}/${height} ${construction}${diameter}`;
}

export function tyreSizeTextToObj(text, isCombination) {
  if (isCombination) {
    const front = getTextBeforeWord(' / ', text);
    const rear = getTextAfterWord(' / ', text);

    const splitSizeFront = front.split('/'); // ['width', '{height} {construction}{diameter}']
    const splitSizeRear = rear.split('/'); // ['width', '{height} {construction}{diameter}']

    const diameterFront = splitSizeFront[1].split(' ')[1].slice(-2);
    const diameterRear = splitSizeRear[1].split(' ')[1].slice(-2);

    const constructionFront = splitSizeFront[1].split(' ')[1].slice(0, -2);
    const constructionRear = splitSizeRear[1].split(' ')[1].slice(0, -2);

    const widthFront = splitSizeFront[0];
    const widthRear = splitSizeRear[0];

    const heightFront = splitSizeFront[1].split(' ')[0];
    const heightRear = splitSizeRear[1].split(' ')[0];

    return {
      tyreInnerDiameter: `${diameterFront},${diameterRear}`,
      tyreConstructionType: `${constructionFront},${constructionRear}`,
      tyreWidth: `${widthFront},${widthRear}`,
      tyreHeight: `${heightFront},${heightRear}`,
    };
  }

  const sizeList = text.split(',');

  const diameterList = sizeList.map((size) => {
    const splitSize = size.split('/'); // ['width', '{height} {construction}{diameter}']
    const diameter = splitSize[1].split(' ')[1].slice(-2);
    if (diameter.includes('.')) {
      return splitSize[1].split(' ')[1].slice(-4);
    } else {
      return diameter;
    }
  }).join(); // get last 2 characters

  const constructionList = sizeList.map((size) => {
    const splitSize = size.split('/'); // ['width', '{height} {construction}{diameter}']
    const diameter = splitSize[1].split(' ')[1].slice(-2);
    let construction = '';

    if (diameter.includes('.')) {
      construction = splitSize[1].split(' ')[1].slice(0, -4);
    } else {
      construction = splitSize[1].split(' ')[1].slice(0, -2);
    }

    return construction;
  }).join(); // get all charaters before last 2 characters

  const widthList = sizeList.map((size) => {
    const splitSize = size.split('/'); // ['width', '{height} {construction}{diameter}']
    const width = splitSize[0];
    return width;
  }).join();

  const heightList = sizeList.map((size) => {
    const splitSize = size.split('/'); // ['width', '{height} {construction}{diameter}']
    const height = splitSize[1].split(' ')[0];
    return height;
  }).join();

  return {
    tyreInnerDiameter: diameterList,
    tyreConstructionType: constructionList,
    tyreWidth: widthList,
    tyreHeight: heightList,
  };
}