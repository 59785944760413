import { apiClient, apiFormData } from '@/services';

export const DealerCalculationService = {
  getCalculations() {
    return apiClient.get('/marketplace_calculation');
  },
  getCalculationById(id) {
    return apiClient.get(`/marketplace_calculation/${id}`);
  },
  createCalculation(params) {
    return apiClient.post('/marketplace_calculation', params);
  },
  updateCalculation(id, params) {
    return apiClient.patch(`/marketplace_calculation/${id}`, params);
  },
  deleteCalculation(id) {
    return apiClient.delete(`/marketplace_calculation/${id}`);
  },
  getOfferToolSettings() {
    return apiClient.get('marketplace_calculation/settings');
  },
  updateOfferToolSettings(params) {
    return apiClient.patch('marketplace_calculation/settings', params);
  },
  updateSettingsLogo(params) {
    return apiFormData.post('marketplace_calculation/settings_logo', params);
  },
};