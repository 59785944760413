<template>
  <div
    id="app"
    :class="[isTablet && 'tablet']"
  >
    <mobile-construction v-if="isMobile" />
    <v-app
      v-else-if="!(isTablet || isMobile)"
      id="vuetify-app"
    >
      <component :is="layout">
        <router-view />
      </component>
      <error-dialog />
      <loading-dialog />
      <email-address-dialog />
      <reset-password-dialog />
    </v-app>
  </div>
</template>

<script>
import MainLayout from '@/layouts/MainLayout';
import LandingLayout from '@/layouts/LandingLayout';
import RimsConfigLayout from '@/layouts/RimsConfigLayout';
import PlainLayout from '@/layouts/PlainLayout';
import AdminLayout from '@/layouts/AdminLayout';
import ErrorDialog from '@/components/ErrorDialog';
import LoadingDialog from '@/components/LoadingDialog';
import EmailAddressDialog from '@/components/EmailAddressDialog';
import ResetPasswordDialog from '@/components/ResetPasswordDialog';
import MobileConstruction from '@/components/MobileConstruction';
import { windowSize, touchScreenDevice } from '@/utils/mixins';

export default {
  components: {
    MainLayout,
    LandingLayout,
    RimsConfigLayout,
    PlainLayout,
    AdminLayout,
    ErrorDialog,
    LoadingDialog,
    EmailAddressDialog,
    ResetPasswordDialog,
    MobileConstruction,
  },
  mixins: [windowSize, touchScreenDevice],
  computed: {
    layout() {
      return this.$route.meta.layout ? `${this.$route.meta.layout}-layout` : 'div';
    },
  },
};
</script>
